import { Box, Divider, Typography } from '@material-ui/core'
import React from 'react'
import {
  DateField,
  FunctionField,
  ReferenceField,
  ResourceContextProvider,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
  useShowContext,
  useTranslate,
} from 'react-admin'
import { Authority } from '../../core/auth/Authority'
import { ChemotherapyAilmentSurveyDTO } from '../../core/dto/chemotherapy-ailment-survey/chemotherapy-ailment-survey.dto'
import { ResourceName } from '../../core/ResourceName'
import { ChemotherapyAilmentList } from './ChemotherapyAilmentSurveyShow/ChemotherapyAilmentList'
import { DeleteButtonWithConfirm } from '../common/DeleteButtonWithConfirm'
import { useHasAuthority } from '../hooks/useHasAuthority'
import { PatientDto } from '../../core/dto/user/patient.dto'

const ShowTitle = () => {
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.chemotherapy-ailment-surveys.titles.show')}
    </span>
  )
}

const GeneralActions = ({ ...props }) => {
  // const { record: ailmentSurvey } = useShowContext<AilmentSurveyDTO>()
  const hasAuthority = useHasAuthority()

  return (
    <>
      <Divider />
      <Box p={1} display="block" textAlign="right">
        {/* {hasAuthority(Authority.EDIT_AILMENT_SURVEYS) && (
          <EditButton
            basePath={`/${ResourceName.AILMENT_SURVEYS}`}
            record={ailmentSurvey}
            variant="contained"
            style={{ margin: '2px' }}
          />
        )} */}
        {hasAuthority(Authority.EDIT_CHEMOTHERAPY_AILMENT_SURVEYS) && (
          <DeleteButtonWithConfirm {...props} />
        )}
      </Box>
    </>
  )
}

const EmptyView = () => {
  const translate = useTranslate()
  return (
    <Box textAlign="center" m={1}>
      <Typography variant="h5" paragraph>
        {translate('resources.chemotherapy-ailments.empty')}
      </Typography>
    </Box>
  )
}

const ChemotherapyAilments = () => {
  const { record: ailmentSurveyData } =
    useShowContext<ChemotherapyAilmentSurveyDTO>()
  return (ailmentSurveyData as ChemotherapyAilmentSurveyDTO).ailmentsIds
    ?.length > 0 ? (
    <ResourceContextProvider value={ResourceName.CHEMOTHERAPY_AILMENTS}>
      <ChemotherapyAilmentList
        basePath={`/${ResourceName.CHEMOTHERAPY_AILMENTS}`}
        filterDefaultValues={{ ailmentSurveyId: ailmentSurveyData?.id }}
      />
    </ResourceContextProvider>
  ) : (
    <EmptyView />
  )
}

const ChemotherapyAilmentSurveyLayout = ({ ...props }) => (
  <SimpleShowLayout {...props}>
    <TextField source="id" label="ID" />
    <ReferenceField
      source="patientId"
      reference={ResourceName.PATIENTS}
      link="show"
      emptyText="-"
      sortable={false}
    >
      <FunctionField<PatientDto>
        source="patientId"
        render={(record?: PatientDto) => (
          <Typography variant="body2">
            {record?.firstName} {record?.lastName}
          </Typography>
        )}
      />
    </ReferenceField>
    <DateField
      source="date"
      showTime
      options={{
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }}
      emptyText="-"
    />
    <ChemotherapyAilments />
    <GeneralActions />
  </SimpleShowLayout>
)

export const ChemotherapyAilmentSurveyShow = (props: ShowProps) => (
  <Show {...props} actions={false} title={<ShowTitle />}>
    <ChemotherapyAilmentSurveyLayout />
  </Show>
)
