import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import SendIcon from '@material-ui/icons/Send'
import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  JsonHubProtocol,
  LogLevel,
} from '@microsoft/signalr'
import { format } from 'date-fns'
import { minBy, orderBy } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { GetOneParams, useNotify, useTranslate } from 'react-admin'
import { useHistory, useLocation } from 'react-router'
import { DateTime, getCurrentDateTime } from '../../core/common/date-time'
import CurrentUserProvider from '../../core/current-user/current-user.provider'
import { ChatMessageDTO } from '../../core/dto/users-communication/chat-message.dto'
import { ReceiveChatHistoryResponse } from '../../core/dto/users-communication/client/receive-chat-history-response.dto'
import { ReceiveMyChatsListResponse } from '../../core/dto/users-communication/client/receive-my-chats-list-response.dto'
import { GetMyChatsListRequest } from '../../core/dto/users-communication/server/get-my-chats-list-request.dto'
import { JoinChatRequest } from '../../core/dto/users-communication/server/join-chat-request.dto'
import { LeaveChatRequest } from '../../core/dto/users-communication/server/leave-chat-request.dto'
import { SendChatHistoryRequest } from '../../core/dto/users-communication/server/send-chat-history-request.dto'
import { SendMessageRequest } from '../../core/dto/users-communication/server/send-message-request.dto'
import { ResourceName } from '../../core/ResourceName'
import Button from '../common/customized-mui-components/Button'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogModal: {
      '& .MuiDialog-paper': {
        width: '100%',
        height: '100%',
      },
    },
    bulkStateActions: {
      height: '16px',
      background: 'rgba(0,0,0,0.2)',
      minWidth: '300px',
      userSelect: 'none',
    },
    content: {
      background: theme.palette.background.default,
      height: '100%',
    },
    bulkProgressTitle: {
      marginLeft: '10px',
      marginRight: 'auto',
    },
    bulkProgressCloseButton: {
      margin: 0,
    },
    actionsButton: {
      margin: '2%',
      width: '96%',
    },
    currentUserMessage: {
      backgroundColor: theme.palette.primary.light,
    },
    messageText: {
      overflowWrap: 'anywhere',
    },
  }),
)

// HubConnection
const CreateChatConnection = () =>
  new HubConnectionBuilder()
    .withUrl('api/v1/ChatHub', HttpTransportType.LongPolling)
    .withAutomaticReconnect({
      nextRetryDelayInMilliseconds: () => 15000,
    })
    .withHubProtocol(new JsonHubProtocol())
    .configureLogging(LogLevel.Information)
    .build()

const OpenChatConnection = async (
  connection: HubConnection,
  connectionStartedCallback?: () => void,
  couldNotStartConnectionCallback?: () => void,
) => {
  try {
    await connection.start()
    if (connectionStartedCallback) connectionStartedCallback()
  } catch {
    if (couldNotStartConnectionCallback) couldNotStartConnectionCallback()
  }
}

const CloseChatConnection = async (connection: HubConnection) =>
  connection.stop()

// Server Methods Calls
const SendMessage = async (
  connection: HubConnection,
  messageRequest: SendMessageRequest,
) => {
  await connection.invoke<SendMessageRequest>('SendMessage', messageRequest)
}

const SendChatHistory = async (
  connection: HubConnection,
  chatHistoryRequest: SendChatHistoryRequest,
) => {
  await connection.invoke<SendChatHistoryRequest>(
    'SendChatHistory',
    chatHistoryRequest,
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GetMyChatsList = async (
  connection: HubConnection,
  myChatsListRequest: GetMyChatsListRequest,
) => {
  await connection.invoke<GetMyChatsListRequest>(
    'GetMyChatsList',
    myChatsListRequest,
  )
}

const JoinChat = async (
  connection: HubConnection,
  joinChat: JoinChatRequest,
) => {
  await connection.invoke<JoinChatRequest>('JoinChat', joinChat)
}

const LeaveChat = async (
  connection: HubConnection,
  leaveChat: LeaveChatRequest,
) => {
  await connection.invoke<LeaveChatRequest>('LeaveChat', leaveChat)
}

const Alive = async (connection: HubConnection) => {
  await connection.invoke<LeaveChatRequest>('Alive')
}

// IChatClient Implementation
const SubscribeClientMethods = (
  connection: HubConnection,
  receiveMessageCallback?: (message: ChatMessageDTO) => void,
  receiveChatHistoryCallback?: (
    chatHistory: ReceiveChatHistoryResponse,
  ) => void,
  receiveMyChatsListCallback?: (
    myChatsList: ReceiveMyChatsListResponse,
  ) => void,
  aliveCallback?: () => void,
  closeConnectionCallback?: () => void,
) => {
  if (receiveMessageCallback)
    connection.on('ReceiveMessage', receiveMessageCallback)
  if (receiveChatHistoryCallback)
    connection.on('ReceiveChatHistory', receiveChatHistoryCallback)
  if (receiveMyChatsListCallback)
    connection.on('ReceiveMyChatsList', receiveMyChatsListCallback)
  if (aliveCallback) connection.on('Alive', aliveCallback)
  if (closeConnectionCallback)
    connection.on('CloseConnection', closeConnectionCallback)
}

// Chat View
export const ChatModal = () => {
  const classes = useStyles()
  const history = useHistory()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [open, setOpen] = useState(true)
  const translate = useTranslate()
  const { search } = useLocation()
  const notify = useNotify()
  const chatIdRaw = new URLSearchParams(search).get('id')
  const chatId: number | undefined =
    chatIdRaw !== null ? Number(chatIdRaw) : undefined
  const [connection] = useState<HubConnection>(CreateChatConnection())
  const [messagesLoadedSince, setMessagesLoadedSince] = useState<DateTime>(
    getCurrentDateTime(),
  )
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [messages, setMessages] = useState<ChatMessageDTO[]>([])
  const [showLoadMore, setShowLoadMore] = useState<boolean>(true)
  const [firstLoad, setFirstLoad] = useState<boolean>(true)
  const [currentUserId, setCurrentUserId] = useState<number>()
  const currentUserIdRef = useRef<number>()
  const [otherAuthors, setOtherAuthors] = useState<Record<number, string>>()
  const otherAuthorsRef = useRef<Record<number, string>>()
  const messageInputRef = useRef<any>(null)
  const listRef = useRef<any>(null)
  const [shouldScrollToBottom, setShouldScrollToBottom] =
    useState<boolean>(false)

  useEffect(() => {
    currentUserIdRef.current = currentUserId
  }, [currentUserId])

  useEffect(() => {
    otherAuthorsRef.current = otherAuthors
  }, [otherAuthors])

  const AddOrRefreshMessagesHistory = (
    chatHistory: ReceiveChatHistoryResponse,
  ) => {
    setOtherAuthors(chatHistory.chatUserDisplayNames)
    if (chatHistory.chatHistory.length > 0)
      setMessages((oldMessages) => [...oldMessages, ...chatHistory.chatHistory])
    else setShowLoadMore(false)
  }

  // Update date since messages was loaded
  useEffect(() => {
    if (messages) {
      const earliestMessage = minBy(
        messages,
        (m) => new Date(m.createdAt as DateTime),
      )
      if (earliestMessage?.createdAt)
        setMessagesLoadedSince(earliestMessage.createdAt)
    }
  }, [messages])

  const ScrollToNewestMessage = (afterNearestMessagesListChange = false) => {
    if (afterNearestMessagesListChange) setShouldScrollToBottom(true)
    else if (listRef?.current)
      listRef.current.scrollIntoView({ behaviour: 'smooth' })
  }

  useEffect(() => {
    if (shouldScrollToBottom && listRef?.current) {
      setShouldScrollToBottom(false)
      listRef.current.scrollIntoView({ behaviour: 'smooth' })
    }
  }, [listRef, shouldScrollToBottom])

  const onConnectionStarted = async () => {
    setMessages([])
    setOtherAuthors([])
    const currentDate = getCurrentDateTime()
    setMessagesLoadedSince(currentDate)
    setFirstLoad(true)
    await SendChatHistory(connection, {
      chatId,
      count: 20,
      toDate: currentDate,
    } as SendChatHistoryRequest)
    await JoinChat(connection, { chatId } as JoinChatRequest)
    setIsDisabled(false)
  }

  const onCouldNotStartConnection = () => {
    setIsDisabled(true)
    setMessages([])
    setOtherAuthors([])
    setFirstLoad(true)
    notify(translate('chat.actions.chat-inactive'), 'error')
  }

  useEffect(() => {
    if (!firstLoad) {
      setFirstLoad(false)
      setShowLoadMore(true)
      ScrollToNewestMessage()
    }
  }, [firstLoad])

  const onMessageReceived = (message: ChatMessageDTO) => {
    if (
      !message.userId ||
      !message.createdAt ||
      message.userId === currentUserIdRef.current ||
      !otherAuthorsRef.current?.[message.userId]
    )
      return
    setMessages((previousState) => [...previousState, message])
    ScrollToNewestMessage(true)
  }

  const onHubConnectionClosed = () => {
    setIsDisabled(true)
    setMessages([])
    setOtherAuthors([])
    setFirstLoad(true)
  }

  const onHubConnectionReconnecting = () => {
    setIsDisabled(true)
    notify(translate('chat.actions.chat-reconnecting'), 'warning')
  }

  const onHubConnectionReconnected = async () => {
    setMessages([])
    setOtherAuthors([])
    const currentDate = getCurrentDateTime()
    setMessagesLoadedSince(currentDate)
    setFirstLoad(true)
    await SendChatHistory(connection, {
      chatId,
      count: 20,
      toDate: currentDate,
    } as SendChatHistoryRequest)
    await JoinChat(connection, { chatId } as JoinChatRequest)
    setIsDisabled(false)
    notify(translate('chat.actions.chat-reconnected'), 'success')
  }

  const onClose = async () => {
    if (connection?.state === HubConnectionState.Connected) {
      if (chatId) await LeaveChat(connection, { chatId } as LeaveChatRequest)
      await CloseChatConnection(connection)
    }
    if (history.location.pathname === '/chat') history.goBack()
  }

  const onChatHistoryReceived = (chatHistory: ReceiveChatHistoryResponse) => {
    AddOrRefreshMessagesHistory(chatHistory)
    setFirstLoad(false)
  }

  const onAliveReceived = async () => {
    await Alive(connection)
  }

  const onCloseConnectionReceived = async () => onClose()

  const onOpen = async () => {
    connection.onclose(onHubConnectionClosed)
    connection.onreconnecting(onHubConnectionReconnecting)
    connection.onreconnected(onHubConnectionReconnected)
    SubscribeClientMethods(
      connection,
      onMessageReceived,
      onChatHistoryReceived,
      undefined,
      onAliveReceived,
      onCloseConnectionReceived,
    )
    const currentUser = await CurrentUserProvider.getOne(
      ResourceName.CURRENT_USER,
      {} as GetOneParams,
    )
    if (currentUser?.data?.id) setCurrentUserId(Number(currentUser?.data?.id))
    await OpenChatConnection(
      connection,
      onConnectionStarted,
      onCouldNotStartConnection,
    )
  }

  useEffect(() => {
    onOpen()
    if (!chatId) {
      notify(translate('chat.actions.bad-chat-id'), 'error')
      onClose()
    }
    const unloadCallback = async (event: BeforeUnloadEvent) => {
      event.preventDefault()
      await onClose()
      return ''
    }
    window.addEventListener('beforeunload', unloadCallback)
    return () => {
      window.removeEventListener('beforeunload', unloadCallback)
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getFormatedDate = (date?: DateTime) => {
    if (!date) return ''
    const parsedDate = new Date(date as DateTime)
    return format(parsedDate, 'HH:mm:ss dd-MM-yyyy')
  }

  const getCurrentServerDateTime = async () =>
    (await CurrentUserProvider.getServerDateTime(ResourceName.CURRENT_USER))
      ?.data?.serverDateTime

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="xl"
      fullScreen={smallScreen}
      disableBackdropClick
      disableEscapeKeyDown
      // PaperComponent={DraggableComponent}
      // aria-labelledby="draggable-dialog-title"
      className={classes.dialogModal}
    >
      <DialogActions
        className={classes.bulkStateActions}
        // style={{ cursor: 'move' }}
        id="draggable-dialog-title"
      >
        <Typography className={classes.bulkProgressTitle}>
          {translate('chat.title')}:{' '}
          {otherAuthors && Object.values(otherAuthors).join(', ')}
        </Typography>
        <IconButton
          size="small"
          className={classes.bulkProgressCloseButton}
          onClick={onClose}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogActions>
      <DialogContent className={classes.content}>
        <>
          <div
            style={{
              overflow: 'auto',
              height: 'calc(100% - 120px - 15px)',
              maxWidth: '100%',
            }}
          >
            <List>
              {showLoadMore === true && (
                <Button
                  key="show-more-button"
                  label={translate('chat.actions.load-more')}
                  onClick={async () =>
                    SendChatHistory(connection, {
                      chatId,
                      count: 20,
                      toDate: messagesLoadedSince,
                    } as SendChatHistoryRequest)
                  }
                  style={{ width: '100%', margin: '15px 0px', padding: 0 }}
                  useSmallVersionBreakpoint={false}
                  disabled={isDisabled}
                />
              )}
              {orderBy(messages, (m) => m.createdAt, 'asc').map((m) => (
                <Grid
                  key={m.id}
                  container
                  direction="row"
                  spacing={0}
                  style={{ padding: '4px 0px' }}
                >
                  {/* eslint-disable-next-line no-nested-ternary */}
                  <Grid
                    item
                    xs={
                      // eslint-disable-next-line no-nested-ternary
                      m.userId === currentUserId
                        ? smallScreen
                          ? 2
                          : 7
                        : undefined
                    }
                  />
                  <Grid item xs={smallScreen ? 10 : 5}>
                    <Paper
                      style={{ padding: 2 }}
                      className={
                        m.userId === currentUserId
                          ? classes.currentUserMessage
                          : ''
                      }
                    >
                      <ListItem>
                        <Grid
                          container
                          direction="column"
                          alignItems="flex-start"
                          spacing={0}
                        >
                          <Grid item>
                            <ListItemText
                              primary={otherAuthors?.[Number(m.userId)]}
                              secondary={m.message}
                              className={classes.messageText}
                            />
                          </Grid>
                          <Grid
                            item
                            spacing={0}
                            container
                            direction="column"
                            alignContent="flex-end"
                          >
                            <Grid item>
                              <ListItemText
                                secondary={getFormatedDate(m.createdAt)}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </ListItem>
                    </Paper>
                  </Grid>
                </Grid>
              ))}
              <ListItem innerRef={listRef} />
            </List>
          </div>
          <div style={{ height: '120px', maxWidth: '100%', marginTop: 15 }}>
            <Grid container spacing={0}>
              <Grid item style={{ width: 'calc(100% - 100px)' }}>
                <TextField
                  disabled={isDisabled}
                  variant="outlined"
                  placeholder={translate('chat.inputs.write-message-here')}
                  fullWidth
                  multiline
                  rowsMax={3}
                  rows={3}
                  inputRef={messageInputRef}
                />
              </Grid>
              <Grid item>
                <Button
                  useSmallVersionBreakpoint={false}
                  disabled={isDisabled}
                  fullWidth
                  style={{
                    height: 'calc(100% - 10px)',
                    margin: '5px',
                    width: '90px',
                  }}
                  variant="contained"
                  onClick={async () => {
                    if (
                      messageInputRef?.current?.value &&
                      messageInputRef.current.value !== ''
                    ) {
                      const messageToSend = {
                        chatId,
                        message: messageInputRef.current.value,
                      } as SendMessageRequest
                      SendMessage(connection, messageToSend)
                      const serverDateTime = await getCurrentServerDateTime()
                      setMessages((previousState) => [
                        ...previousState,
                        {
                          message: messageToSend.message,
                          chatId,
                          userId: currentUserId,
                          createdAt: serverDateTime,
                        } as ChatMessageDTO,
                      ])
                      messageInputRef.current.value = null
                      ScrollToNewestMessage(true)
                    }
                  }}
                >
                  <SendIcon />
                </Button>
              </Grid>
            </Grid>
          </div>
        </>
      </DialogContent>
    </Dialog>
  )
}
