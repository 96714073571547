export enum ResourceName {
  CURRENT_USER = 'current_user',
  ROLES = 'roles',
  PERMISSIONS = 'permissions',
  USERS = 'users',
  DOCTORS = 'doctors',
  PATIENTS = 'patients',
  WEB_USERS = 'web-users',
  RADIOTHERAPY_AILMENTS = 'radiotherapy-ailments',
  RADIOTHERAPY_AILMENT_SURVEYS = 'radiotherapy-ailment-surveys',
  CHEMOTHERAPY_AILMENTS = 'chemotherapy-ailments',
  CHEMOTHERAPY_AILMENT_SURVEYS = 'chemotherapy-ailment-surveys',
  SCHEDULERS = 'schedulers',
  RADIOTHERAPY_MACHINES = 'radiotherapy-machines',
  VACATION_CALENDARS = 'vacation-calendars',
}
