import {
  CreateParams,
  CreateResult,
  DataProvider,
  DeleteParams,
  DeleteResult,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  Identifier,
  UpdateParams,
  UpdateResult,
} from 'react-admin'
import { CHEMOTHERAPY_AILMENT_SURVEYS_URL } from '../api-urls'
import { DateTime } from '../common/date-time'
import {
  get,
  getBlob,
  getWithPagination,
  patch,
  post,
  remove,
} from '../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../common/get-by-conditions.utils'
import { RadOnkAPIGetListParams } from '../common/rad-onk-api-get-list.params'
import { ChemotherapyAilmentSurveyDTO } from '../dto/chemotherapy-ailment-survey/chemotherapy-ailment-survey.dto'
import filterMapper from './chemotherapy-ailment-surveys-filter.mapper'
import sortMapper from './chemotherapy-ailment-surveys-sort.mapper'

const provider = {
  getList: async (
    resource: string,
    { filter: { ...filter }, sort, pagination }: RadOnkAPIGetListParams,
  ): Promise<GetListResult<ChemotherapyAilmentSurveyDTO>> => {
    const filterParams = `s=>${filterParamsComposer('s', filter, filterMapper)}`
    const pathParams = queryParamsComposer(sort, pagination, sortMapper)
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<ChemotherapyAilmentSurveyDTO[]>(
      `${CHEMOTHERAPY_AILMENT_SURVEYS_URL}/GetByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<ChemotherapyAilmentSurveyDTO>> => {
    const data = await get<ChemotherapyAilmentSurveyDTO>(
      `${CHEMOTHERAPY_AILMENT_SURVEYS_URL}`,
      `/${id}`,
    )
    return Promise.resolve({
      data,
    })
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<ChemotherapyAilmentSurveyDTO>> => {
    const data = await get<ChemotherapyAilmentSurveyDTO[]>(
      `${CHEMOTHERAPY_AILMENT_SURVEYS_URL}/GetByConditions`,
      `/s=>new int[] {${ids.toString()}}.Contains(s.Id)`,
    )
    return Promise.resolve({
      data,
    })
  },
  create: async (
    resource: string,
    { data }: CreateParams<CreateChemotherapyAilmentSurveyRequest>,
  ): Promise<CreateResult> => {
    const created = await post<
      CreateChemotherapyAilmentSurveyRequest[],
      ChemotherapyAilmentSurveyDTO[]
    >(`${CHEMOTHERAPY_AILMENT_SURVEYS_URL}`, [data])
    return Promise.resolve({ data: created[0] })
  },
  update: async (
    resource: string,
    { id, data, previousData }: UpdateParams<ChemotherapyAilmentSurveyDTO>,
  ): Promise<UpdateResult> => {
    const update = await patch<
      { id: Identifier; [n: string]: any },
      ChemotherapyAilmentSurveyDTO
    >(`${CHEMOTHERAPY_AILMENT_SURVEYS_URL}`, {
      id,
      date: data.date !== previousData.date ? data.date : undefined,
      patientId:
        data.patientId !== previousData.patientId ? data.patientId : undefined,
    })
    return Promise.resolve({ data: update })
  },
  delete: async (
    resource: string,
    { id }: DeleteParams,
  ): Promise<DeleteResult> => {
    const data = await remove<ChemotherapyAilmentSurveyDTO>(
      `${CHEMOTHERAPY_AILMENT_SURVEYS_URL}`,
      `/${id}`,
    )
    return {
      data: {
        id: data.id,
      },
    }
  },
  exportToCSV: async (
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    resource: string,
    {
      dateFrom,
      dateTo,
      doctorId,
      patientId,
      showArchivedPatients,
    }: ExportToCSVRequest,
  ): Promise<{
    data: {
      status: number
      headers: Headers
      body: Blob
    }
  }> => {
    const queryParams = Array<string>()
    if (dateFrom) queryParams.push(`dateFrom=${dateFrom}`)
    if (dateTo) queryParams.push(`dateTo=${dateTo}`)
    if (doctorId) queryParams.push(`doctorId=${doctorId}`)
    if (patientId) queryParams.push(`patientId=${patientId}`)
    if (showArchivedPatients)
      queryParams.push(`showArchivedPatients=${showArchivedPatients}`)
    const path =
      queryParams.length > 0 ? queryParams.reduce((p, c) => `${p}&${c}`) : null

    const blob = await getBlob(
      CHEMOTHERAPY_AILMENT_SURVEYS_URL,
      `/ExportToCSV${path != null ? `/?${path}` : ''}`,
    )
    return Promise.resolve({
      data: blob,
    })
  },
} as ChemotherapyAilmentSurveysDataProvider

interface ChemotherapyAilmentSurveysDataProvider extends DataProvider {
  exportToCSV: (
    resource: string,
    params: ExportToCSVRequest,
  ) => Promise<{
    data: {
      status: number
      headers: Headers
      body: Blob
    }
  }>
}

export interface CreateChemotherapyAilmentSurveyRequest {
  readonly date?: DateTime
  readonly patientId?: number
}

export interface ExportToCSVRequest {
  readonly dateFrom?: DateTime
  readonly dateTo?: DateTime
  readonly doctorId?: number
  readonly patientId?: number
  readonly showArchivedPatients?: boolean
}

export default provider
