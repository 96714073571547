import React from 'react'
import {
  BooleanInput,
  DateTimeInput,
  GET_ONE,
  SimpleForm,
  sanitizeFetchType,
  useQueryWithStore,
  useTranslate,
} from 'react-admin'
import {
  useMediaQuery,
  useTheme,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { ResourceName } from '../../../core/ResourceName'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'
import { ExportToCSVRequest } from '../../../core/chemotherapy-ailment-surveys/chemotherapy-ailment-surveys.provider'
import { UserBaseDto } from '../../../core/dto/user/user-base.dto'
import { UserTypes } from '../../../core/enum/UserTypes'
import { PatientDto } from '../../../core/dto/user/patient.dto'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { Authority } from '../../../core/auth/Authority'
import { DoctorDto } from '../../../core/dto/user/doctor.dto'

const useStyles = makeStyles((theme) => ({
  filters: {
    '& > div > div': {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
    padding: theme.spacing(1),
    backgroundColor: 'rgba(0,0,0,0.1)',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    borderRadius: theme.spacing(1),
  },
}))

export function validate(values: ExportToCSVRequest) {
  const errors: { [n: string]: string } = {}
  if (!values.dateFrom) {
    errors.dateFrom = 'ra.validation.required'
  }
  if (!values.dateTo) {
    errors.dateTo = 'ra.validation.required'
  }
  return errors
}

const FormFields = ({ ...props }) => {
  const classes = useStyles()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const translate = useTranslate()
  const { data: user } = useQueryWithStore({
    type: sanitizeFetchType(GET_ONE),
    resource: ResourceName.CURRENT_USER,
    payload: {},
  })
  const hasAuthority = useHasAuthority()

  return (
    <>
      <div className={classes.filters}>
        <Typography>
          {translate(
            'resources.chemotherapy-ailment-surveys.dialogs.exportToCSV.fields.filtersLabel',
          )}
        </Typography>
        <div>
          {hasAuthority(Authority.WEB_USER) && (
            <FilteredReferenceInput
              {...props}
              label="resources.chemotherapy-ailment-surveys.dialogs.exportToCSV.fields.doctorId"
              source="doctorId"
              defaultValue={null}
              reference={ResourceName.DOCTORS}
              sort={{ field: 'lastName', order: 'ASC' }}
              perPage={smallScreen ? 5 : 15}
              allowEmpty
              filterSource="fullNameSearch"
              filter={{
                doctorsOnly: true,
              }}
              selectWithPaginationInputProps={{
                optionText: (record: DoctorDto) =>
                  `${record.firstName} ${record.lastName}`,
                showFilter: true,
              }}
              emptyText="-"
            />
          )}
          <FilteredReferenceInput
            {...props}
            label="resources.chemotherapy-ailment-surveys.dialogs.exportToCSV.fields.patientId"
            source="patientId"
            defaultValue={null}
            reference={ResourceName.PATIENTS}
            sort={{ field: 'lastName', order: 'ASC' }}
            perPage={smallScreen ? 5 : 15}
            allowEmpty
            filterSource="fullNameSearch"
            filter={{
              patientsOnly: true,
              active: true,
              filterRelatedPatients:
                (user as UserBaseDto)?.userType === UserTypes.DOCTOR,
              relatedPatientDoctorId: (user as UserBaseDto)?.id,
            }}
            selectWithPaginationInputProps={{
              optionText: (record: PatientDto) =>
                `${record.firstName} ${record.lastName} (${record.pesel})`,
              showFilter: true,
            }}
            emptyText="-"
          />
          <DateTimeInput
            {...props}
            label="resources.chemotherapy-ailment-surveys.dialogs.exportToCSV.fields.dateFrom"
            source="dateFrom"
            parse={(value: string) => (value === '' ? null : value)}
          />
          <DateTimeInput
            {...props}
            label="resources.chemotherapy-ailment-surveys.dialogs.exportToCSV.fields.dateTo"
            source="dateTo"
            parse={(value: string) => (value === '' ? null : value)}
          />
          <BooleanInput
            {...props}
            label="resources.chemotherapy-ailment-surveys.dialogs.exportToCSV.fields.showArchivedPatients"
            source="showArchivedPatients"
          />
        </div>
      </div>
    </>
  )
}

export const ExportToCSVForm = ({ ...props }) => {
  const { data: user } = useQueryWithStore({
    type: sanitizeFetchType(GET_ONE),
    resource: ResourceName.CURRENT_USER,
    payload: {},
  })

  return (
    <SimpleForm
      {...props}
      validate={validate}
      initialValues={{
        doctorId:
          (user as UserBaseDto)?.userType === UserTypes.DOCTOR
            ? (user as UserBaseDto)?.id
            : undefined,
      }}
    >
      <FormFields />
    </SimpleForm>
  )
}
