const mapping: Record<
  string,
  (lambdaParameter: string, value: any, otherValues: any) => string | null
> = {
  ailmentSurveyId: (lambdaParameter, value) =>
    `${lambdaParameter}.AilmentSurveyId.Equals(${value})`,
  patientId: (lambdaParameter, value) =>
    `${lambdaParameter}.AilmentSurvey.PatientId.Equals(${value})`,
  ailmentType: (lambdaParameter, value) =>
    `${lambdaParameter}.AilmentType.Equals(${value})`,
}

const filterMapper = (key: string) =>
  mapping[key] as (
    lambdaParameter: string,
    value: any,
    otherValues: any,
  ) => string

export default filterMapper
