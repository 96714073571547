import {
  CreateParams,
  CreateResult,
  DataProvider,
  DeleteParams,
  DeleteResult,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  Identifier,
  UpdateParams,
  UpdateResult,
} from 'react-admin'
import { SCHEDULERS_URL } from '../api-urls'
import { DateTime } from '../common/date-time'
import {
  get,
  getWithPagination,
  patch,
  post,
  remove,
} from '../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../common/get-by-conditions.utils'
import { RadOnkAPIGetListParams } from '../common/rad-onk-api-get-list.params'
import { BrachytherapySchedulerDto } from '../dto/scheduler/brachytherapy-scheduler.dto'
import { ChemotherapySchedulerDTO } from '../dto/scheduler/chemotherapy-scheduler.dto'
import { ExaminationSchedulerDTO } from '../dto/scheduler/examination-scheduler.dto'
import { RadiotherapySchedulerDTO } from '../dto/scheduler/radiotherapy-scheduler.dto'
import { RecommendationSchedulerDTO } from '../dto/scheduler/recommendation-scheduler.dto'
import { SchedulerBaseDto } from '../dto/scheduler/scheduler-base.dto'
import { SchedulerTypes } from '../enum/SchedulerTypes'
import filterMapper from './schedulers-filter.mapper'
import sortMapper from './schedulers-sort.mapper'

const provider = {
  getList: async (
    resource: string,
    { filter: { ...filter }, sort, pagination }: RadOnkAPIGetListParams,
  ): Promise<
    GetListResult<
      | BrachytherapySchedulerDto
      | ChemotherapySchedulerDTO
      | ExaminationSchedulerDTO
      | RadiotherapySchedulerDTO
      | RecommendationSchedulerDTO
    >
  > => {
    const filterParams = `s=>${filterParamsComposer('s', filter, filterMapper)}`
    const pathParams = queryParamsComposer(sort, pagination, sortMapper)
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<SchedulerBaseDto[]>(
      `${SCHEDULERS_URL}/GetByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<
    GetOneResult<
      | BrachytherapySchedulerDto
      | ChemotherapySchedulerDTO
      | ExaminationSchedulerDTO
      | RadiotherapySchedulerDTO
      | RecommendationSchedulerDTO
    >
  > => {
    const data = await get<SchedulerBaseDto>(`${SCHEDULERS_URL}`, `/${id}`)
    return Promise.resolve({
      data,
    })
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<SchedulerBaseDto>> => {
    const data = await get<SchedulerBaseDto[]>(
      `${SCHEDULERS_URL}/GetByConditions`,
      `/s=>new int[] {${ids.toString()}}.Contains(s.Id)`,
    )
    return Promise.resolve({
      data,
    })
  },
  create: async (
    resource: string,
    { data }: CreateParams<CreateSchedulerRequest>,
  ): Promise<CreateResult> => {
    const { schedulerType, ...restData } = data as any

    const created = await post<CreateSchedulerRequest[], SchedulerBaseDto[]>(
      `${SCHEDULERS_URL}/${schedulerType}`,
      [
        {
          ...restData,
          description:
            (schedulerType === SchedulerTypes.EXAMINATION ||
              schedulerType === SchedulerTypes.RECOMMENDATION) &&
            data.description
              ? data.description
              : undefined,
        },
      ],
    )
    return Promise.resolve({ data: created[0] })
  },
  update: async (
    resource: string,
    { id, data, previousData }: UpdateParams<SchedulerBaseDto>,
  ): Promise<UpdateResult> => {
    const update = await patch<
      { id: Identifier; [n: string]: any },
      SchedulerBaseDto
    >(`${SCHEDULERS_URL}`, {
      id,
      date: data.date !== previousData.date ? data.date : undefined,
      patientId:
        data.patientId !== previousData.patientId ? data.patientId : undefined,
      description:
        (data.schedulerType === SchedulerTypes.EXAMINATION ||
          data.schedulerType === SchedulerTypes.RECOMMENDATION) &&
        (data as ExaminationSchedulerDTO | RecommendationSchedulerDTO)
          .description !== previousData.description
          ? (data as ExaminationSchedulerDTO | RecommendationSchedulerDTO)
              .description
          : undefined,
    })
    return Promise.resolve({ data: update })
  },
  delete: async (
    resource: string,
    { id }: DeleteParams,
  ): Promise<DeleteResult> => {
    const data = await remove<SchedulerBaseDto>(`${SCHEDULERS_URL}`, `/${id}`)
    return {
      data: {
        id: data.id,
      },
    }
  },
} as DataProvider

export interface CreateSchedulerRequest {
  readonly date?: DateTime
  readonly patientId?: number
  readonly description?: string
  // readonly radiotherapyMachineId?: number
  // readonly activityName?: string
  // readonly appointmentStatus?: RadiotherapyAppointmentStatuses
}

export default provider
