const mapping: Record<
  string,
  (lambdaParameter: string, value: any, otherValues: any) => string | null
> = {
  date: (lambdaParameter, value) =>
    `(${lambdaParameter}.DateFrom <= DateTime.Parse("${
      value.split(';')[1]
    }").ToUniversalTime() && ${lambdaParameter}.DateTo >= DateTime.Parse("${
      value.split(';')[0]
    }").ToUniversalTime())`,
  mainDoctorId: (lambdaParameter, value) =>
    `(${lambdaParameter}.MainDoctorId.Equals(${value}))`,
  temporaryDoctorId: (lambdaParameter, value) =>
    `(${lambdaParameter}.TemporaryDoctorId.Equals(${value}))`,
}

const filterMapper = (key: string) =>
  mapping[key] as (
    lambdaParameter: string,
    value: any,
    otherValues: any,
  ) => string

export default filterMapper
