import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core'
import React, { useState } from 'react'
import {
  useTranslate,
  sanitizeFetchType,
  useRefresh,
  useMutation,
} from 'react-admin'
import { ResourceName } from '../../../core/ResourceName'
import Button from '../../common/customized-mui-components/Button'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogProperty: {
      marginBottom: theme.spacing(1),
    },
  }),
)

export const ConfirmDialog = ({
  confirm,
  cancel,
  source,
  open,
  action,
}: {
  confirm: () => void
  cancel: () => void
  source: string
  open: boolean
  action: 'set' | 'remove'
}) => {
  const translate = useTranslate()
  return (
    <Dialog open={open}>
      <DialogTitle>
        {translate(`resources.users.dialogs.confirm.${source}.${action}`)}
      </DialogTitle>
      <DialogActions>
        <Button
          variant="contained"
          onClick={confirm}
          label={translate('ra.action.save')}
          useSmallVersionBreakpoint={false}
        />
        <Button
          variant="contained"
          onClick={cancel}
          label={translate('const.cancel')}
          useSmallVersionBreakpoint={false}
        />
      </DialogActions>
    </Dialog>
  )
}

export const ChangePasswordDialog = ({
  open,
  close,
}: {
  open: boolean
  close: () => void
}) => {
  const classes = useStyles()
  const [value, setValue] = useState<string>('')
  const [error, setError] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [mutate] = useMutation()
  const refresh = useRefresh()
  const translate = useTranslate()

  const handleClose = () => {
    setValue('')
    setError(false)
    setErrorText('')
    close()
  }

  const handleValidate = () => {
    setError(false)
    setErrorText('')
    // if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/.test(value)) {
    //   setError(true)
    //   setErrorText(
    //     'resources.users.dialogs.changePassword.error-password-requirements',
    //   )
    // } else if (value.replace(/^[a-zA-Z0-9]*$/, '').length !== 0) {
    //   setError(true)
    //   setErrorText('resources.users.dialogs.changePassword.error-bad-string')
    // } else
    setConfirmOpen(true)
  }

  const handleChangeUserPassword = async () => {
    setConfirmOpen(false)
    await mutate(
      {
        type: sanitizeFetchType('changePassword'),
        resource: ResourceName.CURRENT_USER,
        payload: {
          newPassword: value,
        },
      },
      { returnPromise: true },
    )
    setValue('')
    refresh()
    close()
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <ConfirmDialog
        open={confirmOpen}
        cancel={() => setConfirmOpen(false)}
        confirm={handleChangeUserPassword}
        source="changePassword"
        action="set"
      />
      <DialogTitle>
        {translate('resources.users.dialogs.changePassword.title')}
      </DialogTitle>
      <DialogContent>
        <TextField
          className={classes.dialogProperty}
          variant="outlined"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          fullWidth
          error={error}
          helperText={translate(errorText)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleValidate}
          label={translate('ra.action.save')}
          useSmallVersionBreakpoint={false}
        />
        <Button
          variant="contained"
          onClick={handleClose}
          label={translate('const.cancel')}
          useSmallVersionBreakpoint={false}
        />
      </DialogActions>
    </Dialog>
  )
}
