import { usePermissions } from 'react-admin'
import { Authority } from '../../core/auth/Authority'
import { mapAuthorityToRequiredPermissions } from '../../core/auth/authority.mapper'

export const hasAuthorityWithGivenPermissions = (
  authority: Authority,
  permissions: Set<string>,
) => {
  const permissionSet = new Set(permissions)
  const requiredPerms = mapAuthorityToRequiredPermissions(authority)
  return requiredPerms.some((reqPerms) => permissionSet.has(reqPerms))
}

export const useHasAuthority = () => {
  const { permissions, loaded } = usePermissions<Set<string>>()
  return (authority: Authority) => {
    if (loaded && !permissions) {
      // eslint-disable-next-line no-console
      console.error(
        '"useHasAuthority" - No permissions or hook was used outside the react admin context',
      )
      return false
    }
    return hasAuthorityWithGivenPermissions(
      authority,
      permissions as Set<string>,
    )
  }
}
