import { DataProvider } from 'react-admin'
import { RadiotherapyAilmentSurveyDTO } from '../../dto/radiotherapy-ailment-survey/radiotherapy-ailment-survey.dto'

const fakeRadiotherapyAilmentSurveys: RadiotherapyAilmentSurveyDTO[] = []

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({
      data: fakeRadiotherapyAilmentSurveys,
      total: fakeRadiotherapyAilmentSurveys.length,
    }),
} as DataProvider

export default provider
