import { HttpError, fetchUtils } from 'react-admin'
import { ContentRangeHeaderValue, contentRangeParser } from './header.parser'
import { ApiErrorBodyDto } from '../dto/error-handling/api-error-body.dto'

const baseHeaders: HeadersInit = {
  'Content-Type': 'application/json',
}

const catchCustomError = (err: HttpError) => {
  const customBody = err?.body as ApiErrorBodyDto
  let errorCode
  if (customBody?.details?.[0]?.errorCode)
    errorCode = customBody?.details?.[0]?.errorCode
  else if (customBody?.code?.length === 9)
    errorCode = customBody?.code?.substring(5, 9)
  return Promise.reject(
    new HttpError(
      errorCode
        ? `common.api-error-handling.messages.${errorCode}`
        : err.message,
      err.status,
      err.body,
    ),
  )
}

const fetchBlob = (url: string, options: fetchUtils.Options = {}) => {
  const requestHeaders = fetchUtils.createHeadersFromOptions(options)

  return fetch(url, { ...options, headers: requestHeaders })
    .then((response) =>
      response.blob().then((blob) => ({
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        body: blob,
      })),
    )
    .catch(catchCustomError)
    .then(({ status, statusText, headers, body }) => {
      if (status < 200 || status >= 300) {
        return Promise.reject(new HttpError(statusText, status))
      }
      return Promise.resolve({ status, headers, body })
    })
}

const getWithHeaders = <T>(url: string, headers: HeadersInit) =>
  fetchUtils
    .fetchJson(url, {
      method: 'GET',
      headers: new Headers(headers),
    })
    .catch(catchCustomError)
    .then(({ json }) => json as Promise<T>)

const postWithHeaders = <T, K>(url: string, body: T, headers: HeadersInit) =>
  fetchUtils
    .fetchJson(url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: new Headers(headers),
    })
    .catch(catchCustomError)
    .then(({ json }) => json as Promise<K>)

const getBlobWithHeaders = (url: string, headers: HeadersInit) =>
  fetchBlob(url, {
    method: 'GET',
    headers: new Headers(headers),
  }).then((data) => data)

export const get = <T>(baseUrl: string, path?: string) =>
  getWithHeaders<T>(`${baseUrl}${path || ''}`, baseHeaders)

export const getBlob = (baseUrl: string, path?: string) =>
  getBlobWithHeaders(`${baseUrl}${path || ''}`, baseHeaders)

export const getByConditions = <T>(
  baseUrl: string,
  path: string,
): Promise<T[]> =>
  getWithHeaders<T[]>(`${baseUrl}/GetByConditions/${path}`, baseHeaders)

export const getWithPagination = <T>(baseUrl: string, path: string) =>
  fetchUtils
    .fetchJson(`${baseUrl}${path}`, {
      method: 'GET',
      headers: new Headers(baseHeaders),
    })
    .catch(catchCustomError)
    .then(({ json, headers }) =>
      Promise.resolve({
        data: json as T,
        range: contentRangeParser(
          headers.get('content-range') as ContentRangeHeaderValue,
        ),
      }),
    )

export const authPost = <T, K>(url: string, body: T) =>
  postWithHeaders<T, K>(url, body, baseHeaders)

export const post = <T, K>(url: string, body: T) =>
  postWithHeaders<T, K>(url, body, baseHeaders)

export const patch = <T, K>(baseUrl: string, body: T) =>
  fetchUtils
    .fetchJson(baseUrl, {
      method: 'PATCH',
      body: JSON.stringify(body),
      headers: new Headers(baseHeaders),
    })
    .catch(catchCustomError)
    .then(({ json }) => json as Promise<K>)

export const put = <T, K>(baseUrl: string, body: T) =>
  fetchUtils
    .fetchJson(baseUrl, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: new Headers(baseHeaders),
    })
    .catch(catchCustomError)
    .then(({ json }) => json as Promise<K>)

export const remove = <T>(baseUrl: string, path: string) =>
  fetchUtils
    .fetchJson(`${baseUrl}${path || ''}`, {
      method: 'DELETE',
      headers: new Headers(baseHeaders),
    })
    .catch(catchCustomError)
    .then(({ json }) => json as Promise<T>)
