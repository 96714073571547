import { CardContent, CardHeader } from '@material-ui/core'
import { stringify } from 'querystring'
import * as React from 'react'
import { useCallback, useState } from 'react'
import {
  GetListResult,
  GET_LIST,
  sanitizeFetchType,
  useMutation,
  useNotify,
  useTranslate,
  useQueryWithStore,
  GET_ONE,
} from 'react-admin'
import { useHistory } from 'react-router-dom'
import { ResourceName } from '../../core/ResourceName'
import SearchInput from './SearchInput'
import { UserBaseDto } from '../../core/dto/user/user-base.dto'
import { UserTypes } from '../../core/enum/UserTypes'
import { PatientDto } from '../../core/dto/user/patient.dto'

export const FindPatient = () => {
  const history = useHistory()
  const notify = useNotify()
  const [error, setError] = useState('')
  const [mutate, { loading }] = useMutation()
  const t = useTranslate()
  const { data: user } = useQueryWithStore({
    type: sanitizeFetchType(GET_ONE),
    resource: ResourceName.CURRENT_USER,
    payload: {},
  })

  const fetchPatients = useCallback(
    (filterString: string) =>
      mutate(
        {
          type: sanitizeFetchType(GET_LIST),
          resource: ResourceName.PATIENTS,
          payload: {
            filter: {
              fullNameSearch: filterString,
              patientsOnly: true,
              active: true,
              filterRelatedPatients:
                (user as UserBaseDto)?.userType === UserTypes.DOCTOR,
              relatedPatientDoctorId: (user as UserBaseDto)?.id,
            },
          },
        },
        {
          returnPromise: true,
          onFailure: (err) => notify(err?.message, 'error'),
        },
      ) as Promise<GetListResult<PatientDto>>,
    [mutate, notify, user],
  )

  const onValue = useCallback(
    async (filterString: string) => {
      setError('')
      const {
        total,
        data: [onlyPatient],
      } = await fetchPatients(filterString)
      if (!total) {
        setError('common.find-patient.error')
      } else if (total === 1) {
        history.push({
          pathname: `/${ResourceName.PATIENTS}/${onlyPatient.id}/show`,
        })
      } else {
        history.push({
          pathname: `/${ResourceName.PATIENTS}`,
          search: stringify({
            filter: JSON.stringify({
              fullNameSearch: filterString,
              patientsOnly: true,
              active: true,
              filterRelatedPatients:
                (user as UserBaseDto)?.userType === UserTypes.DOCTOR,
              relatedPatientDoctorId: (user as UserBaseDto)?.id,
            }),
          }),
        })
      }
    },
    [fetchPatients, history, user],
  )

  return (
    <>
      <CardHeader title={t('common.find-patient.title')} />
      <CardContent>
        <SearchInput
          label="common.find-patient.input-label"
          onValue={onValue}
          disabled={loading}
          error={error}
        />
      </CardContent>
    </>
  )
}
