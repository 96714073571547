import React, { cloneElement, useContext } from 'react'
import {
  CreateButton,
  Datagrid,
  Filter,
  FilterButton,
  FilterContext,
  ListProps,
  Pagination,
  sanitizeListRestProps,
  SearchInput,
  TextField,
  TopToolbar,
  useListContext,
} from 'react-admin'
import { Authority } from '../../core/auth/Authority'
import { UserTypes } from '../../core/enum/UserTypes'
import { ResourceName } from '../../core/ResourceName'
import List from '../common/customized-ra-components/List'
import FilterProps from '../common/FilterProps'
import { useHasAuthority } from '../hooks/useHasAuthority'

const DoctorsActions = ({ ...props }) => {
  const { filters: filtersProp, basePath } = props
  const resource = ResourceName.DOCTORS
  const hasAuthority = useHasAuthority()

  const { displayedFilters, filterValues, showFilter } = useListContext(props)
  const filters = useContext(FilterContext) || filtersProp

  return (
    <TopToolbar {...sanitizeListRestProps(props)}>
      {filtersProp
        ? cloneElement(filtersProp, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })
        : filters && <FilterButton />}
      {hasAuthority(Authority.CREATE_DOCTORS) && (
        <CreateButton
          resource={ResourceName.DOCTORS}
          to={{
            pathname: `${basePath}/create`,
            search: new URLSearchParams({
              userType: UserTypes.DOCTOR,
            }).toString(),
          }}
        />
      )}
    </TopToolbar>
  )
}

const DoctorsFilters = (props: FilterProps) => (
  <Filter {...props}>
    <SearchInput source="fullNameSearch" alwaysOn />
  </Filter>
)

const DoctorGrid = () => {
  const { loading } = useListContext()

  return (
    <Datagrid
      size="small"
      loaded={!loading}
      rowClick={(id) => `${ResourceName.DOCTORS}/${id}/show`}
    >
      <TextField source="id" label="ID" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="userName" />
    </Datagrid>
  )
}

export const DoctorList = ({ ...props }: ListProps) => (
  <List
    {...props}
    perPage={20}
    pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
    exporter={false}
    filters={<DoctorsFilters />}
    filterDefaultValues={{ doctorsOnly: true }}
    actions={<DoctorsActions />}
  >
    <DoctorGrid />
  </List>
)
