var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import * as React from 'react';
import { useMemo } from 'react';
import { Button, FormGroup, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { useRefresh, useTranslate } from 'react-admin';
import { usePreferences } from '../../usePreferences';
import { ComputedFieldEditor } from './ComputedFieldEditor';
import { FieldEditor, getCustomFieldPath } from './FieldEditor';
import { useComputedFields } from './useComputedFields';
import { Route, Switch, Link as RouterLink, useHistory, } from 'react-router-dom';
import { EditorTitle } from '../Editor';
export var FieldsEditor = function (props) {
    var defaultFields = props.defaultFields, preferencesKey = props.preferencesKey, resource = props.resource;
    var translate = useTranslate();
    var refresh = useRefresh();
    var classes = useStyles();
    var history = useHistory();
    var columnsPreferencesKey = preferencesKey + ".columns";
    var customFieldsPreferencesKey = preferencesKey + ".customFields";
    var _a = usePreferences(columnsPreferencesKey, Object.keys(defaultFields)), columnsFromPrefs = _a[0], setColumnsFromPref = _a[1];
    var _b = usePreferences(customFieldsPreferencesKey, []), customFieldsFromPrefs = _b[0], setCustomFieldsFromPref = _b[1];
    var customFields = useComputedFields({
        preferences: customFieldsPreferencesKey,
    });
    var availableFields = useMemo(function () {
        var fields = __assign({}, defaultFields);
        customFields.forEach(function (field) {
            fields[field.props.source] = field;
        });
        return fields;
    }, [customFields, defaultFields]);
    var addColumn = function (event) {
        setColumnsFromPref(Object.keys(availableFields).filter(function (name) {
            return name === event.target.name ||
                columnsFromPrefs.includes(name);
        }));
    };
    var removeColumn = function (event) {
        // always leave at least one column
        if (columnsFromPrefs.length > 1) {
            setColumnsFromPref(columnsFromPrefs.filter(function (name) { return name !== event.target.name; }));
        }
    };
    var handleColumnToggle = function (event) {
        if (event.target.checked) {
            addColumn(event);
        }
        else {
            removeColumn(event);
        }
    };
    var handleSaveCustomField = function (values) {
        var existingFieldIndex = customFieldsFromPrefs.findIndex(function (field) { return field.id === values.id; });
        if (existingFieldIndex >= 0) {
            setCustomFieldsFromPref(__spreadArray(__spreadArray(__spreadArray([], customFieldsFromPrefs.slice(0, existingFieldIndex)), [
                values
            ]), customFieldsFromPrefs.slice(existingFieldIndex + 1)));
        }
        else {
            setCustomFieldsFromPref(customFieldsFromPrefs.concat(values));
            setColumnsFromPref(columnsFromPrefs.concat(values.template));
        }
        history.push('/');
        // Because react-admin won't rerender the datagrid header cells when the field props change, we have to refresh the view
        refresh();
    };
    var handleDeleteCustomField = function (field) {
        setColumnsFromPref(columnsFromPrefs.filter(function (column) { return column !== field.template; }));
        setCustomFieldsFromPref(customFieldsFromPrefs.filter(function (field) { return field.id !== field.id; }));
        history.push('/');
        // Because react-admin won't rerender the datagrid header cells when the field props change, we have to refresh the view
        refresh();
    };
    var handleCustomFieldPaneClose = function () {
        history.push('/');
    };
    return (React.createElement(Switch, null,
        React.createElement(Route, { path: "/create-custom-field", render: function () { return (React.createElement(React.Fragment, null,
                React.createElement(EditorTitle, { title: "ra-preferences.no-code.editor.custom_field" }),
                React.createElement(ComputedFieldEditor, { availableColumns: Object.keys(availableFields)
                        .filter(function (column) { return !column.includes('{{'); })
                        .map(function (column) { return [
                        column,
                        availableFields[column].props.label,
                    ]; }), field: DefaultNewCustomField, onCancel: handleCustomFieldPaneClose, onSave: handleSaveCustomField, resource: resource }))); } }),
        customFieldsFromPrefs.map(function (field) { return (React.createElement(Route, { key: field.template, path: getCustomFieldPath(field), render: function () { return (React.createElement(React.Fragment, null,
                React.createElement(EditorTitle, { title: "ra-preferences.no-code.editor.custom_field" }),
                React.createElement(ComputedFieldEditor, { availableColumns: Object.keys(availableFields)
                        .filter(function (column) { return !column.includes('{{'); })
                        .map(function (column) { return [
                        column,
                        availableFields[column].props.label,
                    ]; }), field: field, onCancel: handleCustomFieldPaneClose, onDelete: function () { return handleDeleteCustomField(field); }, onSave: handleSaveCustomField, resource: resource }))); } })); }),
        React.createElement(Route, { path: "/", render: function () { return (React.createElement(React.Fragment, null,
                React.createElement(Typography, { id: resource + "-columns-description", variant: "subtitle2", color: "textSecondary", gutterBottom: true }, translate('ra-preferences.no-code.action.choose_fields')),
                React.createElement(FormGroup, null, Object.keys(availableFields).map(function (source) { return (React.createElement(FieldEditor, { key: source, selected: columnsFromPrefs.some(function (column) { return column === source; }), source: source, label: availableFields[source].props.label, customField: customFieldsFromPrefs.find(function (field) { return field.template === source; }), onToggle: handleColumnToggle, resource: resource })); })),
                React.createElement(Button, { component: RouterLink, to: "/create-custom-field", startIcon: React.createElement(AddIcon, null), className: classes.addFieldButton }, translate('ra-preferences.no-code.action.add_computed_field')))); } })));
};
var DefaultNewCustomField = {};
var useStyles = makeStyles(function (theme) { return ({
    addFieldButton: {
        marginLeft: theme.spacing(0.5),
    },
}); });
