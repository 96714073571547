import React from 'react'
import { Admin } from 'react-admin'
import { Dashboard } from './main/Dashboard'
import { providersFactory } from './core/providers.factory'
import { generateResourcesBasedOnPermissions } from './main/Resources'
import themeReducer from './core/common/theme.reducer'
import routes from './main/common/routes'
import { AppLayout } from './main/layout/AppLayout'

const App = () => {
  const { authProvider, dataProvider, i18nProvider } = providersFactory(
    process.env.NODE_ENV,
  )
  return (
    <Admin
      dashboard={Dashboard}
      authProvider={authProvider}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      customReducers={{
        theme: themeReducer,
      }}
      customRoutes={routes}
      layout={AppLayout}
    >
      {generateResourcesBasedOnPermissions}
    </Admin>
  )
}

export default App
