var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useRecordContext, useTranslate, getFieldLabelTranslationArgs, } from 'react-admin';
import { useGetResourceLabel } from './useGetResourceLabel';
/**
 * Returns a translated label for an event.
 * @param options The hook options
 * @param options.record Optional. The event record. Uses the record from the RecordContext if not provided.
 * @param options.variant Optional. Either 'full', 'inline' or 'record'.
 * @param options.pluralizeResource Optional. A boolean indicating whether to pluralize the resource name.
 */
export var useEventLabel = function (options) {
    var _a = options.variant, variant = _a === void 0 ? 'full' : _a, pluralizeResource = options.pluralizeResource;
    var record = useRecordContext(options);
    var getResourceLabel = useGetResourceLabel();
    var translate = useTranslate();
    if (!record) {
        return null;
    }
    var pluralize = pluralizeResource != undefined
        ? pluralizeResource
        : actionPluralOptions[record.action];
    var _b = record.payload, _c = _b.data, data = _c === void 0 ? {} : _c, _d = _b.ids, ids = _d === void 0 ? [] : _d, id = _b.id;
    var name = (data === null || data === void 0 ? void 0 : data.name) ||
        (data === null || data === void 0 ? void 0 : data.label) ||
        (data === null || data === void 0 ? void 0 : data.title) ||
        (data === null || data === void 0 ? void 0 : data.reference) ||
        (data === null || data === void 0 ? void 0 : data.id) ||
        id;
    var fields = [];
    if (record.payload.previousData) {
        for (var _i = 0, _e = Object.keys(record.payload.previousData); _i < _e.length; _i++) {
            var field = _e[_i];
            if (record.payload.data &&
                record.payload.data[field] !==
                    record.payload.previousData[field]) {
                fields.push(translate.apply(void 0, getFieldLabelTranslationArgs({
                    source: field,
                    resource: record.resource,
                })));
            }
        }
    }
    var translateOptions = {
        name: name,
        ids: ids.join(', '),
        resource: getResourceLabel(record.resource, pluralize ? 2 : 1).toLowerCase(),
        fields: fields.slice(0, 2).join(', '),
        all_fields: fields.join(', '),
        smart_count: variant === 'record' && fields.length > 2
            ? fields.length - 2
            : fields.length,
    };
    var finalVariant = variant === 'record' && fields.length > 2
        ? 'record_many_fields'
        : variant;
    var label = translate("ra-audit-log." + variantTranslationKeys[finalVariant] + "." + record.resource + "." + record.action, __assign(__assign({}, translateOptions), { 
        // Default generic translation when there is no resource specific translation available
        _: translate("ra-audit-log." + variantTranslationKeys[finalVariant] + "." + record.action, translateOptions) }));
    return label;
};
var variantTranslationKeys = {
    full: 'event',
    inline: 'inline_event',
    record: 'record_event',
    record_many_fields: 'record_many_fields_events',
};
var actionPluralOptions = {
    create: false,
    delete: false,
    deleteMany: true,
    update: false,
    updateMany: true,
};
