import { DataProvider } from 'react-admin'
import { ChemotherapyAilmentSurveyDTO } from '../../dto/chemotherapy-ailment-survey/chemotherapy-ailment-survey.dto'

const fakeChemotherapyAilmentSurveys: ChemotherapyAilmentSurveyDTO[] = []

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({
      data: fakeChemotherapyAilmentSurveys,
      total: fakeChemotherapyAilmentSurveys.length,
    }),
} as DataProvider

export default provider
