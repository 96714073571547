import { Box, Divider, Typography } from '@material-ui/core'
import React from 'react'
import {
  DateField,
  EditButton,
  FunctionField,
  ReferenceField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
  useShowContext,
  useTranslate,
} from 'react-admin'
import { Authority } from '../../core/auth/Authority'
import { RadiotherapySchedulerDTO } from '../../core/dto/scheduler/radiotherapy-scheduler.dto'
import { SchedulerBaseDto } from '../../core/dto/scheduler/scheduler-base.dto'
import { SchedulerTypes } from '../../core/enum/SchedulerTypes'
import { ResourceName } from '../../core/ResourceName'
import { DeleteButtonWithConfirm } from '../common/DeleteButtonWithConfirm'
import { useHasAuthority } from '../hooks/useHasAuthority'
import { PatientDto } from '../../core/dto/user/patient.dto'
import { RadiotherapyMachineDto } from '../../core/dto/radiotherapy-machine.dto'

const ShowTitle = () => {
  const translate = useTranslate()
  return <span>{translate('resources.schedulers.titles.show')}</span>
}

const GeneralActions = ({ ...props }) => {
  const { record: scheduler } = useShowContext<SchedulerBaseDto>()
  const hasAuthority = useHasAuthority()

  return (
    <>
      <Divider />
      <Box p={1} display="block" textAlign="right">
        {scheduler?.schedulerType !== SchedulerTypes.RADIOTHERAPY &&
          hasAuthority(Authority.EDIT_SCHEDULERS) && (
            <EditButton
              basePath={`/${ResourceName.SCHEDULERS}`}
              record={scheduler}
              variant="contained"
              style={{ margin: '2px' }}
            />
          )}
        {scheduler?.schedulerType !== SchedulerTypes.RADIOTHERAPY &&
          hasAuthority(Authority.EDIT_SCHEDULERS) && (
            <DeleteButtonWithConfirm {...props} />
          )}
      </Box>
    </>
  )
}

const SchedulerLayout = ({ ...props }) => {
  const translate = useTranslate()
  const { record: schedulerData } = useShowContext<SchedulerBaseDto>()

  return (
    <SimpleShowLayout {...props}>
      <TextField source="id" label="ID" />
      <ReferenceField
        source="patientId"
        reference={ResourceName.PATIENTS}
        link="show"
        emptyText="-"
        sortable={false}
      >
        <FunctionField<PatientDto>
          source="patientId"
          render={(record?: PatientDto) => (
            <Typography variant="body2">
              {record?.firstName} {record?.lastName}
            </Typography>
          )}
        />
      </ReferenceField>
      <DateField
        source="date"
        showTime
        options={{
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        }}
        emptyText="-"
      />
      <FunctionField<SchedulerBaseDto>
        source="schedulerType"
        sortable={false}
        render={(record?: SchedulerBaseDto) => (
          <div>
            {translate(
              `resources.enums.schedulerTypes.${record?.schedulerType}`,
            )}
          </div>
        )}
      />
      {(schedulerData?.schedulerType === SchedulerTypes.EXAMINATION ||
        schedulerData?.schedulerType === SchedulerTypes.RECOMMENDATION) && (
        <TextField source="description" />
      )}
      {schedulerData?.schedulerType === SchedulerTypes.RADIOTHERAPY && (
        <ReferenceField
          source="radiotherapyMachineId"
          reference={ResourceName.RADIOTHERAPY_MACHINES}
          link={false}
          emptyText="-"
          sortable={false}
        >
          <FunctionField<RadiotherapyMachineDto>
            source="radiotherapyMachineId"
            render={(record?: RadiotherapyMachineDto) => (
              <Typography variant="body2">{record?.name}</Typography>
            )}
          />
        </ReferenceField>
      )}
      {schedulerData?.schedulerType === SchedulerTypes.RADIOTHERAPY && (
        <TextField source="activityName" />
      )}
      {schedulerData?.schedulerType === SchedulerTypes.RADIOTHERAPY && (
        <FunctionField<SchedulerBaseDto>
          source="appointmentStatus"
          render={(record?: RadiotherapySchedulerDTO) => (
            <div>
              {translate(
                `resources.enums.radiotherapyAppointmentStatuses.${record?.appointmentStatus}`,
              )}
            </div>
          )}
        />
      )}
      <GeneralActions />
    </SimpleShowLayout>
  )
}

export const SchedulerShow = (props: ShowProps) => (
  <Show {...props} actions={false} title={<ShowTitle />}>
    <SchedulerLayout />
  </Show>
)
