import { DataProvider, sanitizeFetchType } from 'react-admin'
import { ResourceName } from '../ResourceName'
import fakePermissionsProvider from './providers/fake-permissions.provider'
import fakeRolesProvider from './providers/fake-roles.provider'
import fakeUsersProvider from './providers/fake-user.provider'
import fakeSchedulersProvider from './providers/fake-scheduler.provider'
import fakeRadiotherapyAilmentSurveysProvider from './providers/fake-radiotherapy-ailment-surveys.provider'
import fakeRadiotherapyAilmentsProvider from './providers/fake-radiotherapy-ailments.provider'
import fakeRadiotherapyMachinesProvider from './providers/fake-radiotherapy-machines.provider'
import fakeVacationCalendarsProvider from './providers/fake-vacation-calendars.provider'
import fakeChemotherapyAilmentsProvider from './providers/fake-chemotherapy-ailments.provider'
import fakeChemotherapyAilmentSurveysProvider from './providers/fake-chemotherapy-ailment-surveys.provider'

const dataProviders: Record<ResourceName, DataProvider> = {
  [ResourceName.CURRENT_USER]: fakeUsersProvider,
  [ResourceName.ROLES]: fakeRolesProvider,
  [ResourceName.PERMISSIONS]: fakePermissionsProvider,
  [ResourceName.USERS]: fakeUsersProvider,
  [ResourceName.DOCTORS]: fakeUsersProvider,
  [ResourceName.PATIENTS]: fakeUsersProvider,
  [ResourceName.WEB_USERS]: fakeUsersProvider,
  [ResourceName.RADIOTHERAPY_AILMENTS]: fakeRadiotherapyAilmentsProvider,
  [ResourceName.RADIOTHERAPY_AILMENT_SURVEYS]:
    fakeRadiotherapyAilmentSurveysProvider,
  [ResourceName.CHEMOTHERAPY_AILMENTS]: fakeChemotherapyAilmentsProvider,
  [ResourceName.CHEMOTHERAPY_AILMENT_SURVEYS]:
    fakeChemotherapyAilmentSurveysProvider,
  [ResourceName.SCHEDULERS]: fakeSchedulersProvider,
  [ResourceName.RADIOTHERAPY_MACHINES]: fakeRadiotherapyMachinesProvider,
  [ResourceName.VACATION_CALENDARS]: fakeVacationCalendarsProvider,
}

export default (
  fetchType: string,
  resource: string,
  params: unknown,
): Promise<unknown> => {
  const selectedProvider = dataProviders[resource as ResourceName]
  const type = sanitizeFetchType(fetchType)
  return selectedProvider[type](resource, params)
}
