import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core'
import React from 'react'
import { useMutation, useNotify, useTranslate } from 'ra-core'
import { Close, Cancel, GetApp as Download } from '@material-ui/icons'
import { SaveButton, Toolbar } from 'react-admin'
import refresh from 'ra-core/esm/sideEffect/refresh'
import DraggableComponent from '../../common/DraggableComponent'
import Button from '../../common/customized-mui-components/Button'
import { ResourceName } from '../../../core/ResourceName'
import { ExportToCSVForm } from './ExportToCSVForm'

const useStyles = makeStyles(() =>
  createStyles({
    bulkStateActions: {
      height: '16px',
      background: 'rgba(0,0,0,0.2)',
      minWidth: '300px',
      userSelect: 'none',
    },
    bulkProgressTitle: {
      marginLeft: '10px',
      marginRight: 'auto',
    },
    bulkProgressCloseButton: {
      margin: 0,
    },
    toolbar: {
      gap: 0,
      display: 'flex',
      alignItems: 'center',
      margin: 0,
      backgroundColor: 'transparent',
      flex: '0 0 auto',
      padding: '8px',
      justifyContent: 'flex-end',
    },
    toolbarButton: {
      margin: '2%',
      width: '96%',
    },
  }),
)

const ExportToCSVToolbar = ({ ...props }) => {
  const { onClose } = props
  const notify = useNotify()
  const classes = useStyles()
  const [mutate] = useMutation()
  const translate = useTranslate()

  const handleExportToCSV = async ({ ...params }) => {
    const {
      data: { body, headers },
    } = await mutate(
      {
        type: 'exportToCSV',
        resource: ResourceName.CHEMOTHERAPY_AILMENT_SURVEYS,
        payload: params,
      },
      {
        returnPromise: true,
        onFailure: (msg) => notify(msg, 'error'),
      },
    )

    const fileName = decodeURIComponent(
      headers?.get('Content-Disposition')?.split("filename*=UTF-8''")[1],
    )

    const objectUrl: string = URL.createObjectURL(body)
    const a: HTMLAnchorElement = document.createElement(
      'a',
    ) as HTMLAnchorElement

    a.href = objectUrl
    a.download = fileName
    document.body.appendChild(a)
    a.click()

    document.body.removeChild(a)
    URL.revokeObjectURL(objectUrl)

    refresh()
  }

  return (
    <Toolbar {...props} className={classes.toolbar}>
      <SaveButton
        size="small"
        className={classes.toolbarButton}
        label="resources.chemotherapy-ailment-surveys.actions.exportToCSV"
        onSave={handleExportToCSV}
        icon={<Download />}
      />
      <Button
        variant="contained"
        onClick={onClose}
        label={translate('const.cancel')}
        useSmallVersionBreakpoint={false}
        className={classes.toolbarButton}
      >
        <Cancel />
      </Button>
    </Toolbar>
  )
}

export const ExportToCSVDialog = ({
  open,
  close,
}: {
  open: boolean
  close: () => void
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  const handleClose = () => {
    close()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick
      disableEscapeKeyDown
      PaperComponent={DraggableComponent}
      aria-labelledby="draggable-dialog-title"
      maxWidth="lg"
    >
      <DialogActions
        className={classes.bulkStateActions}
        style={{ cursor: 'move' }}
        id="draggable-dialog-title"
      >
        <Typography className={classes.bulkProgressTitle}>
          {translate(
            'resources.chemotherapy-ailment-surveys.dialogs.exportToCSV.title',
          )}
        </Typography>
        <IconButton
          size="small"
          className={classes.bulkProgressCloseButton}
          onClick={handleClose}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogActions>
      <DialogContent>
        <ExportToCSVForm
          toolbar={<ExportToCSVToolbar onClose={handleClose} />}
        />
      </DialogContent>
    </Dialog>
  )
}
