import { useMediaQuery, useTheme } from '@material-ui/core'
import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  Create,
  CreateProps,
  DateTimeInput,
  ListButton,
  SaveButton,
  SimpleForm,
  Toolbar,
  ToolbarProps,
  useTranslate,
} from 'react-admin'
import { useLocation } from 'react-router'
import { ResourceName } from '../../core/ResourceName'
import FilteredReferenceInput from '../common/FilteredReferenceInput'
import { CreateVacationCalendarRequest } from '../../core/vacation-calendars/vacation-calendars.provider'
import { DoctorDto } from '../../core/dto/user/doctor.dto'
import { formatDate } from '../../core/common/date-time'

export const validateVacationCreate = (
  values: CreateVacationCalendarRequest,
) => {
  const errors: { [n: string]: string } = {}
  if (!values.mainDoctorId) {
    errors.mainDoctorId = 'ra.validation.required'
  }
  if (!values.temporaryDoctorId) {
    errors.temporaryDoctorId = 'ra.validation.required'
  }
  if (!values.dateFrom) {
    errors.dateFrom = 'ra.validation.required'
  }
  if (!values.dateTo) {
    errors.dateTo = 'ra.validation.required'
  }
  return errors
}

const CreateTitle = () => {
  const translate = useTranslate()
  return <span>{translate('resources.vacation-calendars.titles.create')}</span>
}

const CreateToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ListButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

export const VacationCalendarCreate = (props: CreateProps) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { search, state } = useLocation()
  const dateFrom = new URLSearchParams(search).get('dateFrom')
  const mainDoctorId = new URLSearchParams(search).get('mainDoctorId')

  return (
    <Create {...props} title={<CreateTitle />}>
      <SimpleForm
        redirect="list"
        validate={validateVacationCreate}
        toolbar={<CreateToolbar />}
        initialValues={{ ...(state as any), dateFrom, mainDoctorId }}
      >
        <FilteredReferenceInput
          source="mainDoctorId"
          defaultValue={null}
          reference={ResourceName.DOCTORS}
          sort={{ field: 'lastName', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          allowEmpty
          filterSource="fullNameSearch"
          filter={{
            doctorsOnly: true,
          }}
          emptyText="-"
          isRequired
          selectWithPaginationInputProps={{
            optionText: (record: DoctorDto) =>
              `${record.firstName} ${record.lastName}`,
            showFilter: true,
          }}
        />
        <FilteredReferenceInput
          source="temporaryDoctorId"
          defaultValue={null}
          reference={ResourceName.DOCTORS}
          sort={{ field: 'lastName', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          allowEmpty
          filterSource="fullNameSearch"
          filter={{
            doctorsOnly: true,
          }}
          emptyText="-"
          isRequired
          selectWithPaginationInputProps={{
            optionText: (record: DoctorDto) =>
              `${record.firstName} ${record.lastName}`,
            showFilter: true,
          }}
        />
        <DateTimeInput
          source="dateFrom"
          type="date"
          format={formatDate}
          parse={(value: string) => (value === '' ? null : value)}
          isRequired
        />
        <DateTimeInput
          source="dateTo"
          type="date"
          format={formatDate}
          parse={(value: string) => (value === '' ? null : value)}
          isRequired
        />
      </SimpleForm>
    </Create>
  )
}
