import { DataProvider } from 'react-admin'
import { RadiotherapyAilmentBaseDTO } from '../../dto/radiotherapy-ailment/radiotherapy-ailment-base.dto'

const fakeRadiotherapyAilments: RadiotherapyAilmentBaseDTO[] = []

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({
      data: fakeRadiotherapyAilments,
      total: fakeRadiotherapyAilments.length,
    }),
} as DataProvider

export default provider
