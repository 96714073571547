import {
  DataProvider,
  GetListResult,
  GetManyParams,
  GetManyResult,
} from 'react-admin'
import { ADMINISTRATOR_URL } from '../../api-urls'
import { get, getWithPagination } from '../../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../../common/get-by-conditions.utils'
import { RadOnkAPIGetListParams } from '../../common/rad-onk-api-get-list.params'
import { PermissionDto } from '../../dto/permission.dto'
import filterMapper from './permissions-filter.mapper'
import { mapSortRoleParam } from './permissions-sort.mapper'

const provider = {
  getList: async (
    resource: string,
    { filter: { ...filter }, sort, pagination }: RadOnkAPIGetListParams,
  ): Promise<GetListResult<PermissionDto>> => {
    const filterParams = `o=>${filterParamsComposer('o', filter, filterMapper)}`
    const pathParams = queryParamsComposer(sort, pagination, mapSortRoleParam)
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<PermissionDto[]>(
      `${ADMINISTRATOR_URL}/GetPermissionsByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  // getOne: async (
  //   resource: string,
  //   { id }: GetOneParams,
  // ): Promise<GetOneResult<RoleDto>> => {
  //   const data = await get<RoleDto>(
  //     `${ADMINISTRATOR_URL}/GetRoleById/`,
  //     id as string,
  //   )
  //   return Promise.resolve({
  //     data,
  //   })
  // },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<PermissionDto>> => {
    const data = await get<PermissionDto[]>(
      ADMINISTRATOR_URL,
      `/GetPermissionsByConditions/p=>new int[] {${ids.toString()}}.Contains(p.Id)`,
    )
    return Promise.resolve({ data })
  },
} as DataProvider

export default provider
