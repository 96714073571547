var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import * as React from 'react';
import { useState, useEffect } from 'react';
import { List, ListItem, IconButton, Button, TextField, } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import DeleteIcon from '@material-ui/icons/CancelOutlined';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { makeStyles } from '@material-ui/core/styles';
import isEqual from 'lodash/isEqual';
import { useTranslate } from 'ra-core';
import { useResourceModel } from '../ResourceModel';
import { PropertyValueInput } from './PropertyValueInput';
var autocompleteFilter = createFilterOptions();
/**
 * A controlled input component for editing object values
 *
 * Renders one row for each key/value pairs, allows adding and removing pairs.
 *
 * @example
 *
 * const App = () => {
 *   const [value, setValue] = useState({ foo: "bar " });
 *   const handleChange = (val) => {
 *     setValue(val);
 *   };
 *   return (<ObjectInput value={value} onChange={handleChange} />);
 * }
 */
export var ObjectInput = function (_a) {
    var resource = _a.resource, value = _a.value, onChange = _a.onChange, className = _a.className;
    var classes = useStyles();
    var translate = useTranslate();
    var _b = useState(function () {
        return value ? Object.entries(value) : [];
    }), entries = _b[0], setEntries = _b[1];
    // The form uses the local state, which updates immediately on user input
    // The changes are bubbled up (via onChange) when the user blurs an input, or deletes a row
    // Adding a empty row doesn't call onChange
    useEffect(function () {
        var localValue = Object.fromEntries(entries);
        if (value && !isEqual(value, localValue)) {
            setEntries(Object.entries(value));
        }
    }, [value]); // eslint-disable-line react-hooks/exhaustive-deps
    var model = useResourceModel(resource)[0];
    var keys = Object.keys((model === null || model === void 0 ? void 0 : model.fields) || {}).map(function (key) { return ({
        title: key,
        value: key,
    }); });
    var handleKeyChange = function (index) {
        return function (event, newKey) {
            if (typeof newKey === 'string') {
                setEntries(function (entries) { return __spreadArray(__spreadArray(__spreadArray([], entries.slice(0, index)), [
                    [newKey, entries[index][1]]
                ]), entries.slice(index + 1)); });
            }
            else if (newKey && newKey.value) {
                // Create a new value from the user input
                setEntries(function (entries) { return __spreadArray(__spreadArray(__spreadArray([], entries.slice(0, index)), [
                    [newKey && newKey.value, '']
                ]), entries.slice(index + 1)); });
            }
            else {
                setEntries(function (entries) { return __spreadArray(__spreadArray(__spreadArray([], entries.slice(0, index)), [
                    [newKey, '']
                ]), entries.slice(index + 1)); });
            }
            // onChange will be called when a value is set
        };
    };
    var handleValueChange = function (index) {
        return function (event, newValue) {
            var newEntries = __spreadArray(__spreadArray(__spreadArray([], entries.slice(0, index)), [
                [entries[index][0], newValue]
            ]), entries.slice(index + 1));
            onChange(Object.fromEntries(newEntries));
        };
    };
    var handleItemRemove = function (index) { return function () {
        onChange(Object.fromEntries(__spreadArray(__spreadArray([], entries.slice(0, index)), entries.slice(index + 1))));
        // the local state will be modified by the useEffect
    }; };
    var handleItemAdd = function () {
        setEntries(function (entries) { return __spreadArray(__spreadArray([], entries), [['', '']]); });
    };
    if (!model || !model.fields) {
        return null;
    }
    return (React.createElement(List, { className: className, dense: true },
        entries.map(function (_a, index) {
            var key = _a[0], val = _a[1];
            return (React.createElement(ListItem, { key: index, className: classes.filterItemRow },
                React.createElement(Autocomplete, { freeSolo: true, options: keys, value: key, onChange: handleKeyChange(index), filterOptions: function (options, params) {
                        var filtered = autocompleteFilter(options, params);
                        // Suggest the creation of a new value
                        if (params.inputValue !== '') {
                            filtered.push({
                                inputValue: params.inputValue,
                                title: "Add \"" + params.inputValue + "\"",
                            });
                        }
                        return filtered;
                    }, selectOnFocus: true, clearOnBlur: true, handleHomeEndKeys: true, className: classes.filterItemField, renderInput: function (params) { return (React.createElement(TextField, __assign({}, params, { label: "Key" }))); }, renderOption: function (option) { return option.title; }, getOptionLabel: function (option) {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        // Regular option
                        return option.title;
                    } }),
                React.createElement(PropertyValueInput, { name: key, value: val, onChange: handleValueChange(index), type: model.fields[key], className: classes.filterItemValue }),
                React.createElement(IconButton, { "arial-label": "ra.action.delete", onClick: handleItemRemove(index), size: "small", className: classes.deleteButton },
                    React.createElement(DeleteIcon, null))));
        }),
        React.createElement(ListItem, null,
            React.createElement(Button, { variant: "outlined", size: "small", startIcon: React.createElement(AddIcon, null), onClick: handleItemAdd }, translate('ra-preferences.no-code.list.filter.add', {
                _: 'Add condition',
            })))));
};
var useStyles = makeStyles(function (theme) { return ({
    filterItemRow: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: 0,
    },
    filterItemField: {
        flex: 1,
        '&:first-child': {
            marginRight: theme.spacing(1),
        },
    },
    filterItemValue: {
        flex: 1,
    },
    deleteButton: {
        height: 30,
    },
}); });
