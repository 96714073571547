import React from 'react'
import {
  Datagrid,
  ListProps,
  Pagination,
  TextField,
  TextInput,
  useListContext,
  Filter,
  FunctionField,
  useTranslate,
} from 'react-admin'
import { RoleDto } from '../../../core/dto/role/role.dto'
import { ResourceName } from '../../../core/ResourceName'
import List from '../../common/customized-ra-components/List'
import FilterProps from '../../common/FilterProps'

const RolesFilters = (props: FilterProps) => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn />
  </Filter>
)

const RolesGrid = () => {
  const { loading } = useListContext()
  const translate = useTranslate()
  return (
    <Datagrid
      size="small"
      loaded={!loading}
      rowClick={(id) => `${ResourceName.ROLES}/${id}/show`}
    >
      <TextField source="id" label="ID" />
      <TextField source="name" />
      <FunctionField<RoleDto>
        sortable={false}
        source="isPredefined"
        render={(record?: RoleDto) =>
          record?.isPredefined ? (
            <div>{translate('const.yes')}</div>
          ) : (
            <div>{translate('const.no')}</div>
          )
        }
      />
    </Datagrid>
  )
}

export const RoleList = ({ ...props }: ListProps) => (
  <List
    {...props}
    perPage={20}
    pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
    exporter={false}
    filters={<RolesFilters />}
  >
    <RolesGrid />
  </List>
)
