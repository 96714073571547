import React from 'react'
import { DateTimeInput, SimpleForm, TextInput, useTranslate } from 'react-admin'
import {
  useMediaQuery,
  useTheme,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { SendMessageToManyPatientsRequest } from '../../../core/current-user/current-user.provider'
import { ResourceName } from '../../../core/ResourceName'
import { DoctorDto } from '../../../core/dto/user/doctor.dto'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'
import { RadiotherapyMachineDto } from '../../../core/dto/radiotherapy-machine.dto'

const useStyles = makeStyles((theme) => ({
  filters: {
    '& > div > div': {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
    padding: theme.spacing(1),
    backgroundColor: 'rgba(0,0,0,0.1)',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    borderRadius: theme.spacing(1),
  },
  toolbar: {
    gap: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0,
  },
  messageInput: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
}))

export function validate(values: SendMessageToManyPatientsRequest) {
  const errors: { [n: string]: string } = {}
  if (!values.message?.length) {
    errors.message = 'ra.validation.required'
  }
  return errors
}

const FormFields = ({ ...props }) => {
  const classes = useStyles()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const translate = useTranslate()

  return (
    <>
      <div className={classes.filters}>
        <Typography>
          {translate(
            'resources.patients.dialogs.sendBulkAdministrativeMessage.fields.filtersLabel',
          )}
        </Typography>
        <div>
          <FilteredReferenceInput
            {...props}
            label="resources.patients.dialogs.sendBulkAdministrativeMessage.fields.mainDoctorIdFilter"
            source="mainDoctorIdFilter"
            defaultValue={null}
            reference={ResourceName.DOCTORS}
            sort={{ field: 'lastName', order: 'ASC' }}
            perPage={smallScreen ? 5 : 15}
            allowEmpty
            filterSource="fullNameSearch"
            filter={{ doctorsOnly: true }}
            emptyText="-"
            selectWithPaginationInputProps={{
              optionText: (record: DoctorDto) =>
                `${record.firstName} ${record.lastName}`,
              showFilter: true,
            }}
          />
          <FilteredReferenceInput
            {...props}
            label="resources.patients.dialogs.sendBulkAdministrativeMessage.fields.temporaryDoctorIdFilter"
            source="temporaryDoctorIdFilter"
            defaultValue={null}
            reference={ResourceName.DOCTORS}
            sort={{ field: 'lastName', order: 'ASC' }}
            perPage={smallScreen ? 5 : 15}
            allowEmpty
            filterSource="fullNameSearch"
            filter={{ doctorsOnly: true }}
            emptyText="-"
            selectWithPaginationInputProps={{
              optionText: (record: DoctorDto) =>
                `${record.firstName} ${record.lastName}`,
              showFilter: true,
            }}
          />
          <FilteredReferenceInput
            {...props}
            label="resources.patients.dialogs.sendBulkAdministrativeMessage.fields.otherDoctorIdFilter"
            source="otherDoctorIdFilter"
            defaultValue={null}
            reference={ResourceName.DOCTORS}
            sort={{ field: 'lastName', order: 'ASC' }}
            perPage={smallScreen ? 5 : 15}
            allowEmpty
            filterSource="fullNameSearch"
            filter={{ doctorsOnly: true }}
            emptyText="-"
            selectWithPaginationInputProps={{
              optionText: (record: DoctorDto) =>
                `${record.firstName} ${record.lastName}`,
              showFilter: true,
            }}
          />
          <FilteredReferenceInput
            {...props}
            label="resources.patients.dialogs.sendBulkAdministrativeMessage.fields.radiotherapyMachineIdFilter"
            source="radiotherapyMachineIdFilter"
            defaultValue={null}
            reference={ResourceName.RADIOTHERAPY_MACHINES}
            sort={{ field: 'name', order: 'ASC' }}
            perPage={smallScreen ? 5 : 15}
            allowEmpty
            filterSource="name"
            emptyText="-"
            selectWithPaginationInputProps={{
              optionText: (record: RadiotherapyMachineDto) => `${record.name}`,
              showFilter: true,
            }}
          />
          <DateTimeInput
            {...props}
            label="resources.patients.dialogs.sendBulkAdministrativeMessage.fields.radiotherapyDateFromFilter"
            source="radiotherapyDateFromFilter"
            parse={(value: string) => (value === '' ? null : value)}
          />
          <DateTimeInput
            {...props}
            label="resources.patients.dialogs.sendBulkAdministrativeMessage.fields.radiotherapyDateToFilter"
            source="radiotherapyDateToFilter"
            parse={(value: string) => (value === '' ? null : value)}
          />
        </div>
      </div>
      <TextInput
        {...props}
        className={classes.messageInput}
        source="message"
        label="resources.patients.dialogs.sendBulkAdministrativeMessage.fields.message"
        multiline
        fullWidth
        rows={8}
        required
      />
    </>
  )
}

export const SendBulkAdministrativeMessageForm = ({ ...props }) => (
  <SimpleForm {...props} validate={validate}>
    <FormFields />
  </SimpleForm>
)
