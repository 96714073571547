import { DataProvider } from 'react-admin'
import { SchedulerBaseDto } from '../../dto/scheduler/scheduler-base.dto'

const fakeUsers: SchedulerBaseDto[] = []

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({ data: fakeUsers, total: fakeUsers.length }),
} as DataProvider

export default provider
