const API_V1 = `${process.env.REACT_APP_API_BASE_URL}/api/v1`
export const LOGIN_URL = `${API_V1}/Authorization/Login`
export const LOGOUT_URL = `${API_V1}/Authorization/Logout`
export const ADMINISTRATOR_URL = `${API_V1}/Administrator`
export const JOBS_URL = `${API_V1}/Jobs`
export const CURRENT_USER_URL = `${API_V1}/User`
export const CURRENT_DOCTOR_URL = `${API_V1}/Doctor`
export const CURRENT_WEB_USER_URL = `${API_V1}/WebUser`
export const SCHEDULERS_URL = `${API_V1}/Schedulers`
export const RADIOTHERAPY_AILMENTS_URL = `${API_V1}/RadiotherapyAilments`
export const RADIOTHERAPY_AILMENT_SURVEYS_URL = `${API_V1}/RadiotherapyAilmentSurveys`
export const CHEMOTHERAPY_AILMENTS_URL = `${API_V1}/ChemotherapyAilments`
export const CHEMOTHERAPY_AILMENT_SURVEYS_URL = `${API_V1}/ChemotherapyAilmentSurveys`
export const RADIOTHERAPY_MACHINES_URL = `${API_V1}/RadiotherapyMachines`
export const VACATION_CALENDARS_URL = `${API_V1}/VacationCalendar`
