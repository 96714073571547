import { useMediaQuery, useTheme } from '@material-ui/core'
import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  Create,
  CreateProps,
  DateTimeInput,
  FormDataConsumer,
  GET_ONE,
  ListButton,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  ToolbarProps,
  sanitizeFetchType,
  useQueryWithStore,
  useTranslate,
} from 'react-admin'
import { useForm } from 'react-final-form'
import { useLocation } from 'react-router'
import { SchedulerTypes } from '../../core/enum/SchedulerTypes'
import { ResourceName } from '../../core/ResourceName'
import { CreateSchedulerRequest } from '../../core/schedulers/schedulers.provider'
import FilteredReferenceInput from '../common/FilteredReferenceInput'
import { PatientDto } from '../../core/dto/user/patient.dto'
import { UserBaseDto } from '../../core/dto/user/user-base.dto'
import { UserTypes } from '../../core/enum/UserTypes'

export const validateSchedulerCreate = (values: CreateSchedulerRequest) => {
  const errors: { [n: string]: string } = {}
  if (!(values as any).schedulerType) {
    errors.schedulerType = 'ra.validation.required'
  }
  if (!values.patientId) {
    errors.patientId = 'ra.validation.required'
  }
  if (!values.date) {
    errors.date = 'ra.validation.required'
  }
  return errors
}

const CreateTitle = () => {
  const translate = useTranslate()
  return <span>{translate('resources.schedulers.titles.create')}</span>
}

const CreateToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ListButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

export const SchedulerCreate = (props: CreateProps) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { state } = useLocation()

  const GetPatientSchedulerTypeOptions = () => {
    const { getFieldState, change } = useForm()
    const { data: patient } = useQueryWithStore({
      type: sanitizeFetchType(GET_ONE),
      resource: ResourceName.PATIENTS,
      payload: { id: getFieldState('patientId')?.value },
    })
    const availableChoices = Object.entries(SchedulerTypes)
      .filter((s) => s[1] !== SchedulerTypes.RADIOTHERAPY)
      .filter(
        (s) =>
          s[1] !== SchedulerTypes.CHEMOTHERAPY ||
          (patient as PatientDto)?.isRadiochemotherapyModuleAvailable ||
          (patient as PatientDto)?.isChemotherapyModuleAvailable,
      )
      .filter(
        (s) =>
          s[1] !== SchedulerTypes.BRACHYTHERAPY ||
          (patient as PatientDto)?.isBrachytherapyModuleAvailable,
      )
    const actualChoice = getFieldState('schedulerType')?.value
    if (!availableChoices.some((c) => c[1] === actualChoice))
      change('schedulerType', undefined)
    return availableChoices.map(([value]) => ({
      id: value,
      name: `resources.enums.schedulerTypes.${value}`,
    }))
  }

  const { data: user } = useQueryWithStore({
    type: sanitizeFetchType(GET_ONE),
    resource: ResourceName.CURRENT_USER,
    payload: {},
  })

  return (
    <Create {...props} title={<CreateTitle />}>
      <SimpleForm
        redirect="show"
        validate={validateSchedulerCreate}
        toolbar={<CreateToolbar />}
        initialValues={state}
      >
        <FilteredReferenceInput
          source="patientId"
          defaultValue={null}
          reference={ResourceName.PATIENTS}
          sort={{ field: 'lastName', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          allowEmpty
          filterSource="fullNameSearch"
          filter={{
            patientsOnly: true,
            active: true,
            filterRelatedPatients:
              (user as UserBaseDto)?.userType === UserTypes.DOCTOR,
            relatedPatientDoctorId: (user as UserBaseDto)?.id,
          }}
          emptyText="-"
          selectWithPaginationInputProps={{
            optionText: (record: PatientDto) =>
              `${record.firstName} ${record.lastName} (${record.pesel})`,
            showFilter: true,
            disabled: (state as any)?.patientId,
          }}
        />
        <FormDataConsumer>
          {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
          {({ formData, ...rest }) => (
            <SelectInput
              source="schedulerType"
              isRequired
              choices={GetPatientSchedulerTypeOptions()}
              {...rest}
            />
          )}
        </FormDataConsumer>
        <DateTimeInput
          source="date"
          parse={(value: string) => (value === '' ? null : value)}
          isRequired
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            (formData?.schedulerType === SchedulerTypes.EXAMINATION ||
              formData?.schedulerType === SchedulerTypes.RECOMMENDATION) && (
              <TextInput source="description" multiline {...rest} />
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  )
}
