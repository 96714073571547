import { Authority } from './Authority'

const mapping: Record<Authority, string[]> = {
  // User management
  [Authority.VIEW_USER_MANAGEMENT]: ['ViewItems_User_Management'],

  // Users
  [Authority.VIEW_USERS]: [
    'ViewItems_Users_Show',
    'ViewItems_Users_Edit',
    'ViewItems_Users_Add',
  ],
  [Authority.EDIT_USERS]: ['ViewItems_Users_Edit'],
  [Authority.CREATE_USERS]: ['ViewItems_Users_Add'],

  // Roles
  [Authority.VIEW_ROLES]: [
    'ViewItems_Roles_Show',
    'ViewItems_Roles_Edit',
    'ViewItems_Roles_Add',
  ],
  [Authority.EDIT_ROLES]: ['ViewItems_Roles_Edit'],
  [Authority.CREATE_ROLES]: ['ViewItems_Roles_Add'],

  // Doctors
  [Authority.VIEW_DOCTORS]: [
    'ViewItems_Doctors_Show',
    'ViewItems_Doctors_Edit',
    'ViewItems_Doctors_Add',
  ],
  [Authority.EDIT_DOCTORS]: ['ViewItems_Doctors_Edit'],
  [Authority.CREATE_DOCTORS]: ['ViewItems_Doctors_Add'],

  // Patients
  [Authority.VIEW_PATIENTS]: [
    'ViewItems_Patients_Show',
    'ViewItems_Patients_Edit',
    'ViewItems_Patients_Add',
  ],
  [Authority.EDIT_PATIENTS]: ['ViewItems_Patients_Edit'],
  [Authority.CREATE_PATIENTS]: ['ViewItems_Patients_Add'],

  // Radiotherapy ailments
  [Authority.VIEW_RADIOTHERAPY_AILMENTS]: [
    'ViewItems_RadiotherapyAilments_Show',
    'ViewItems_RadiotherapyAilments_Edit',
    'ViewItems_RadiotherapyAilments_Add',
  ],
  [Authority.EDIT_RADIOTHERAPY_AILMENTS]: [
    'ViewItems_RadiotherapyAilments_Edit',
  ],
  [Authority.CREATE_RADIOTHERAPY_AILMENTS]: [
    'ViewItems_RadiotherapyAilments_Add',
  ],

  // Radiotherapy ailment surveys
  [Authority.VIEW_RADIOTHERAPY_AILMENT_SURVEYS]: [
    'ViewItems_RadiotherapyAilmentSurveys_Show',
    'ViewItems_RadiotherapyAilmentSurveys_Edit',
    'ViewItems_RadiotherapyAilmentSurveys_Add',
  ],
  [Authority.EDIT_RADIOTHERAPY_AILMENT_SURVEYS]: [
    'ViewItems_RadiotherapyAilmentSurveys_Edit',
  ],
  [Authority.CREATE_RADIOTHERAPY_AILMENT_SURVEYS]: [
    'ViewItems_RadiotherapyAilmentSurveys_Add',
  ],

  // Chemotherapy ailments
  [Authority.VIEW_CHEMOTHERAPY_AILMENTS]: [
    'ViewItems_ChemotherapyAilments_Show',
    'ViewItems_ChemotherapyAilments_Edit',
    'ViewItems_ChemotherapyAilments_Add',
  ],
  [Authority.EDIT_CHEMOTHERAPY_AILMENTS]: [
    'ViewItems_ChemotherapyAilments_Edit',
  ],
  [Authority.CREATE_CHEMOTHERAPY_AILMENTS]: [
    'ViewItems_ChemotherapyAilments_Add',
  ],

  // Chemotherapy ailment surveys
  [Authority.VIEW_CHEMOTHERAPY_AILMENT_SURVEYS]: [
    'ViewItems_ChemotherapyAilmentSurveys_Show',
    'ViewItems_ChemotherapyAilmentSurveys_Edit',
    'ViewItems_ChemotherapyAilmentSurveys_Add',
  ],
  [Authority.EDIT_CHEMOTHERAPY_AILMENT_SURVEYS]: [
    'ViewItems_ChemotherapyAilmentSurveys_Edit',
  ],
  [Authority.CREATE_CHEMOTHERAPY_AILMENT_SURVEYS]: [
    'ViewItems_ChemotherapyAilmentSurveys_Add',
  ],

  // Schedulers
  [Authority.VIEW_SCHEDULERS]: [
    'ViewItems_Schedulers_Show',
    'ViewItems_PatientSchedulers_Show',
    'ViewItems_Schedulers_Edit',
    'ViewItems_Schedulers_Add',
  ],
  [Authority.EDIT_SCHEDULERS]: ['ViewItems_Schedulers_Edit'],
  [Authority.CREATE_SCHEDULERS]: ['ViewItems_Schedulers_Add'],

  // Schedulers
  [Authority.VIEW_RADIOTHERAPY_MACHINES]: [
    'ViewItems_RadiotherapyMachines_Show',
  ],

  // Vacation calendars
  [Authority.VIEW_VACATION_CALENDARS]: [
    'ViewItems_VacationCalendar_Show',
    'ViewItems_VacationCalendar_Edit',
    'ViewItems_VacationCalendar_Add',
  ],
  [Authority.EDIT_VACATION_CALENDARS]: ['ViewItems_VacationCalendar_Edit'],
  [Authority.CREATE_VACATION_CALENDARS]: ['ViewItems_VacationCalendar_Add'],

  // Custom user type roles
  [Authority.WEB_USER]: ['WEB_USER'],
}

export const mapAuthorityToRequiredPermissions = (authority: Authority) =>
  mapping[authority]
