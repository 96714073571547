import {
  Card,
  CardContent,
  CardHeader,
  makeStyles,
  Slider,
} from '@material-ui/core'
import { lightBlue } from '@material-ui/core/colors'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { ListProps, useListContext, useTranslate } from 'react-admin'
import { ChemotherapyAilmentBaseDTO } from '../../../core/dto/chemotherapy-ailment/chemotherapy-ailment-base.dto'
import { DiarrheaAilmentDTO } from '../../../core/dto/chemotherapy-ailment/diarrhea-ailment.dto'
import List from '../../common/customized-ra-components/List'
import { HairLossAilmentDTO } from '../../../core/dto/chemotherapy-ailment/hair-loss-ailment.dto'
import { HandsTinglingAilmentDTO } from '../../../core/dto/chemotherapy-ailment/hands-tingling-ailment.dto'
import { NauseaAilmentDTO } from '../../../core/dto/chemotherapy-ailment/nausea-ailment.dto'
import { PainAndBurnAilmentDTO } from '../../../core/dto/chemotherapy-ailment/pain-and-burn-ailment.dto'
import { WeaknessAilmentDTO } from '../../../core/dto/chemotherapy-ailment/weakness-ailment.dto'

const useStyles = makeStyles((theme) => ({
  cardStyle: {
    margin: '0.5em',
    verticalAlign: 'top',
    '& .MuiCardContent-root': {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  slider: {
    '& .MuiSlider-thumb': {
      height: 24,
      width: 24,
      marginTop: -9,
      marginLeft: -11,
    },
    '& .MuiSlider-track': {
      height: 6,
      backgroundColor: theme.palette.error.main,
    },
    '& .MuiSlider-rail': {
      height: 6,
      opacity: 0.5,
      backgroundColor: lightBlue.A700,
    },
    '& .MuiSlider-mark': {
      height: 20,
      width: 2,
      backgroundColor: theme.palette.grey[500],
      marginTop: -7,
    },
  },
}))

const availableSliderMarks = [
  {
    value: 1,
    label: <Typography>1</Typography>,
  },
  {
    value: 2,
    label: <Typography>2</Typography>,
  },
  {
    value: 3,
    label: <Typography>3</Typography>,
  },
  {
    value: 4,
    label: <Typography>4</Typography>,
  },
]

const convertLevelEnumToNumber = (level: string) =>
  Number(level?.replace('_', ''))

const ChemotherapyAilmentsGrid = () => {
  const { ids, data } = useListContext()
  const translate = useTranslate()
  const classes = useStyles()

  return (
    <div style={{ margin: '1em' }}>
      {ids.map((id) => (
        <Card key={id} className={classes.cardStyle}>
          <CardHeader
            titleTypographyProps={{ variant: 'h6' }}
            style={{
              textDecoration:
                data[id]?.occurs === true ? 'underline' : 'line-through',
              color: data[id]?.occurs === true ? undefined : 'gray',
            }}
            title={translate(
              `resources.enums.chemotherapyAilmentTypes.${data[id]?.ailmentType}`,
            )}
          />
          {(data[id] as ChemotherapyAilmentBaseDTO).occurs
            ? {
                DIARRHEA: (
                  <CardContent>
                    <Slider
                      min={1}
                      max={4}
                      defaultValue={convertLevelEnumToNumber(data[id]?.level)}
                      marks={availableSliderMarks}
                      className={classes.slider}
                      disabled
                    />
                    <Card>
                      <CardContent>
                        <Typography>
                          {{
                            _1: translate(
                              'resources.chemotherapy-ailments.fields.diarrhea._1',
                            ),
                            _2: translate(
                              'resources.chemotherapy-ailments.fields.diarrhea._2',
                            ),
                            _3: translate(
                              'resources.chemotherapy-ailments.fields.diarrhea._3',
                            ),
                            _4: translate(
                              'resources.chemotherapy-ailments.fields.diarrhea._4',
                            ),
                          }[(data[id] as DiarrheaAilmentDTO)?.level] ||
                            undefined}
                        </Typography>
                      </CardContent>
                    </Card>
                  </CardContent>
                ),
                HAIR_LOSS: (
                  <CardContent>
                    <Slider
                      min={1}
                      max={3}
                      defaultValue={convertLevelEnumToNumber(data[id]?.level)}
                      marks={availableSliderMarks}
                      className={classes.slider}
                      disabled
                    />
                    <Card>
                      <CardContent>
                        <Typography>
                          {{
                            _1: translate(
                              'resources.chemotherapy-ailments.fields.hairLoss._1',
                            ),
                            _2: translate(
                              'resources.chemotherapy-ailments.fields.hairLoss._2',
                            ),
                            _3: translate(
                              'resources.chemotherapy-ailments.fields.hairLoss._3',
                            ),
                          }[(data[id] as HairLossAilmentDTO)?.level] ||
                            undefined}
                        </Typography>
                      </CardContent>
                    </Card>
                  </CardContent>
                ),
                HANDS_TINGLING: (
                  <CardContent>
                    <Slider
                      min={1}
                      max={3}
                      defaultValue={convertLevelEnumToNumber(data[id]?.level)}
                      marks={availableSliderMarks}
                      className={classes.slider}
                      disabled
                    />
                    <Card>
                      <CardContent>
                        <Typography>
                          {{
                            _1: translate(
                              'resources.chemotherapy-ailments.fields.handsTingling._1',
                            ),
                            _2: translate(
                              'resources.chemotherapy-ailments.fields.handsTingling._2',
                            ),
                            _3: translate(
                              'resources.chemotherapy-ailments.fields.handsTingling._3',
                            ),
                          }[(data[id] as HandsTinglingAilmentDTO)?.level] ||
                            undefined}
                        </Typography>
                      </CardContent>
                    </Card>
                  </CardContent>
                ),
                NAUSEA: (
                  <CardContent>
                    <Slider
                      min={1}
                      max={3}
                      defaultValue={convertLevelEnumToNumber(data[id]?.level)}
                      marks={availableSliderMarks}
                      className={classes.slider}
                      disabled
                    />
                    <Card>
                      <CardContent>
                        <Typography>
                          {{
                            _1: translate(
                              'resources.chemotherapy-ailments.fields.nausea._1',
                            ),
                            _2: translate(
                              'resources.chemotherapy-ailments.fields.nausea._2',
                            ),
                            _3: translate(
                              'resources.chemotherapy-ailments.fields.nausea._3',
                            ),
                          }[(data[id] as NauseaAilmentDTO)?.level] || undefined}
                        </Typography>
                      </CardContent>
                    </Card>
                  </CardContent>
                ),
                PAIN_AND_BURN: (
                  <CardContent>
                    <Slider
                      min={1}
                      max={4}
                      defaultValue={convertLevelEnumToNumber(data[id]?.level)}
                      marks={availableSliderMarks}
                      className={classes.slider}
                      disabled
                    />
                    <Card>
                      <CardContent>
                        <Typography>
                          {{
                            _1: translate(
                              'resources.chemotherapy-ailments.fields.painAndBurn._1',
                            ),
                            _2: translate(
                              'resources.chemotherapy-ailments.fields.painAndBurn._2',
                            ),
                            _3: translate(
                              'resources.chemotherapy-ailments.fields.painAndBurn._3',
                            ),
                            _4: translate(
                              'resources.chemotherapy-ailments.fields.painAndBurn._4',
                            ),
                          }[(data[id] as PainAndBurnAilmentDTO)?.level] ||
                            undefined}
                        </Typography>
                      </CardContent>
                    </Card>
                  </CardContent>
                ),
                WEAKNESS: (
                  <CardContent>
                    <Slider
                      min={1}
                      max={3}
                      defaultValue={convertLevelEnumToNumber(data[id]?.level)}
                      marks={availableSliderMarks}
                      className={classes.slider}
                      disabled
                    />
                    <Card>
                      <CardContent>
                        <Typography>
                          {{
                            _1: translate(
                              'resources.chemotherapy-ailments.fields.weakness._1',
                            ),
                            _2: translate(
                              'resources.chemotherapy-ailments.fields.weakness._2',
                            ),
                            _3: translate(
                              'resources.chemotherapy-ailments.fields.weakness._3',
                            ),
                          }[(data[id] as WeaknessAilmentDTO)?.level] ||
                            undefined}
                        </Typography>
                      </CardContent>
                    </Card>
                  </CardContent>
                ),
              }[(data[id] as ChemotherapyAilmentBaseDTO).ailmentType] ||
              undefined
            : undefined}
          {/* <CardActions style={{ textAlign: 'right' }}>
            <EditButton resource="posts" basePath={basePath} record={data[id]} />
          </CardActions> */}
        </Card>
      ))}
    </div>
  )
}

export const ChemotherapyAilmentList = ({ ...props }: ListProps) => (
  <List
    {...props}
    perPage={20}
    pagination={false}
    exporter={false}
    sort={{ field: 'occurs', order: 'DESC' }}
    actions={false}
  >
    <ChemotherapyAilmentsGrid />
  </List>
)
