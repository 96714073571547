import React from 'react'
import {
  Datagrid,
  Filter,
  FunctionField,
  ListProps,
  Pagination,
  TextField,
  TextInput,
  useListContext,
  useTranslate,
} from 'react-admin'
import { UserBaseDto } from '../../../core/dto/user/user-base.dto'
import { ResourceName } from '../../../core/ResourceName'
import List from '../../common/customized-ra-components/List'
import FilterProps from '../../common/FilterProps'

const UsersFilters = (props: FilterProps) => (
  <Filter {...props}>
    <TextInput source="userName" alwaysOn />
  </Filter>
)

const UsersGrid = () => {
  const { loading } = useListContext()
  const translate = useTranslate()

  return (
    <Datagrid
      size="small"
      loaded={!loading}
      rowClick={(id) => `${ResourceName.USERS}/${id}/show`}
    >
      <TextField source="id" label="ID" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="userName" />
      <FunctionField<UserBaseDto>
        source="userType"
        sortable={false}
        render={(record?: UserBaseDto) => (
          <div>
            {translate(`resources.enums.userTypes.${record?.userType}`)}
          </div>
        )}
      />
    </Datagrid>
  )
}

export const UserList = ({ ...props }: ListProps) => (
  <List
    {...props}
    perPage={20}
    pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
    exporter={false}
    filters={<UsersFilters />}
  >
    <UsersGrid />
  </List>
)
