var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { useContext } from 'react';
import { FilterContext, FilterForm as RaFilterForm, useListContext, } from 'react-admin';
import get from 'lodash/get';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core';
/**
 * A customized version of react-admin FilterForm component which takes less
 * space when there are no filters.
 */
export var FilterForm = function (props) {
    var _a;
    var filtersProps = props.filters, initialValues = props.initialValues;
    var _b = useListContext(props).displayedFilters, displayedFilters = _b === void 0 ? {} : _b;
    var classes = useStyles();
    var filters = useContext(FilterContext) || filtersProps;
    var shownFilters = filters.filter(function (filterElement) {
        return filterElement.props.alwaysOn ||
            displayedFilters[filterElement.props.source] ||
            typeof get(initialValues, filterElement.props.source) !==
                'undefined';
    });
    return (React.createElement(RaFilterForm, __assign({}, props, { className: classnames((_a = {},
            _a[classes.emptyForm] = shownFilters.length === 0,
            _a)) })));
};
var useStyles = makeStyles(function (theme) { return ({
    emptyForm: {
        minHeight: theme.spacing(2),
    },
}); });
