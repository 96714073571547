import { createMuiTheme, defaultTheme, RaThemeOptions } from 'react-admin'
import { blue, blueGrey, lightBlue } from '@material-ui/core/colors'
import { Palette, PaletteOptions } from '@material-ui/core/styles/createPalette'
import { Theme } from '@material-ui/core'

interface ExtendedThemeOptions extends RaThemeOptions {
  palette?: ExtendedPaleteOptions
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExtendedPaleteOptions extends PaletteOptions {}

export interface ExtendedTheme extends Theme {
  palette: ExtendedPalete
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ExtendedPalete extends Palette {}

const extendedThemeOptions = {
  ...defaultTheme,
  // typography: {
  //   // Use the system font instead of the default Roboto font.
  //   fontFamily: [
  //     '-apple-system',
  //     'BlinkMacSystemFont',
  //     '"Segoe UI"',
  //     'Arial',
  //     'sans-serif',
  //   ].join(','),
  //   body1: {
  //     fontWeight: 300,
  //   },
  //   body2: {
  //     fontWeight: 300,
  //   },
  //   h5: {
  //     fontWeight: 200,
  //   },
  // },
  overrides: {
    RaFilter: {
      form: {
        margin: 0,
      },
    },
    MuiTableCell: {
      body: {
        color: 'inherit',
      },
    },
    MuiIconButton: {
      root: {
        color: 'inherit',
      },
    },
  },
  sidebar: {
    width: 240,
    closedWidth: 55,
  },
} as ExtendedThemeOptions

const extendedLightThemeOptions: ExtendedThemeOptions = {
  ...extendedThemeOptions,
  palette: {
    ...extendedThemeOptions.palette,
    type: 'light',
    primary: {
      light: blue[400],
      main: blue[600],
      dark: blue[800],
      contrastText: '#fff',
    },
    secondary: {
      light: blueGrey[50],
      main: blueGrey[100],
      dark: blueGrey[400],
      contrastText: '#111',
    },
  },
}

const extendedDarkThemeOptions: ExtendedThemeOptions = {
  ...extendedThemeOptions,
  palette: {
    ...extendedThemeOptions.palette,
    type: 'dark',
    primary: {
      light: lightBlue[200],
      main: lightBlue[400],
      dark: lightBlue[700],
      contrastText: '#fff',
    },
    secondary: {
      light: blueGrey[500],
      main: blueGrey[600],
      dark: blueGrey[800],
      contrastText: '#fff',
    },
  },
}

const lightTheme = createMuiTheme(extendedLightThemeOptions)
const darkTheme = createMuiTheme(extendedDarkThemeOptions)

export const extendedLightTheme: ExtendedTheme = {
  ...lightTheme,
  palette: {
    ...lightTheme.palette,
  },
} as ExtendedTheme

export const extendedDarkTheme: ExtendedTheme = {
  ...darkTheme,
  palette: {
    ...darkTheme.palette,
  },
} as ExtendedTheme
