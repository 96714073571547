import { ResourceName } from '../ResourceName'

const mapping: Record<string, string> = {
  [ResourceName.ROLES]: 'filterRoleId',
  [ResourceName.USERS]: 'filterUserId',
  [ResourceName.PATIENTS]: 'filterDoctorPatientId',
}

const filterMapper = (key: ResourceName) => mapping[key]

export default filterMapper
