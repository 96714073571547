import { Typography, useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'
import {
  BooleanInput,
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  GET_ONE,
  ListProps,
  Pagination,
  ReferenceField,
  TextField,
  sanitizeFetchType,
  useListContext,
  useQueryWithStore,
  useTranslate,
} from 'react-admin'
import { Authority } from '../../core/auth/Authority'
import { SchedulerBaseDto } from '../../core/dto/scheduler/scheduler-base.dto'
import { ResourceName } from '../../core/ResourceName'
import List from '../common/customized-ra-components/List'
import { DateFilterInput } from '../common/DateFilterInput'
import FilteredReferenceInput from '../common/FilteredReferenceInput'
import FilterProps from '../common/FilterProps'
import { useHasAuthority } from '../hooks/useHasAuthority'
import { UserBaseDto } from '../../core/dto/user/user-base.dto'
import { UserTypes } from '../../core/enum/UserTypes'
import { PatientDto } from '../../core/dto/user/patient.dto'

const SchedulersFilters = (props: FilterProps) => {
  const hasAuthority = useHasAuthority()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { data: user } = useQueryWithStore({
    type: sanitizeFetchType(GET_ONE),
    resource: ResourceName.CURRENT_USER,
    payload: {},
  })

  return (
    <Filter {...props}>
      {localStorage.getItem('user_type') === UserTypes.DOCTOR &&
        hasAuthority(Authority.WEB_USER) && (
          <BooleanInput source="filterRelatedPatientsScheduler" alwaysOn />
        )}
      <DateFilterInput source="date" key="date" />
      {hasAuthority(Authority.VIEW_PATIENTS) && (
        <FilteredReferenceInput
          label="resources.schedulers.fields.patientId"
          source="patientId"
          reference={ResourceName.PATIENTS}
          sort={{ field: 'lastName', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="fullNameSearch"
          filter={{
            patientsOnly: true,
            active: true,
            filterRelatedPatients:
              (user as UserBaseDto)?.userType === UserTypes.DOCTOR,
            relatedPatientDoctorId: (user as UserBaseDto)?.id,
          }}
          selectWithPaginationInputProps={{
            optionText: (record: PatientDto) =>
              `${record.firstName} ${record.lastName} (${record.pesel})`,
            showFilter: true,
          }}
        />
      )}
    </Filter>
  )
}

const SchedulersGrid = ({ ...props }) => {
  const { loading } = useListContext()
  const translate = useTranslate()

  return (
    <Datagrid
      {...props}
      size="small"
      loaded={!loading}
      rowClick="show"
      hasBulkActions={false}
    >
      <TextField source="id" label="ID" />
      <ReferenceField
        source="patientId"
        reference={ResourceName.PATIENTS}
        link={false}
        emptyText="-"
        sortable={false}
      >
        <FunctionField<PatientDto>
          source="patientId"
          render={(record?: PatientDto) => (
            <Typography variant="body2">
              {record?.firstName} {record?.lastName}
            </Typography>
          )}
        />
      </ReferenceField>
      <DateField
        source="date"
        showTime
        options={{
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        }}
        emptyText="-"
      />
      <FunctionField<SchedulerBaseDto>
        source="schedulerType"
        sortable={false}
        render={(record?: SchedulerBaseDto) => (
          <div>
            {translate(
              `resources.enums.schedulerTypes.${record?.schedulerType}`,
            )}
          </div>
        )}
      />
    </Datagrid>
  )
}

export const SchedulerList = ({ ...props }: ListProps) => {
  const { data: user } = useQueryWithStore({
    type: sanitizeFetchType(GET_ONE),
    resource: ResourceName.CURRENT_USER,
    payload: {},
  })

  return (
    <List
      filters={<SchedulersFilters />}
      filterDefaultValues={{
        filterRelatedPatientsScheduler:
          (user as UserBaseDto)?.userType === UserTypes.DOCTOR,
        relatedPatientDoctorId: (user as UserBaseDto)?.id,
      }}
      {...props}
      perPage={20}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
      exporter={false}
      sort={{ field: 'date', order: 'DESC' }}
    >
      <SchedulersGrid />
    </List>
  )
}
