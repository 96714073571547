import { useMediaQuery, useTheme } from '@material-ui/core'
import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  BooleanInput,
  Create,
  CreateProps,
  FormDataConsumer,
  Identifier,
  ListButton,
  NumberInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  ToolbarProps,
  useTranslate,
} from 'react-admin'
import { validatePolish } from 'validate-polish'
import { useForm } from 'react-final-form'
import { useHistory, useLocation } from 'react-router'
import { ResourceName } from '../../../core/ResourceName'
import { CreateWebUserRequest } from '../../../core/administration/users/users.provider'
import { PatientTags } from '../../../core/enum/PatientTags'
import { UserTypes } from '../../../core/enum/UserTypes'
import Base64ImageInput from '../../common/Base64ImageInput'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'
import CustomButton from '../../common/customized-mui-components/Button'
import { DoctorDto } from '../../../core/dto/user/doctor.dto'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { Authority } from '../../../core/auth/Authority'

export const validateUserCreate = (values: CreateWebUserRequest) => {
  const errors: { [n: string]: string } = {}
  if (!(values as any).userType) {
    errors.userType = 'ra.validation.required'
  }
  if (!values.firstName) {
    errors.firstName = 'ra.validation.required'
  }
  if (!values.lastName) {
    errors.lastName = 'ra.validation.required'
  }
  if (!values.userName) {
    errors.userName = 'ra.validation.required'
  }
  if (!values.password) {
    errors.password = 'ra.validation.required'
  }
  if (
    (values as any).userType === UserTypes.PATIENT &&
    !(values as any).pesel
  ) {
    errors.pesel = 'ra.validation.required'
  }
  if ((values as any).userType === UserTypes.PATIENT && !(values as any).tag) {
    errors.tag = 'ra.validation.required'
  }
  if (
    (values as any).userType === UserTypes.PATIENT &&
    !(values as any).patientRadiotherapySurveyInterval
  ) {
    errors.patientRadiotherapySurveyInterval = 'ra.validation.required'
  }
  if (
    (values as any).userType === UserTypes.PATIENT &&
    ((values as any).patientRadiotherapySurveyInterval < 7 ||
      (values as any).patientRadiotherapySurveyInterval > 90)
  ) {
    errors.patientRadiotherapySurveyInterval = 'ra.validation.out_of_range'
  }
  if (
    (values as any).userType === UserTypes.PATIENT &&
    !(values as any).patientChemotherapySurveyInterval
  ) {
    errors.patientChemotherapySurveyInterval = 'ra.validation.required'
  }
  if (
    (values as any).userType === UserTypes.PATIENT &&
    ((values as any).patientChemotherapySurveyInterval < 7 ||
      (values as any).patientChemotherapySurveyInterval > 90)
  ) {
    errors.patientChemotherapySurveyInterval = 'ra.validation.out_of_range'
  }
  if (
    (values as any).userType === UserTypes.PATIENT &&
    (!(values as any).pesel || !validatePolish.pesel((values as any).pesel))
  ) {
    errors.pesel = 'ra.validation.incorrect_pesel'
  }
  return errors
}

const CreateTitle = () => {
  const translate = useTranslate()
  return <span>{translate('resources.users.titles.create')}</span>
}

const CreateToolbar = (props: ToolbarProps) => {
  const history = useHistory()

  return (
    <Toolbar {...props}>
      <SaveButton
        redirect={(basePath: string, id: Identifier, data: any) => {
          if (data?.userType === UserTypes.PATIENT) {
            return '../print-patient-data'
          }
          return `${id}/show`
        }}
        transform={(data) => {
          if (data?.userType === UserTypes.PATIENT)
            history.push({ pathname: `../print-patient-data`, state: data })
          return data
        }}
      />
      <ListButton icon={<Cancel />} label="const.cancel" />
    </Toolbar>
  )
}

const defaultValues = {
  requirePasswordUpdate: false,
  active: true,
  patientRadiotherapySurveyInterval: 7,
  patientChemotherapySurveyInterval: 7,
}

export const UserCreate = (props: CreateProps) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { search, state } = useLocation()
  const userType = new URLSearchParams(search).get('userType')
  const hasAuthority = useHasAuthority()

  const GeneratePatientPasswordButton = ({ formData }: { formData: any }) => {
    const { change } = useForm()

    return (
      <CustomButton
        label="resources.patients.actions.generate-default-password"
        useSmallVersionBreakpoint={false}
        disabled={!formData.firstName || !formData.lastName || !formData.pesel}
        onClick={() => {
          change(
            'password',
            `${formData.firstName.slice(0, 2)}${formData.lastName.slice(
              0,
              2,
            )}${formData.pesel.slice(0, 2)}`.toLocaleLowerCase(),
          )
        }}
      />
    )
  }

  const GeneratePatientLoginButton = ({ formData }: { formData: any }) => {
    const { change } = useForm()

    return (
      <CustomButton
        label="resources.patients.actions.generate-default-login"
        useSmallVersionBreakpoint={false}
        disabled={!formData.pesel}
        onClick={() => {
          change('userName', formData.pesel.toLocaleLowerCase())
        }}
      />
    )
  }

  return (
    <Create {...props} title={<CreateTitle />}>
      <SimpleForm
        redirect="show"
        validate={validateUserCreate}
        toolbar={<CreateToolbar />}
        initialValues={{ ...defaultValues, ...(state as any), userType }}
      >
        {!userType && (
          <SelectInput
            source="userType"
            isRequired
            choices={Object.entries(UserTypes).map(([value]) => ({
              id: value,
              name: `resources.enums.userTypes.${value}`,
            }))}
            disabled={userType != null}
          />
        )}
        {/* <FormDataConsumer>
          {({ formData, ...rest }) =>
            (formData as any).userType === UserTypes.PATIENT && (
              <BooleanInput source="active" {...rest} />
            )
          }
        </FormDataConsumer> */}
        <TextInput source="firstName" isRequired />
        <TextInput source="lastName" isRequired />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            (formData as any).userType === UserTypes.PATIENT && (
              <TextInput source="pesel" isRequired {...rest} />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <div>
              <TextInput
                source="userName"
                isRequired
                label="resources.users.fields.userName"
                {...rest}
              />
              {(formData as any).userType === UserTypes.PATIENT && (
                <GeneratePatientLoginButton formData={formData} />
              )}
            </div>
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <div>
              <TextInput
                source="password"
                isRequired
                label="resources.users.fields.password"
                {...rest}
              />
              {(formData as any).userType === UserTypes.PATIENT && (
                <GeneratePatientPasswordButton formData={formData} />
              )}
            </div>
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            ((formData as any).userType === UserTypes.DOCTOR ||
              (formData as any).userType === UserTypes.WEB_USER) && (
              <TextInput
                source="email"
                label="resources.users.fields.email"
                {...rest}
              />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            (formData as any).userType === UserTypes.DOCTOR && (
              <Base64ImageInput
                source="base64Photo"
                outputSource="blobPhoto"
                label="resources.users.fields.base64Photo"
                {...rest}
              />
            )
          }
        </FormDataConsumer>
        {/* <FormDataConsumer>
          {({ formData, ...rest }) =>
            ((formData as any).userType === UserTypes.DOCTOR ||
              (formData as any).userType === UserTypes.WEB_USER) && (
              <BooleanInput source="requirePasswordUpdate" {...rest} />
            )
          }
        </FormDataConsumer> */}
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            (formData as any).userType === UserTypes.PATIENT && (
              <FilteredReferenceInput
                {...rest}
                source="mainDoctorId"
                defaultValue={null}
                reference={ResourceName.DOCTORS}
                sort={{ field: 'lastName', order: 'ASC' }}
                perPage={smallScreen ? 5 : 15}
                allowEmpty
                filterSource="fullNameSearch"
                filter={{ doctorsOnly: true }}
                emptyText="-"
                selectWithPaginationInputProps={{
                  optionText: (record: DoctorDto) =>
                    `${record.firstName} ${record.lastName}`,
                  showFilter: true,
                  disabled:
                    (state as any)?.mainDoctorId &&
                    !hasAuthority(Authority.WEB_USER),
                }}
              />
            )
          }
        </FormDataConsumer>
        {/* <FormDataConsumer>
          {({ formData, ...rest }) =>
            (formData as any).userType === UserTypes.PATIENT && (
              <FilteredReferenceInput
                {...rest}
                source="temporaryDoctorId"
                defaultValue={null}
                reference={ResourceName.DOCTORS}
                sort={{ field: 'lastName', order: 'ASC' }}
                perPage={smallScreen ? 5 : 15}
                allowEmpty
                filterSource="fullNameSearch"
                filter={{ doctorsOnly: true }}
                emptyText="-"
                selectWithPaginationInputProps={{
                  optionText: (record: DoctorDto) =>
                    `${record.firstName} ${record.lastName}`,
                  showFilter: true,
                }}
              />
            )
          }
        </FormDataConsumer> */}
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            (formData as any).userType === UserTypes.PATIENT && (
              <BooleanInput source="isRadiotherapyModuleAvailable" {...rest} />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            (formData as any).userType === UserTypes.PATIENT && (
              <BooleanInput
                source="isRadiochemotherapyModuleAvailable"
                {...rest}
              />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            (formData as any).userType === UserTypes.PATIENT && (
              <BooleanInput source="isChemotherapyModuleAvailable" {...rest} />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            (formData as any).userType === UserTypes.PATIENT && (
              <BooleanInput source="isBrachytherapyModuleAvailable" {...rest} />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            (formData as any).userType === UserTypes.PATIENT && (
              <SelectInput
                source="tag"
                isRequired
                choices={Object.entries(PatientTags).map(([value]) => ({
                  id: value,
                  name: `resources.enums.patientTags.${value}`,
                }))}
                {...rest}
              />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            (formData as any).userType === UserTypes.PATIENT && (
              <NumberInput
                source="patientRadiotherapySurveyInterval"
                isRequired
                min={7}
                max={90}
                {...rest}
              />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            (formData as any).userType === UserTypes.PATIENT && (
              <NumberInput
                source="patientChemotherapySurveyInterval"
                isRequired
                min={7}
                max={90}
                {...rest}
              />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            (formData as any).userType === UserTypes.PATIENT && (
              <TextInput source="diagnosis" multiline {...rest} />
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  )
}
