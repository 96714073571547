import {
  DataProvider,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
} from 'react-admin'
import { RADIOTHERAPY_MACHINES_URL } from '../api-urls'
import { get, getWithPagination } from '../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../common/get-by-conditions.utils'
import { RadOnkAPIGetListParams } from '../common/rad-onk-api-get-list.params'
import filterMapper from './radiotherapy-machines-filter.mapper'
import sortMapper from './radiotherapy-machines-sort.mapper'
import { RadiotherapyMachineDto } from '../dto/radiotherapy-machine.dto'

const provider = {
  getList: async (
    resource: string,
    { filter: { ...filter }, sort, pagination }: RadOnkAPIGetListParams,
  ): Promise<GetListResult<RadiotherapyMachineDto>> => {
    const filterParams = `s=>${filterParamsComposer('s', filter, filterMapper)}`
    const pathParams = queryParamsComposer(sort, pagination, sortMapper)
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<RadiotherapyMachineDto[]>(
      `${RADIOTHERAPY_MACHINES_URL}/GetByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<RadiotherapyMachineDto>> => {
    const data = await get<RadiotherapyMachineDto>(
      `${RADIOTHERAPY_MACHINES_URL}`,
      `/${id}`,
    )
    return Promise.resolve({
      data,
    })
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<RadiotherapyMachineDto>> => {
    const data = await get<RadiotherapyMachineDto[]>(
      `${RADIOTHERAPY_MACHINES_URL}/GetByConditions`,
      `/s=>new int[] {${ids.toString()}}.Contains(s.Id)`,
    )
    return Promise.resolve({
      data,
    })
  },
} as DataProvider

export default provider
