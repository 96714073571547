import { Box, Divider } from '@material-ui/core'
import React, { ReactElement } from 'react'
import {
  BooleanInput,
  Datagrid,
  EditButton,
  Filter,
  FunctionField,
  ImageField,
  List,
  NumberField,
  Pagination,
  Record,
  ReferenceField,
  ResourceContextProvider,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  TitleProps,
  useShowContext,
  useTranslate,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { DoctorDto } from '../../../core/dto/user/doctor.dto'
import { PatientDto } from '../../../core/dto/user/patient.dto'
import { UserBaseDto } from '../../../core/dto/user/user-base.dto'
import { UserTypes } from '../../../core/enum/UserTypes'
import { ResourceName } from '../../../core/ResourceName'
import { DeleteButtonWithConfirm } from '../../common/DeleteButtonWithConfirm'
import RelationTab from '../../common/RelationTab'
import relationTabFilter from '../../common/RelationTabFilter'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import ChangePasswordButton from './UserShow/UserChangePasswordButton'

interface UserTitleProps extends TitleProps {
  record?: UserBaseDto
}

const ShowTitle = (data: UserTitleProps) => {
  const { record: user } = data
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.users.titles.show')}: {user?.id}. {user?.userName}
    </span>
  )
}

const UserRoles = ({ ...props }) => {
  const { record: user } = props
  const hasAuthority = useHasAuthority()

  const UserRolesMapper = (records: Record[], source: string) => ({
    [source]: records.map((record: Record) => record.id),
  })

  const rolesFilters = (...filterProps: any[]) =>
    relationTabFilter({
      ...filterProps,
      children: [<TextInput source="name" alwaysOn />],
      resource: ResourceName.USERS,
    })

  return (
    <RelationTab<UserBaseDto>
      resource={ResourceName.ROLES}
      source="rolesIds"
      mode={hasAuthority(Authority.EDIT_USERS) ? 'edit' : 'show'}
      attachMethod="attachRoles"
      detachMethod="detachRoles"
      attachRequestPayload={(r, ids) => ({
        userId: r.id,
        rolesIds: ids,
      })}
      detachRequestPayload={(r, ids) => ({
        userId: r.id,
        rolesIds: ids,
      })}
      refetchListAfterChange={(filters) => filters?.filterUserId === true}
      relationsRequestMethod="getRoles"
      relationsRequestPayload={(r) => ({ userId: r.id })}
      relationsMapper={UserRolesMapper}
      filters={rolesFilters() as ReactElement[]}
      filterDefaultValues={{
        userId: user?.id,
        filterUserId: true,
      }}
      sort={{
        field: 'id',
        order: 'DESC',
      }}
    >
      <TextField source="id" label="ID" />\
      <ResourceContextProvider value={ResourceName.ROLES}>
        <ReferenceField
          source="id"
          label="resources.roles.fields.name"
          reference={ResourceName.ROLES}
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
      </ResourceContextProvider>
    </RelationTab>
  )
}

// DOCTOR
const DoctorPatients = ({ ...props }) => {
  const { record: user } = props
  const hasAuthority = useHasAuthority()

  const PatientsFilters = () => (
    <Filter>
      <TextInput source="userName" alwaysOn />
      <BooleanInput
        label="common.related-records.actions.attached-only"
        source="filterPatientDoctorId"
        alwaysOn
      />
    </Filter>
  )

  return (
    <RelationTab<UserBaseDto>
      resource={ResourceName.USERS}
      source="patientIds"
      mode={hasAuthority(Authority.EDIT_USERS) ? 'edit' : 'show'}
      attachMethod="attachPatientsToDoctor"
      detachMethod="detachPatientsFromDoctor"
      attachRequestPayload={(u, ids) => ({
        doctorId: u.id,
        patientIds: ids,
      })}
      detachRequestPayload={(r, ids) => ({
        doctorId: r.id,
        patientIds: ids,
      })}
      refetchListAfterChange={(filters) =>
        filters?.filterPatientDoctorId === true
      }
      filters={<PatientsFilters />}
      filterDefaultValues={{
        patientDoctorId: user?.id,
        filterPatientDoctorId: true,
        patientsOnly: true,
        active: true,
      }}
      sort={{
        field: 'id',
        order: 'DESC',
      }}
    >
      <TextField source="id" label="ID" />
      <ReferenceField
        source="id"
        sortBy="userName"
        label="resources.users.fields.userName"
        reference={ResourceName.USERS}
        link="show"
      >
        <TextField source="userName" />
      </ReferenceField>
    </RelationTab>
  )
}

const MainPatients = () => {
  const { record: user, basePath } = useShowContext()

  const MainPatientsFilters = () => (
    <Filter>
      <TextInput source="userName" alwaysOn />
    </Filter>
  )

  return (
    <ResourceContextProvider value={ResourceName.USERS}>
      <List
        basePath={basePath}
        exporter={false}
        title=" "
        filters={<MainPatientsFilters />}
        filterDefaultValues={{
          patientsOnly: true,
          active: true,
          filterPatientMainDoctorId: true,
          patientMainDoctorId: user?.id,
        }}
        sort={{
          field: 'id',
          order: 'DESC',
        }}
        pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100]} />}
        perPage={20}
        actions={<></>}
      >
        <>
          <Datagrid size="small" style={{ tableLayout: 'fixed' }}>
            <TextField source="id" label="ID" />
            <ReferenceField
              source="id"
              sortBy="userName"
              label="resources.users.fields.userName"
              reference={ResourceName.USERS}
              link="show"
            >
              <TextField source="userName" />
            </ReferenceField>
          </Datagrid>
        </>
      </List>
    </ResourceContextProvider>
  )
}

const TemporaryPatients = () => {
  const { record: user, basePath } = useShowContext()

  const TemporaryPatientsFilters = () => (
    <Filter>
      <TextInput source="userName" alwaysOn />
    </Filter>
  )

  return (
    <ResourceContextProvider value={ResourceName.USERS}>
      <List
        basePath={basePath}
        exporter={false}
        title=" "
        filters={<TemporaryPatientsFilters />}
        filterDefaultValues={{
          patientsOnly: true,
          active: true,
          filterPatientTemporaryDoctorId: true,
          patientTemporaryDoctorId: user?.id,
        }}
        sort={{
          field: 'id',
          order: 'DESC',
        }}
        pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100]} />}
        perPage={20}
        actions={<></>}
      >
        <>
          <Datagrid size="small" style={{ tableLayout: 'fixed' }}>
            <TextField source="id" label="ID" />
            <ReferenceField
              source="id"
              sortBy="userName"
              label="resources.users.fields.userName"
              reference={ResourceName.USERS}
              link="show"
            >
              <TextField source="userName" />
            </ReferenceField>
          </Datagrid>
        </>
      </List>
    </ResourceContextProvider>
  )
}

// PATIENT
const PatientDocotrs = ({ ...props }) => {
  const { record: user } = props
  const hasAuthority = useHasAuthority()

  const PatientsFilters = () => (
    <Filter>
      <TextInput source="userName" alwaysOn />
      <BooleanInput
        label="common.related-records.actions.attached-only"
        source="filterDoctorPatientId"
        alwaysOn
      />
    </Filter>
  )

  return (
    <RelationTab<UserBaseDto>
      resource={ResourceName.USERS}
      source="doctorIds"
      mode={hasAuthority(Authority.EDIT_USERS) ? 'edit' : 'show'}
      attachMethod="attachDoctorsToPatient"
      detachMethod="detachDoctorsFromPatient"
      attachRequestPayload={(u, ids) => ({
        patientId: u.id,
        doctorIds: ids,
      })}
      detachRequestPayload={(r, ids) => ({
        patientId: r.id,
        doctorIds: ids,
      })}
      refetchListAfterChange={(filters) =>
        filters?.filterDoctorPatientId === true
      }
      filters={<PatientsFilters />}
      filterDefaultValues={{
        doctorPatientId: user?.id,
        filterDoctorPatientId: true,
        doctorsOnly: true,
      }}
      sort={{
        field: 'id',
        order: 'DESC',
      }}
    >
      <TextField source="id" label="ID" />
      <ReferenceField
        source="id"
        sortBy="userName"
        label="resources.users.fields.userName"
        reference={ResourceName.USERS}
        link="show"
      >
        <TextField source="userName" />
      </ReferenceField>
    </RelationTab>
  )
}

const GeneralActions = ({ ...props }) => {
  const { record: user } = useShowContext<UserBaseDto>()
  const hasAuthority = useHasAuthority()

  return (
    <>
      <Divider />
      <Box p={1} display="block" textAlign="right">
        <ChangePasswordButton />
        <EditButton
          basePath={`/${ResourceName.USERS}`}
          record={user}
          variant="contained"
          style={{ margin: '2px' }}
          disabled={!hasAuthority(Authority.EDIT_USERS)}
        />
        <DeleteButtonWithConfirm {...props} />
      </Box>
    </>
  )
}

const UserTabs = ({ ...props }) => {
  const translate = useTranslate()
  const hasAuthority = useHasAuthority()
  const { record: userData } = useShowContext<UserBaseDto>()

  return (
    <TabbedShowLayout {...props}>
      <Tab label="resources.users.tabs.general">
        <TextField source="id" label="ID" />
        {userData?.userType === UserTypes.PATIENT && (
          <FunctionField<PatientDto>
            source="active"
            render={(record?: PatientDto) => (
              <div>
                {record?.active === true
                  ? translate('const.yes')
                  : translate('const.no')}
              </div>
            )}
          />
        )}
        <TextField source="firstName" />
        <TextField source="lastName" />
        {userData?.userType === UserTypes.PATIENT && (
          <TextField source="pesel" emptyText="-" />
        )}
        <TextField source="userName" />
        {(userData?.userType === UserTypes.DOCTOR ||
          userData?.userType === UserTypes.WEB_USER) && (
          <TextField source="email" emptyText="-" />
        )}
        {userData?.userType === UserTypes.DOCTOR &&
          (userData as DoctorDto)?.base64Photo && (
            <ImageField source="base64Photo" />
          )}
        <FunctionField<UserBaseDto>
          source="userType"
          sortable={false}
          render={(record?: UserBaseDto) => (
            <div>
              {translate(`resources.enums.userTypes.${record?.userType}`)}
            </div>
          )}
        />
        {userData?.userType === UserTypes.PATIENT && (
          <ReferenceField
            source="mainDoctorId"
            reference={ResourceName.USERS}
            link="show"
            emptyText="-"
          >
            <TextField source="userName" />
          </ReferenceField>
        )}
        {userData?.userType === UserTypes.PATIENT && (
          <ReferenceField
            source="temporaryDoctorId"
            reference={ResourceName.USERS}
            link="show"
            emptyText="-"
          >
            <TextField source="userName" />
          </ReferenceField>
        )}
        {userData?.userType === UserTypes.PATIENT && (
          <FunctionField<PatientDto>
            source="isRadiotherapyModuleAvailable"
            render={(record?: PatientDto) => (
              <div>
                {record?.isRadiotherapyModuleAvailable
                  ? translate('const.yes')
                  : translate('const.no')}
              </div>
            )}
          />
        )}
        {userData?.userType === UserTypes.PATIENT && (
          <FunctionField<PatientDto>
            source="isRadiochemotherapyModuleAvailable"
            render={(record?: PatientDto) => (
              <div>
                {record?.isRadiochemotherapyModuleAvailable
                  ? translate('const.yes')
                  : translate('const.no')}
              </div>
            )}
          />
        )}
        {userData?.userType === UserTypes.PATIENT && (
          <FunctionField<PatientDto>
            source="isChemotherapyModuleAvailable"
            render={(record?: PatientDto) => (
              <div>
                {record?.isChemotherapyModuleAvailable
                  ? translate('const.yes')
                  : translate('const.no')}
              </div>
            )}
          />
        )}
        {userData?.userType === UserTypes.PATIENT && (
          <FunctionField<PatientDto>
            source="isBrachytherapyModuleAvailable"
            render={(record?: PatientDto) => (
              <div>
                {record?.isBrachytherapyModuleAvailable
                  ? translate('const.yes')
                  : translate('const.no')}
              </div>
            )}
          />
        )}
        {userData?.userType === UserTypes.PATIENT && (
          <FunctionField<PatientDto>
            source="tag"
            sortable={false}
            render={(record?: PatientDto) => (
              <div>
                {record?.tag
                  ? translate(`resources.enums.patientTags.${record?.tag}`)
                  : '-'}
              </div>
            )}
          />
        )}
        <NumberField
          source="patientRadiotherapySurveyInterval"
          sortable={false}
        />
        <NumberField
          source="patientChemotherapySurveyInterval"
          sortable={false}
        />
        {userData?.userType === UserTypes.PATIENT && (
          <TextField source="diagnosis" emptyText="-" />
        )}
        {/* {(userData?.userType === UserTypes.DOCTOR ||
          userData?.userType === UserTypes.WEB_USER) && (
          <FunctionField<UserBaseDto>
            source="requirePasswordUpdate"
            render={(record?: UserBaseDto) => (
              <div>
                {record?.requirePasswordUpdate
                  ? translate('const.yes')
                  : translate('const.no')}
              </div>
            )}
          />
        )} */}
        <GeneralActions />
      </Tab>
      {hasAuthority(Authority.VIEW_ROLES) && (
        <Tab label="resources.users.tabs.roles" path="roles">
          <UserRoles />
        </Tab>
      )}
      {hasAuthority(Authority.VIEW_USERS) &&
        userData?.userType === UserTypes.DOCTOR && (
          <Tab label="resources.users.tabs.patients" path="patients">
            <DoctorPatients />
          </Tab>
        )}
      {hasAuthority(Authority.VIEW_USERS) &&
        userData?.userType === UserTypes.DOCTOR && (
          <Tab label="resources.users.tabs.mainPatients" path="mainPatients">
            <MainPatients />
          </Tab>
        )}
      {hasAuthority(Authority.VIEW_USERS) &&
        userData?.userType === UserTypes.DOCTOR && (
          <Tab
            label="resources.users.tabs.temporaryPatients"
            path="temporaryPatients"
          >
            <TemporaryPatients />
          </Tab>
        )}
      {hasAuthority(Authority.VIEW_USERS) &&
        userData?.userType === UserTypes.PATIENT && (
          <Tab label="resources.users.tabs.doctors" path="doctors">
            <PatientDocotrs />
          </Tab>
        )}
    </TabbedShowLayout>
  )
}

export const UserShow = (props: ShowProps) => (
  <Show {...props} actions={false} title={<ShowTitle />}>
    <UserTabs />
  </Show>
)
