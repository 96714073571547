import { AuthProvider, GetOneParams } from 'react-admin'
import { LOGIN_URL, LOGOUT_URL, CURRENT_USER_URL } from '../api-urls'
import { authPost, get } from '../common/fetch.utils'
import currentUserProvider from '../current-user/current-user.provider'
import { RoleDto } from '../dto/role/role.dto'
import i18nProvider from '../i18n.provider'
import { ResourceName } from '../ResourceName'
import { LoginRequest } from './auth.request'
import { UserBaseDto } from '../dto/user/user-base.dto'

const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    await authPost<LoginRequest, undefined>(LOGIN_URL, {
      login: username,
      password,
    })
    const roleDtos = await get<RoleDto[]>(`${CURRENT_USER_URL}/GetRoles`)
    const roles = roleDtos.map((r) => r.name)
    localStorage.setItem('username', username)
    localStorage.setItem('roles', roles.join())
    // User preferences
    const { data: settings } = await currentUserProvider.getSettings(
      ResourceName.CURRENT_USER,
    )
    localStorage.setItem(
      'preferences',
      JSON.stringify({
        theme: settings?.frontDisplayMode,
        language: settings?.language,
      }),
    )
    // User type
    const { data: userData } = await currentUserProvider.getOne(
      ResourceName.CURRENT_USER,
      {} as GetOneParams,
    )
    localStorage.setItem('user_type', (userData as UserBaseDto).userType)
  },
  logout: async () => {
    localStorage.removeItem('username')
    localStorage.removeItem('user_type')
    localStorage.removeItem('roles')
    try {
      await authPost<undefined, undefined>(LOGOUT_URL, undefined)
    } catch {
      // eslint-disable-next-line no-console
      console.warn('Could not logout')
    }
    return Promise.resolve()
  },
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('username')
      localStorage.removeItem('user_type')
      localStorage.removeItem('roles')
      return Promise.reject()
    }
    return Promise.resolve()
  },
  checkAuth: async () => {
    let preferences
    try {
      preferences = JSON.parse(localStorage?.preferences)
      if (preferences === null || preferences === undefined) preferences = {}
    } catch {
      preferences = {}
    }
    if (preferences?.language) i18nProvider.changeLocale(preferences.language)
    return localStorage.getItem('username')
      ? Promise.resolve()
      : Promise.reject()
  },
  getPermissions: (): Promise<Set<string>> => {
    const roles = localStorage.getItem('roles') as string
    return Promise.resolve(new Set(roles?.split(',') || []))
  },
  getIdentity: () =>
    Promise.resolve({
      id: localStorage.getItem('username') as string,
    }),
}

export default authProvider
