var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Joyride from 'react-joyride';
import { ErrorBoundary } from 'react-error-boundary';
import useTour from './useTour';
import Tooltip from './ui/Tooltip';
import { useTranslate } from 'react-admin';
var ErrorFallback = function (_a) {
    var error = _a.error, componentStack = _a.componentStack, resetErrorBoundary = _a.resetErrorBoundary;
    process.env.NODE_ENV !== 'production' &&
        console.warn('Joyride error', {
            error: error,
            componentStack: componentStack,
            resetErrorBoundary: resetErrorBoundary,
        });
    return null;
};
/**
 * Component that handles the UI part of the tour.
 *
 * Uses react-joyride
 *
 * @param {TourType} tours List of supported tours
 * @param {any} joyrideProps Props passed down to react-joyride
 */
var Tour = function (_a) {
    var tours = _a.tours, joyrideProps = _a.joyrideProps;
    var _b = useTour(), _c = _b[0], running = _c.running, stepIndex = _c.stepIndex, activeTour = _c.activeTour, stop = _b[1].stop;
    var translate = useTranslate();
    var defaultJoyRideProps = {
        locale: {
            back: translate('ra.action.back', { _: 'Back' }),
            close: translate('ra.action.close', { _: 'Close' }),
            last: translate('ra-tour.action.last', { _: 'Last' }),
            next: translate('ra-tour.action.next', { _: 'Next' }),
            open: translate('ra-tour.action.open', { _: 'Open the dialog' }),
            skip: translate('ra-tour.action.skip', { _: 'Skip' }),
        },
    };
    if (!activeTour) {
        return null;
    }
    var steps = tours[activeTour].steps;
    if (!running) {
        return null;
    }
    var stepJoyrideProps = steps[stepIndex]
        ? steps[stepIndex].joyrideProps
        : {};
    return (React.createElement(ErrorBoundary, { FallbackComponent: ErrorFallback, onError: function (error, componentStack) {
            process.env.NODE_ENV !== 'production' &&
                console.warn('Joyride error', { error: error, componentStack: componentStack });
        }, onReset: function () {
            stop();
        } },
        React.createElement(Joyride, __assign({ showProgress: true, showSkipButton: true, disableCloseOnEsc: true, disableOverlayClose: true, spotlightClicks: true, tooltipComponent: Tooltip }, defaultJoyRideProps, joyrideProps, stepJoyrideProps, { steps: steps.map(function (step) { return (__assign(__assign({}, step), { content: typeof step.content === 'string'
                    ? translate(step.content, {
                        _: step.content,
                    })
                    : step.content })); }), run: true, stepIndex: stepIndex, continuous: true }))));
};
export default Tour;
