import { Box, Divider } from '@material-ui/core'
import React from 'react'
import {
  EditButton,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  TitleProps,
  useShowContext,
  useTranslate,
} from 'react-admin'
import { Authority } from '../../core/auth/Authority'
import { UserBaseDto } from '../../core/dto/user/user-base.dto'
import { ResourceName } from '../../core/ResourceName'
import { DeleteButtonWithConfirm } from '../common/DeleteButtonWithConfirm'
import { useHasAuthority } from '../hooks/useHasAuthority'
import ChangePasswordButton from '../administration/users/UserShow/UserChangePasswordButton'

interface WebUserTitleProps extends TitleProps {
  record?: UserBaseDto
}

const ShowTitle = (data: WebUserTitleProps) => {
  const { record: user } = data
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.web-users.titles.show')}: {user?.firstName}{' '}
      {user?.lastName}
    </span>
  )
}

const GeneralActions = ({ ...props }) => {
  const { record: user } = useShowContext<UserBaseDto>()
  const hasAuthority = useHasAuthority()

  return (
    <>
      <Divider />
      <Box p={1} display="block" textAlign="right">
        <ChangePasswordButton />
        <EditButton
          basePath={`/${ResourceName.WEB_USERS}`}
          record={user}
          variant="contained"
          style={{ margin: '2px' }}
          disabled={!hasAuthority(Authority.EDIT_USERS)}
        />
        <DeleteButtonWithConfirm {...props} />
      </Box>
    </>
  )
}

const WebUserTabs = ({ ...props }) => (
  <TabbedShowLayout {...props}>
    <Tab label="resources.web-users.tabs.general">
      <TextField source="id" label="ID" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="userName" />
      <TextField source="email" emptyText="-" />

      {/* <FunctionField<UserBaseDto>
          source="requirePasswordUpdate"
          render={(record?: UserBaseDto) => (
            <div>
              {record?.requirePasswordUpdate
                ? translate('const.yes')
                : translate('const.no')}
            </div>
          )}
        /> */}
      <GeneralActions />
    </Tab>
  </TabbedShowLayout>
)

export const WebUserShow = (props: ShowProps) => (
  <Show {...props} actions={false} title={<ShowTitle />}>
    <WebUserTabs />
  </Show>
)
