import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  DateTimeInput,
  Edit,
  EditProps,
  SaveButton,
  ShowButton,
  SimpleForm,
  TextField,
  Toolbar,
  ToolbarProps,
  useTranslate,
} from 'react-admin'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { VacationCalendarDto } from '../../core/dto/vacation-calendar.dto'
import { ResourceName } from '../../core/ResourceName'
import { DoctorDto } from '../../core/dto/user/doctor.dto'
import FilteredReferenceInput from '../common/FilteredReferenceInput'
import { formatDate } from '../../core/common/date-time'
import { DeleteButtonWithConfirm } from '../common/DeleteButtonWithConfirm'

export const validateSchedulerEdit = (values: VacationCalendarDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.mainDoctorId) {
    errors.mainDoctorId = 'ra.validation.required'
  }
  if (!values.temporaryDoctorId) {
    errors.temporaryDoctorId = 'ra.validation.required'
  }
  if (!values.dateFrom) {
    errors.dateFrom = 'ra.validation.required'
  }
  if (!values.dateTo) {
    errors.dateTo = 'ra.validation.required'
  }
  return errors
}

const EditTitle = () => {
  const translate = useTranslate()
  return <span>{translate('resources.vacation-calendars.titles.edit')}</span>
}

const EditToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ShowButton icon={<Cancel />} label="const.cancel" />
    <div style={{ marginRight: 'auto' }} />
    <DeleteButtonWithConfirm />
  </Toolbar>
)

const VacationCalendarEditForm = ({ ...props }) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <SimpleForm
      {...props}
      redirect="list"
      validate={validateSchedulerEdit}
      toolbar={<EditToolbar />}
    >
      <TextField source="id" label="ID" />
      <FilteredReferenceInput
        source="mainDoctorId"
        defaultValue={null}
        reference={ResourceName.DOCTORS}
        sort={{ field: 'lastName', order: 'ASC' }}
        perPage={smallScreen ? 5 : 15}
        allowEmpty
        filterSource="fullNameSearch"
        filter={{
          doctorsOnly: true,
        }}
        emptyText="-"
        isRequired
        selectWithPaginationInputProps={{
          optionText: (record: DoctorDto) =>
            `${record.firstName} ${record.lastName}`,
          showFilter: true,
        }}
      />
      <FilteredReferenceInput
        source="temporaryDoctorId"
        defaultValue={null}
        reference={ResourceName.DOCTORS}
        sort={{ field: 'lastName', order: 'ASC' }}
        perPage={smallScreen ? 5 : 15}
        allowEmpty
        filterSource="fullNameSearch"
        filter={{
          doctorsOnly: true,
        }}
        emptyText="-"
        isRequired
        selectWithPaginationInputProps={{
          optionText: (record: DoctorDto) =>
            `${record.firstName} ${record.lastName}`,
          showFilter: true,
        }}
      />
      <DateTimeInput
        source="dateFrom"
        type="date"
        format={formatDate}
        parse={(value: string) => (value === '' ? null : value)}
        isRequired
      />
      <DateTimeInput
        source="dateTo"
        type="date"
        format={formatDate}
        parse={(value: string) => (value === '' ? null : value)}
        isRequired
      />
    </SimpleForm>
  )
}

export const VacationCalendarEdit = (props: EditProps) => (
  <Edit {...props} title={<EditTitle />} actions={false}>
    <VacationCalendarEditForm />
  </Edit>
)
