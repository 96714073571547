import { Box, Divider } from '@material-ui/core'
import React, { ReactElement } from 'react'
import {
  Datagrid,
  EditButton,
  FunctionField,
  Identifier,
  ReferenceField,
  ResourceContextProvider,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  TitleProps,
  useShowContext,
  useTranslate,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { RoleDto } from '../../../core/dto/role/role.dto'
import { ResourceName } from '../../../core/ResourceName'
import List from '../../common/customized-ra-components/List'
import RelationTab from '../../common/RelationTab'
import relationTabFilter from '../../common/RelationTabFilter'
import { useHasAuthority } from '../../hooks/useHasAuthority'

interface RoleTitleProps extends TitleProps {
  record?: RoleDto
}

const ShowTitle = (data: RoleTitleProps) => {
  const { record: company } = data
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.roles.titles.show')}: {company?.id}. {company?.name}
    </span>
  )
}

const RoleUsers = () => {
  const { record: role, basePath } = useShowContext()

  return (
    <>
      <ResourceContextProvider value={ResourceName.USERS}>
        <List
          title=" "
          actions={false}
          exporter={false}
          basePath={basePath}
          filter={{ roleId: role?.id }}
          filters={undefined}
          perPage={10}
          pagination={false}
          bulkActionButtons={false}
        >
          <Datagrid
            rowClick={(id: Identifier) => `/${ResourceName.USERS}/${id}/show`}
          >
            <TextField source="id" label="ID" />
            <TextField source="userName" />
          </Datagrid>
        </List>
      </ResourceContextProvider>
    </>
  )
}

const GeneralActions = () => {
  const { record: role } = useShowContext<RoleDto>()
  const hasAuthority = useHasAuthority()

  return (
    <>
      <Divider />
      <Box p={1} display="block" textAlign="right">
        <EditButton
          basePath={`/${ResourceName.ROLES}`}
          record={role}
          variant="contained"
          style={{ margin: '2px' }}
          disabled={!hasAuthority(Authority.EDIT_ROLES) || role?.isPredefined}
        />
      </Box>
    </>
  )
}

const RoleTabs = ({ ...props }) => {
  const hasAuthority = useHasAuthority()
  const { record: role } = useShowContext<RoleDto>()
  const translate = useTranslate()

  const permissionsFilter = (...filterProps: any[]) =>
    relationTabFilter({
      ...filterProps,
      children: [<TextInput source="name" alwaysOn />],
      resource: ResourceName.ROLES,
    })

  const parentsFilter = (...filterProps: any[]) =>
    relationTabFilter({
      ...filterProps,
      children: [<TextInput source="name" alwaysOn />],
      source: 'filterChildRoleId',
      resource: ResourceName.ROLES,
    })

  const childrenFilter = (...filterProps: any[]) =>
    relationTabFilter({
      ...filterProps,
      children: [<TextInput source="name" alwaysOn />],
      source: 'filterParentRoleId',
      resource: ResourceName.ROLES,
    })

  return (
    <TabbedShowLayout {...props}>
      <Tab label="resources.roles.tabs.general">
        <TextField source="id" label="ID" />
        <TextField source="name" />
        <FunctionField<RoleDto>
          source="isPredefined"
          render={(record?: RoleDto) =>
            record?.isPredefined ? (
              <div>{translate('const.yes')}</div>
            ) : (
              <div>{translate('const.no')}</div>
            )
          }
        />
        <GeneralActions />
      </Tab>
      {hasAuthority(Authority.VIEW_ROLES) && (
        <Tab path="permissions" label="resources.roles.tabs.permissions">
          <>
            <RelationTab<RoleDto>
              resource={ResourceName.PERMISSIONS}
              source="permissionsIds"
              mode={
                hasAuthority(Authority.EDIT_ROLES) && !role?.isPredefined
                  ? 'edit'
                  : 'show'
              }
              attachMethod="attachPermissions"
              detachMethod="detachPermissions"
              attachRequestPayload={(r, ids) => ({
                roleId: r.id,
                permissionsIds: ids,
              })}
              detachRequestPayload={(r, ids) => ({
                roleId: r.id,
                permissionsIds: ids,
              })}
              refetchListAfterChange={(filters) =>
                filters?.filterRoleId === true
              }
              filters={permissionsFilter() as ReactElement[]}
              filterDefaultValues={{
                roleId: role?.id,
                filterRoleId: true,
              }}
              sort={{
                field: 'id',
                order: 'DESC',
              }}
            >
              <TextField label="ID" source="id" />
              <TextField source="name" />
            </RelationTab>
          </>
        </Tab>
      )}
      {hasAuthority(Authority.VIEW_USERS) && (
        <Tab label="resources.roles.tabs.users" path="users">
          <RoleUsers />
        </Tab>
      )}
      {hasAuthority(Authority.VIEW_ROLES) && (
        <Tab path="parents" label="resources.roles.tabs.parents">
          <>
            <RelationTab<RoleDto>
              resource={ResourceName.ROLES}
              source="parentRolesIds"
              mode={
                hasAuthority(Authority.EDIT_ROLES) && !role?.isPredefined
                  ? 'edit'
                  : 'show'
              }
              attachMethod="attachParentRolesToRole"
              detachMethod="detachParentRolesFromRole"
              attachRequestPayload={(r, ids) => ({
                roleId: r.id,
                parentRolesIds: ids,
              })}
              detachRequestPayload={(r, ids) => ({
                roleId: r.id,
                parentRolesIds: ids,
              })}
              refetchListAfterChange={(filters) =>
                filters?.filterChildRoleId === true
              }
              filters={parentsFilter() as ReactElement[]}
              filterDefaultValues={{
                childRoleId: role?.id,
                filterChildRoleId: true,
              }}
              sort={{
                field: 'id',
                order: 'DESC',
              }}
            >
              <TextField label="ID" source="id" />
              <ReferenceField
                source="id"
                label="resources.roles.fields.name"
                reference={ResourceName.ROLES}
                link="show"
              >
                <TextField source="name" />
              </ReferenceField>
              )
            </RelationTab>
          </>
        </Tab>
      )}
      {hasAuthority(Authority.VIEW_ROLES) && (
        <Tab path="children" label="resources.roles.tabs.children">
          <>
            <RelationTab<RoleDto>
              resource={ResourceName.ROLES}
              source="childRolesIds"
              mode={
                hasAuthority(Authority.EDIT_ROLES) && !role?.isPredefined
                  ? 'edit'
                  : 'show'
              }
              attachMethod="attachChildRolesToRole"
              detachMethod="detachChildRolesFromRole"
              attachRequestPayload={(r, ids) => ({
                roleId: r.id,
                childRolesIds: ids,
              })}
              detachRequestPayload={(r, ids) => ({
                roleId: r.id,
                childRolesIds: ids,
              })}
              refetchListAfterChange={(filters) =>
                filters?.filterParentRoleId === true
              }
              filters={childrenFilter() as ReactElement[]}
              filterDefaultValues={{
                parentRoleId: role?.id,
                filterParentRoleId: true,
              }}
              sort={{
                field: 'id',
                order: 'DESC',
              }}
            >
              <TextField label="ID" source="id" />
              <ReferenceField
                source="id"
                label="resources.roles.fields.name"
                reference={ResourceName.ROLES}
                link="show"
              >
                <TextField source="name" />
              </ReferenceField>
            </RelationTab>
          </>
        </Tab>
      )}
    </TabbedShowLayout>
  )
}

export const RoleShow = (props: ShowProps) => (
  <Show {...props} actions={false} title={<ShowTitle />}>
    <RoleTabs />
  </Show>
)
