import {
  Card,
  CardContent,
  CardHeader,
  createStyles,
  Grid,
  makeStyles,
  Theme,
} from '@material-ui/core'
import React from 'react'
import {
  GET_ONE,
  ResourceContextProvider,
  sanitizeFetchType,
  useQueryWithStore,
  useTranslate,
} from 'react-admin'
import { ResourceName } from '../core/ResourceName'
import { RadiotherapyAilmentSurveyList } from './radiotherapy-ailment-surveys/RadiotherapyAilmentSurveyList'
import { Authority } from '../core/auth/Authority'
import { useHasAuthority } from './hooks/useHasAuthority'
import { UserBaseDto } from '../core/dto/user/user-base.dto'
import { UserTypes } from '../core/enum/UserTypes'
import { FindPatient } from './common/FindPatient'
import { FindDoctor } from './common/FindDoctor'
import { ChemotherapyAilmentSurveyList } from './chemotherapy-ailment-surveys/ChemotherapyAilmentSurveyList'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      textAlign: 'center',
      letterSpacing: theme.spacing(0.2),
    },
  }),
)

const PatientFinder = () => (
  <Card style={{ width: '100%', marginBottom: '5px' }}>
    <FindPatient />
  </Card>
)

const DoctorFinder = () => (
  <Card style={{ width: '100%', marginBottom: '5px' }}>
    <FindDoctor />
  </Card>
)

const LastRadiotherapyAilmentSurveys = () => {
  const t = useTranslate()
  const { data: user } = useQueryWithStore({
    type: sanitizeFetchType(GET_ONE),
    resource: ResourceName.CURRENT_USER,
    payload: {},
  })

  return (
    <Card style={{ width: '100%', marginBottom: '5px' }}>
      <CardHeader
        title={t('dashboard.cards.last-radiotherapy-ailment-surveys.title')}
      />
      <CardContent>
        <ResourceContextProvider
          value={ResourceName.RADIOTHERAPY_AILMENT_SURVEYS}
        >
          <RadiotherapyAilmentSurveyList
            actions={false}
            filters={undefined}
            filterDefaultValues={{
              hidePatientsWithoutRadiotherapyModule: true,
              filterRelatedPatientsAilmentSurveys:
                (user as UserBaseDto)?.userType === UserTypes.DOCTOR,
              relatedPatientDoctorId: (user as UserBaseDto)?.id,
            }}
            perPage={10}
            pagination={false}
            bulkActionButtons={false}
            sort={{ field: 'date', order: 'DESC' }}
            basePath={`/${ResourceName.RADIOTHERAPY_AILMENT_SURVEYS}`}
            title=" "
          />
        </ResourceContextProvider>
      </CardContent>
    </Card>
  )
}

const LastChemotherapyAilmentSurveys = () => {
  const t = useTranslate()
  const { data: user } = useQueryWithStore({
    type: sanitizeFetchType(GET_ONE),
    resource: ResourceName.CURRENT_USER,
    payload: {},
  })

  return (
    <Card style={{ width: '100%', marginBottom: '5px' }}>
      <CardHeader
        title={t('dashboard.cards.last-chemotherapy-ailment-surveys.title')}
      />
      <CardContent>
        <ResourceContextProvider
          value={ResourceName.CHEMOTHERAPY_AILMENT_SURVEYS}
        >
          <ChemotherapyAilmentSurveyList
            actions={false}
            filters={undefined}
            filterDefaultValues={{
              hidePatientsWithoutChemotherapyModule: true,
              filterRelatedPatientsAilmentSurveys:
                (user as UserBaseDto)?.userType === UserTypes.DOCTOR,
              relatedPatientDoctorId: (user as UserBaseDto)?.id,
            }}
            perPage={10}
            pagination={false}
            bulkActionButtons={false}
            sort={{ field: 'date', order: 'DESC' }}
            basePath={`/${ResourceName.CHEMOTHERAPY_AILMENT_SURVEYS}`}
            title=" "
          />
        </ResourceContextProvider>
      </CardContent>
    </Card>
  )
}

export const Dashboard = () => {
  const classes = useStyles()
  const t = useTranslate()
  const hasAuthority = useHasAuthority()

  let preferences
  try {
    preferences = JSON.parse(localStorage?.preferences)
    if (preferences === null || preferences === undefined) preferences = {}
  } catch {
    preferences = {}
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            className={classes.header}
            title={t('dashboard.welcome')}
            titleTypographyProps={{ variant: 'h4' }}
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        {hasAuthority(Authority.VIEW_PATIENTS) && <PatientFinder />}
      </Grid>
      {hasAuthority(Authority.WEB_USER) && (
        <Grid item xs={12}>
          {hasAuthority(Authority.VIEW_DOCTORS) && <DoctorFinder />}
        </Grid>
      )}
      <Grid item xs={12}>
        {hasAuthority(Authority.VIEW_RADIOTHERAPY_AILMENT_SURVEYS) &&
          hasAuthority(Authority.VIEW_RADIOTHERAPY_AILMENTS) && (
            <LastRadiotherapyAilmentSurveys />
          )}
      </Grid>
      <Grid item xs={12}>
        {hasAuthority(Authority.VIEW_CHEMOTHERAPY_AILMENT_SURVEYS) &&
          hasAuthority(Authority.VIEW_CHEMOTHERAPY_AILMENTS) && (
            <LastChemotherapyAilmentSurveys />
          )}
      </Grid>
    </Grid>
  )
}
