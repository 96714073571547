import React from 'react'
import { Resource } from 'react-admin'
import { Authority } from '../core/auth/Authority'
import { ResourceName } from '../core/ResourceName'
import { RoleCreate } from './administration/roles/RoleCreate'
import { RoleEdit } from './administration/roles/RoleEdit'
import { RoleList } from './administration/roles/RoleList'
import { RoleShow } from './administration/roles/RoleShow'
import { UserCreate } from './administration/users/UserCreate'
import { UserEdit } from './administration/users/UserEdit'
import { UserList } from './administration/users/UserList'
import { UserShow } from './administration/users/UserShow'
import { RadiotherapyAilmentSurveyCreate } from './radiotherapy-ailment-surveys/RadiotherapyAilmentSurveyCreate'
import { RadiotherapyAilmentSurveyEdit } from './radiotherapy-ailment-surveys/RadiotherapyAilmentSurveyEdit'
import { RadiotherapyAilmentSurveyList } from './radiotherapy-ailment-surveys/RadiotherapyAilmentSurveyList'
import { RadiotherapyAilmentSurveyShow } from './radiotherapy-ailment-surveys/RadiotherapyAilmentSurveyShow'
import { RadiotherapyAilmentList } from './radiotherapy-ailment-surveys/RadiotherapyAilmentSurveyShow/RadiotherapyAilmentList'
import { CurrentUserEdit } from './current-user/CurrentUserEdit'
import { hasAuthorityWithGivenPermissions as hasAuthority } from './hooks/useHasAuthority'
import { PatientEdit } from './patient-management/PatientEdit'
import { PatientList } from './patient-management/PatientList'
import { PatientShow } from './patient-management/PatientShow'
import { SchedulerCreate } from './schedulers/SchedulerCreate'
import { SchedulerEdit } from './schedulers/SchedulerEdit'
import { SchedulerList } from './schedulers/SchedulerList'
import { SchedulerShow } from './schedulers/SchedulerShow'
import { DoctorList } from './doctor-management/DoctorList'
import { DoctorShow } from './doctor-management/DoctorShow'
import { DoctorEdit } from './doctor-management/DoctorEdit'
import { WebUserList } from './web-user-management/WebUserList'
import { WebUserShow } from './web-user-management/WebUserShow'
import { WebUserEdit } from './web-user-management/WebUserEdit'
import { VacationCalendarList } from './vacation-calendars/VacationCalendarList'
import { VacationCalendarShow } from './vacation-calendars/VacationCalendarShow'
import { VacationCalendarEdit } from './vacation-calendars/VacationCalendarEdit'
import { VacationCalendarCreate } from './vacation-calendars/VacationCalendarCreate'
import { ChemotherapyAilmentSurveyList } from './chemotherapy-ailment-surveys/ChemotherapyAilmentSurveyList'
import { ChemotherapyAilmentSurveyShow } from './chemotherapy-ailment-surveys/ChemotherapyAilmentSurveyShow'
import { ChemotherapyAilmentSurveyEdit } from './chemotherapy-ailment-surveys/ChemotherapyAilmentSurveyEdit'
import { ChemotherapyAilmentSurveyCreate } from './chemotherapy-ailment-surveys/ChemotherapyAilmentSurveyCreate'
import { ChemotherapyAilmentList } from './chemotherapy-ailment-surveys/ChemotherapyAilmentSurveyShow/ChemotherapyAilmentList'

export const generateResourcesBasedOnPermissions = (
  permissions: Set<string>,
) => [
  <Resource name="dummy" />,
  hasAuthority(Authority.VIEW_USERS, permissions) && (
    <Resource
      name={ResourceName.USERS}
      list={UserList}
      show={UserShow}
      edit={
        hasAuthority(Authority.EDIT_USERS, permissions) ? UserEdit : undefined
      }
      create={
        hasAuthority(Authority.CREATE_USERS, permissions)
          ? UserCreate
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_ROLES, permissions) && (
    <Resource
      name={ResourceName.ROLES}
      list={RoleList}
      show={RoleShow}
      edit={
        hasAuthority(Authority.EDIT_ROLES, permissions) ? RoleEdit : undefined
      }
      create={
        hasAuthority(Authority.CREATE_ROLES, permissions)
          ? RoleCreate
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_ROLES, permissions) && (
    <Resource name={ResourceName.PERMISSIONS} />
  ),
  hasAuthority(Authority.VIEW_USER_MANAGEMENT, permissions) && (
    <Resource name={ResourceName.CURRENT_USER} edit={CurrentUserEdit} />
  ),
  hasAuthority(Authority.VIEW_DOCTORS, permissions) && (
    <Resource
      name={ResourceName.DOCTORS}
      list={DoctorList}
      show={DoctorShow}
      edit={
        hasAuthority(Authority.EDIT_DOCTORS, permissions)
          ? DoctorEdit
          : undefined
      }
      create={
        hasAuthority(Authority.CREATE_DOCTORS, permissions)
          ? UserCreate
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_PATIENTS, permissions) && (
    <Resource
      name={ResourceName.PATIENTS}
      list={PatientList}
      show={PatientShow}
      edit={
        hasAuthority(Authority.EDIT_PATIENTS, permissions)
          ? PatientEdit
          : undefined
      }
      create={
        hasAuthority(Authority.CREATE_PATIENTS, permissions)
          ? UserCreate
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_RADIOTHERAPY_AILMENT_SURVEYS, permissions) &&
    hasAuthority(Authority.VIEW_RADIOTHERAPY_AILMENTS, permissions) && (
      <Resource
        name={ResourceName.RADIOTHERAPY_AILMENT_SURVEYS}
        list={RadiotherapyAilmentSurveyList}
        show={RadiotherapyAilmentSurveyShow}
        edit={
          hasAuthority(Authority.EDIT_RADIOTHERAPY_AILMENT_SURVEYS, permissions)
            ? RadiotherapyAilmentSurveyEdit
            : undefined
        }
        create={
          hasAuthority(
            Authority.CREATE_RADIOTHERAPY_AILMENT_SURVEYS,
            permissions,
          )
            ? RadiotherapyAilmentSurveyCreate
            : undefined
        }
      />
    ),
  hasAuthority(Authority.VIEW_RADIOTHERAPY_AILMENTS, permissions) && (
    <Resource
      name={ResourceName.RADIOTHERAPY_AILMENTS}
      list={RadiotherapyAilmentList}
    />
  ),

  hasAuthority(Authority.VIEW_CHEMOTHERAPY_AILMENT_SURVEYS, permissions) &&
    hasAuthority(Authority.VIEW_CHEMOTHERAPY_AILMENTS, permissions) && (
      <Resource
        name={ResourceName.CHEMOTHERAPY_AILMENT_SURVEYS}
        list={ChemotherapyAilmentSurveyList}
        show={ChemotherapyAilmentSurveyShow}
        edit={
          hasAuthority(Authority.EDIT_CHEMOTHERAPY_AILMENT_SURVEYS, permissions)
            ? ChemotherapyAilmentSurveyEdit
            : undefined
        }
        create={
          hasAuthority(
            Authority.CREATE_CHEMOTHERAPY_AILMENT_SURVEYS,
            permissions,
          )
            ? ChemotherapyAilmentSurveyCreate
            : undefined
        }
      />
    ),
  hasAuthority(Authority.VIEW_CHEMOTHERAPY_AILMENTS, permissions) && (
    <Resource
      name={ResourceName.CHEMOTHERAPY_AILMENTS}
      list={ChemotherapyAilmentList}
    />
  ),

  hasAuthority(Authority.VIEW_SCHEDULERS, permissions) && (
    <Resource
      name={ResourceName.SCHEDULERS}
      list={SchedulerList}
      show={SchedulerShow}
      edit={
        hasAuthority(Authority.EDIT_SCHEDULERS, permissions)
          ? SchedulerEdit
          : undefined
      }
      create={
        hasAuthority(Authority.CREATE_SCHEDULERS, permissions)
          ? SchedulerCreate
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_USERS, permissions) && (
    <Resource
      name={ResourceName.WEB_USERS}
      list={WebUserList}
      show={WebUserShow}
      edit={
        hasAuthority(Authority.EDIT_USERS, permissions)
          ? WebUserEdit
          : undefined
      }
      create={
        hasAuthority(Authority.CREATE_USERS, permissions)
          ? UserCreate
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_RADIOTHERAPY_MACHINES, permissions) && (
    <Resource name={ResourceName.RADIOTHERAPY_MACHINES} />
  ),
  hasAuthority(Authority.VIEW_VACATION_CALENDARS, permissions) && (
    <Resource
      name={ResourceName.VACATION_CALENDARS}
      list={VacationCalendarList}
      show={VacationCalendarShow}
      edit={
        hasAuthority(Authority.EDIT_VACATION_CALENDARS, permissions)
          ? VacationCalendarEdit
          : undefined
      }
      create={
        hasAuthority(Authority.CREATE_VACATION_CALENDARS, permissions)
          ? VacationCalendarCreate
          : undefined
      }
    />
  ),
]
