import { makeStyles } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import PropTypes from 'prop-types'
import {
  FieldTitle,
  useChoices,
  useInput,
  useListPaginationContext,
  useTranslate,
  warning,
} from 'ra-core'
import * as React from 'react'
import { useCallback, useState, useRef, useEffect } from 'react'
import {
  ClassesOverride,
  InputHelperText,
  Pagination,
  PaginationProps,
  ResettableTextField,
  SelectInputProps,
  useSupportCreateSuggestion,
} from 'react-admin'
import MUITextField from '@material-ui/core/TextField'
import { get } from 'lodash'

// import ResettableTextField, { resettableStyles } from './ResettableTextField';
// import InputHelperText from './InputHelperText';
// import sanitizeInputRestProps from './sanitizeInputRestProps';
// import Labeled from './Labeled';
// import { LinearProgress } from '../layout';
// import {
//     useSupportCreateSuggestion,
//     SupportCreateSuggestionOptions,
// } from './useSupportCreateSuggestion';
// import { ClassesOverride } from '../types';

const useStyles = makeStyles(
  (theme) => ({
    // ...resettableStyles,
    input: {
      minWidth: theme.spacing(20),
    },
    itemFields: {
      margin: theme.spacing(0.5),
      display: 'inline-block',
    },
    searchInput: {
      margin: theme.spacing(1),
      display: 'flex',
    },
  }),
  { name: 'RaSelectInput' },
)

export interface SelectWithPaginationInputProps extends SelectInputProps {
  classes?: ClassesOverride<typeof useStyles>
  paginationProps?: PaginationProps
  rowIcon?: (record: any) => React.ReactElement
  onFilterValueChange?: (filterValue: string) => void
  showFilter?: boolean | true
}

export const SelectWithPaginationInput = ({
  rowIcon,
  onFilterValueChange,
  showFilter = false,
  ...props
}: SelectWithPaginationInputProps) => {
  const {
    allowEmpty,
    choices = [],
    // classes: classesOverride,
    className,
    create,
    createLabel,
    createValue,
    disableValue,
    emptyText,
    emptyValue,
    format,
    helperText,
    label,
    // loaded,
    loading,
    margin = 'dense',
    onBlur,
    onChange,
    onCreate,
    onFocus,
    options,
    optionText,
    optionValue,
    parse,
    paginationProps,
    resource,
    source,
    translateChoice,
    validate,
    ...rest
  } = props
  const translate = useTranslate()
  const classes = useStyles(props)

  warning(
    source === undefined,
    `If you're not wrapping the SelectInput inside a ReferenceInput, you must provide the source prop`,
  )

  warning(
    choices === undefined,
    `If you're not wrapping the SelectInput inside a ReferenceInput, you must provide the choices prop`,
  )

  const { getChoiceText, getChoiceValue } = useChoices({
    optionText,
    optionValue,
    translateChoice,
  })

  const { id, input, isRequired, meta } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source: source as string,
    validate,
    ...rest,
  })

  const { touched, error, submitError } = meta

  const renderEmptyItemOption = useCallback(
    () =>
      React.isValidElement(emptyText) // eslint-disable-line no-nested-ternary
        ? React.cloneElement(emptyText)
        : emptyText === ''
        ? ' ' // em space, forces the display of an empty line of normal height
        : translate(emptyText, { _: emptyText }),
    [emptyText, translate],
  )

  const renderMenuItemOption = useCallback(
    (choice) => getChoiceText(choice),
    [getChoiceText],
  )

  const handleChange = useCallback(
    async (event: React.ChangeEvent<HTMLSelectElement>, newItem) => {
      if (newItem) {
        const value = getChoiceValue(newItem)
        input.onChange(value)
        return
      }

      input.onChange(event)
    },
    [input, getChoiceValue],
  )

  const {
    getCreateItem,
    handleChange: handleChangeWithCreateSupport,
    createElement,
  } = useSupportCreateSuggestion({
    create,
    createLabel,
    createValue,
    handleChange,
    onCreate,
    optionText,
  })
  // if (loading) {
  //     return (
  //         <Labeled
  //             id={id}
  //             label={label}
  //             source={source}
  //             resource={resource}
  //             className={className}
  //             isRequired={isRequired}
  //             meta={meta}
  //             input={input}
  //             margin={margin}
  //         >
  //             <LinearProgress />
  //         </Labeled>
  //     );
  // }

  const renderCreateItem = () => {
    if (onCreate || create) {
      const createItem = getCreateItem()
      return (
        <MenuItem value={createItem.id} key={createItem.id}>
          {createItem.name}
        </MenuItem>
      )
    }
    return null
  }

  const { setPage } = useListPaginationContext(paginationProps)
  const [filterValue, setFilterValue] = useState('')
  const [shouldUpdateFilter, setShouldUpdateFilter] = useState<boolean>(false)
  const filterInputRef = useRef<any>()
  const handleFilterChange = (event: any) => {
    setFilterValue(event.target.value)
    setShouldUpdateFilter(true)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (onFilterValueChange) onFilterValueChange(filterValue)
      setPage(1)
      setShouldUpdateFilter(false)
    }, 1000)
    if (!shouldUpdateFilter) clearInterval(interval)
    return () => clearInterval(interval)
  }, [filterValue, onFilterValueChange, shouldUpdateFilter, setPage])

  return (
    <>
      <ResettableTextField
        id={id}
        {...input}
        onChange={handleChangeWithCreateSupport}
        select
        label={
          label !== '' &&
          label !== false && (
            <FieldTitle
              label={label}
              source={source}
              resource={resource}
              isRequired={isRequired}
            />
          )
        }
        className={`${classes.input} ${className}`}
        clearAlwaysVisible
        error={!!(touched && (error || submitError))}
        helperText={
          <InputHelperText
            touched={touched === true}
            error={error || submitError}
            helperText={helperText}
          />
        }
        margin={margin}
        {...options}
        // {...sanitizeRestProps(rest)}
        disabled={rest.disabled}
      >
        {showFilter && (
          <div>
            <MUITextField
              className={classes.searchInput}
              inputRef={filterInputRef}
              autoFocus
              onChange={handleFilterChange}
              value={filterValue}
              label={translate(
                'common.select-with-pagination-input.fields.filter-input-caption',
              )}
              onKeyDown={(e: any) => {
                e.stopPropagation()
              }}
              onClick={(e: any) => {
                e.stopPropagation()
              }}
            />
          </div>
        )}
        {allowEmpty ? (
          <MenuItem
            value={emptyValue}
            key="null"
            aria-label={translate('ra.action.clear_input_value')}
            title={translate('ra.action.clear_input_value')}
            onFocusVisible={(e: any) => {
              if (filterInputRef?.current?.focus) filterInputRef.current.focus()
              e.stopPropagation()
            }}
          >
            {renderEmptyItemOption()}
          </MenuItem>
        ) : null}
        {!loading &&
          choices.map((choice) => (
            <MenuItem
              key={getChoiceValue(choice)}
              value={getChoiceValue(choice)}
              disabled={get(choice, disableValue)}
              onFocusVisible={(e: any) => {
                if (filterInputRef?.current?.focus)
                  filterInputRef.current.focus()
                e.stopPropagation()
              }}
              style={{ whiteSpace: 'pre-line' }}
            >
              {rowIcon && (
                <div className={classes.itemFields}>{rowIcon(choice)}</div>
              )}
              <div className={classes.itemFields}>
                {renderMenuItemOption(choice)}
              </div>
            </MenuItem>
          ))}
        {renderCreateItem()}
        <div>
          {!loading && (
            <Pagination
              {...paginationProps}
              onClick={(e: any) => {
                e.stopPropagation()
              }}
            />
          )}
        </div>
      </ResettableTextField>
      {createElement}
    </>
  )
}

SelectWithPaginationInput.propTypes = {
  allowEmpty: PropTypes.bool,
  emptyText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  emptyValue: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  choices: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  options: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  optionText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]).isRequired,
  optionValue: PropTypes.string.isRequired,
  disableValue: PropTypes.string,
  resettable: PropTypes.bool,
  resource: PropTypes.string,
  source: PropTypes.string,
  translateChoice: PropTypes.bool,
}

SelectWithPaginationInput.defaultProps = {
  emptyText: '',
  emptyValue: '',
  options: {},
  optionText: 'name', // eslint-disable-line react/default-props-match-prop-types
  optionValue: 'id', // eslint-disable-line react/default-props-match-prop-types
  translateChoice: true,
  disableValue: 'disabled',
}

// const sanitizeRestProps = ({
//     addLabel,
//     afterSubmit,
//     allowNull,
//     beforeSubmit,
//     choices,
//     className,
//     crudGetMatching,
//     crudGetOne,
//     data,
//     filter,
//     filterToQuery,
//     formatOnBlur,
//     isEqual,
//     limitChoicesToValue,
//     multiple,
//     name,
//     pagination,
//     perPage,
//     ref,
//     reference,
//     refetch,
//     render,
//     setFilter,
//     setPagination,
//     setSort,
//     sort,
//     subscription,
//     type,
//     validateFields,
//     validation,
//     value,
//     ...rest
// }: any) => sanitizeInputRestProps(rest);
