import {
  CreateParams,
  DataProvider,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from 'react-admin'
import {
  CURRENT_DOCTOR_URL,
  CURRENT_USER_URL,
  CURRENT_WEB_USER_URL,
} from '../api-urls'
import {
  CacheableDataProviderExtensionResult,
  DataProviderExtensionResult,
} from '../common/data-provider'
import { get, patch, post, put } from '../common/fetch.utils'
import { WebUserSettingsDto } from '../dto/user/settings/web-user-settings.dto'
import { DoctorDto } from '../dto/user/doctor.dto'
import { WebUserDto } from '../dto/user/web-user.dto'
import { convertFileToBase64String } from '../common/converters'
import { UserTypes } from '../enum/UserTypes'
import { DateTime } from '../common/date-time'

const provider = {
  getOne: async (
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    resource: string,
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    params: GetOneParams,
  ): Promise<GetOneResult<WebUserDto | DoctorDto>> => {
    const data = await get<WebUserDto | DoctorDto>(CURRENT_USER_URL)
    return Promise.resolve({
      data,
    })
  },
  update: async (
    resource: string,
    { data, previousData }: UpdateParams<WebUserDto | DoctorDto>,
  ): Promise<UpdateResult> => {
    const update = await patch<{ [n: string]: any }, WebUserDto | DoctorDto>(
      CURRENT_USER_URL,
      {
        firstName:
          data.firstName !== previousData.firstName
            ? data.firstName
            : undefined,
        lastName:
          data.lastName !== previousData.lastName ? data.lastName : undefined,
        userName:
          data.userName !== previousData.userName ? data.userName : undefined,
        base64Photo: (data as any).blobPhoto
          ? await convertFileToBase64String((data as any)?.blobPhoto?.rawFile)
          : undefined,
        email: data.email !== previousData.email ? data.email : undefined,
      },
    )
    return Promise.resolve({ data: update })
  },
  getSettings: async (
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    resource: string,
  ): Promise<DataProviderExtensionResult<WebUserSettingsDto>> => {
    const settings = await get<WebUserSettingsDto>(
      `${CURRENT_USER_URL}/GetSettings/`,
    )
    return {
      data: settings,
    }
  },
  patchSettings: async (
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    resource: string,
    params: WebUserSettingsDto,
  ): Promise<DataProviderExtensionResult<WebUserSettingsDto>> => {
    const settings = await patch<WebUserSettingsDto, WebUserSettingsDto>(
      `${CURRENT_USER_URL}/PatchSettings/`,
      params,
    )
    return {
      data: settings,
    }
  },
  changePassword: async (
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    resource: string,
    params: ChangeUserPasswordRequest,
  ): Promise<DataProviderExtensionResult<ChangeUserPasswordResponse>> => {
    const settings = await put<
      ChangeUserPasswordRequest,
      ChangeUserPasswordResponse
    >(`${CURRENT_USER_URL}/ChangePassword/`, params)
    return {
      data: settings,
    }
  },
  getMyPatientChat: async (
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    resource: string,
    params: GetMyPatientChatRequest,
  ): Promise<DataProviderExtensionResult<GetMyPatientChatResponse | null>> => {
    const userType = localStorage.getItem('user_type')
    if (
      (userType !== UserTypes.DOCTOR && userType !== UserTypes.WEB_USER) ||
      !params.patientId
    )
      return {
        data: null,
      }
    let response = null
    if (userType === UserTypes.DOCTOR) {
      response = await get<GetMyPatientChatResponse>(
        `${CURRENT_DOCTOR_URL}/GetMyPatientChat/${params.patientId}/`,
      )
    } else if (userType === UserTypes.WEB_USER) {
      response = await get<GetMyPatientChatResponse>(
        `${CURRENT_WEB_USER_URL}/GetPatientAdministrationChat/${params.patientId}/`,
      )
    }
    return {
      data: response,
    }
  },
  getServerDateTime: async (
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    resource: string,
  ): Promise<DataProviderExtensionResult<GetServerDateTimeResponse>> => {
    const settings = await get<GetServerDateTimeResponse>(
      `${CURRENT_USER_URL}/GetServerDateTime/`,
    )
    return {
      data: settings,
    }
  },
  sendAdministrativeMessageToManyPatients: async (
    resource: string,
    { data }: CreateParams<SendMessageToManyPatientsRequest>,
  ): Promise<DataProviderExtensionResult<undefined>> => {
    const userType = localStorage.getItem('user_type')
    if (userType !== UserTypes.WEB_USER) {
      return Promise.reject(
        new Error(
          "Function 'sendAdministrativeMessageToManyPatients' is only for WEB_USER user type",
        ),
      )
    }
    const created = await post<SendMessageToManyPatientsRequest, undefined>(
      `${CURRENT_WEB_USER_URL}/SendAdministrativeMessageToManyPatients/`,
      data,
    )
    return {
      data: created,
    }
  },
} as UserDataProvider

interface UserDataProvider extends DataProvider {
  changePassword: (
    resource: string,
    params: ChangeUserPasswordRequest,
  ) => Promise<CacheableDataProviderExtensionResult<ChangeUserPasswordResponse>>

  getSettings: (
    resource: string,
  ) => Promise<CacheableDataProviderExtensionResult<WebUserSettingsDto>>

  patchSettings: (
    resource: string,
    params: WebUserSettingsDto,
  ) => Promise<CacheableDataProviderExtensionResult<WebUserSettingsDto>>

  getMyPatientChat: (
    resource: string,
    params: GetMyPatientChatRequest,
  ) => Promise<DataProviderExtensionResult<GetMyPatientChatResponse | null>>

  getServerDateTime: (
    resource: string,
  ) => Promise<DataProviderExtensionResult<GetServerDateTimeResponse>>

  sendAdministrativeMessageToManyPatients: (
    resource: string,
    params: SendMessageToManyPatientsRequest,
  ) => Promise<DataProviderExtensionResult<undefined>>
}

interface ChangeUserPasswordRequest {
  readonly newPassword: string
}

interface ChangeUserPasswordResponse {
  readonly password: string
  readonly id: number
  readonly userName: string
}

interface GetMyPatientChatRequest {
  readonly patientId: number
}

export interface GetMyPatientChatResponse {
  readonly patientId: number
  readonly chatId?: number
}

export interface GetServerDateTimeResponse {
  readonly serverDateTime: DateTime
}

export interface SendMessageToManyPatientsRequest {
  readonly message?: string
  readonly mainDoctorIdFilter?: number
  readonly temporaryDoctorIdFilter?: number
  readonly otherDoctorIdFilter?: number
  readonly radiotherapyMachineIdFilter?: number
  readonly radiotherapyDateFromFilter?: DateTime
  readonly radiotherapyDateToFilter?: DateTime
}

export default provider
