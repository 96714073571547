import { CardContent, CardHeader } from '@material-ui/core'
import { stringify } from 'querystring'
import * as React from 'react'
import { useCallback, useState } from 'react'
import {
  GetListResult,
  GET_LIST,
  sanitizeFetchType,
  useMutation,
  useNotify,
  useTranslate,
} from 'react-admin'
import { useHistory } from 'react-router-dom'
import { ResourceName } from '../../core/ResourceName'
import SearchInput from './SearchInput'
import { DoctorDto } from '../../core/dto/user/doctor.dto'

export const FindDoctor = () => {
  const history = useHistory()
  const notify = useNotify()
  const [error, setError] = useState('')
  const [mutate, { loading }] = useMutation()
  const t = useTranslate()

  const fetchDoctors = useCallback(
    (filterString: string) =>
      mutate(
        {
          type: sanitizeFetchType(GET_LIST),
          resource: ResourceName.DOCTORS,
          payload: {
            filter: {
              fullNameSearch: filterString,
              doctorsOnly: true,
            },
          },
        },
        {
          returnPromise: true,
          onFailure: (err) => notify(err?.message, 'error'),
        },
      ) as Promise<GetListResult<DoctorDto>>,
    [mutate, notify],
  )

  const onValue = useCallback(
    async (filterString: string) => {
      setError('')
      const {
        total,
        data: [onlyDoctor],
      } = await fetchDoctors(filterString)
      if (!total) {
        setError('common.find-doctor.error')
      } else if (total === 1) {
        history.push({
          pathname: `/${ResourceName.DOCTORS}/${onlyDoctor.id}/show`,
        })
      } else {
        history.push({
          pathname: `/${ResourceName.DOCTORS}`,
          search: stringify({
            filter: JSON.stringify({
              fullNameSearch: filterString,
              doctorsOnly: true,
            }),
          }),
        })
      }
    },
    [fetchDoctors, history],
  )

  return (
    <>
      <CardHeader title={t('common.find-doctor.title')} />
      <CardContent>
        <SearchInput
          label="common.find-doctor.input-label"
          onValue={onValue}
          disabled={loading}
          error={error}
        />
      </CardContent>
    </>
  )
}
