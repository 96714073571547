import {
  L10n,
  loadCldr,
  registerLicense,
  setCulture,
} from '@syncfusion/ej2-base'
import * as EJ2_LOCALE from '@syncfusion/ej2-locale/src/pl.json'
import { Schedule, TimelineMonth } from '@syncfusion/ej2-react-schedule'
import * as n4 from 'cldr-data/main/pl/ca-gregorian.json'
import * as n3 from 'cldr-data/main/pl/numbers.json'
import * as n2 from 'cldr-data/main/pl/timeZoneNames.json'
import * as s2 from 'cldr-data/supplemental/numberingSystems.json'
import * as s3 from 'cldr-data/supplemental/weekData.json'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './i18n.init'
import './index.css'
import reportWebVitals from './reportWebVitals'

// Registering Syncfusion license key
// registerLicense(
//   'ORg4AjUWIQA/Gnt2VlhiQlVPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSH9RcERiXXxeeXJRR2Q=',
// )

Schedule.Inject(TimelineMonth)
loadCldr(n2, n3, n4, s2, s3)
L10n.load({ pl: EJ2_LOCALE.pl })
setCulture('pl')

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
