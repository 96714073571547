import {
  CreateParams,
  CreateResult,
  DataProvider,
  DeleteParams,
  DeleteResult,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  Identifier,
  UpdateParams,
  UpdateResult,
} from 'react-admin'
import { VACATION_CALENDARS_URL } from '../api-urls'
import { DateTime } from '../common/date-time'
import {
  get,
  getWithPagination,
  patch,
  post,
  remove,
} from '../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../common/get-by-conditions.utils'
import { RadOnkAPIGetListParams } from '../common/rad-onk-api-get-list.params'
import filterMapper from './vacation-calendars-filter.mapper'
import sortMapper from './vacation-calendars-sort.mapper'
import { VacationCalendarDto } from '../dto/vacation-calendar.dto'

const provider = {
  getList: async (
    resource: string,
    { filter: { ...filter }, sort, pagination }: RadOnkAPIGetListParams,
  ): Promise<GetListResult<VacationCalendarDto>> => {
    const filterParams = `s=>${filterParamsComposer('s', filter, filterMapper)}`
    const pathParams = queryParamsComposer(sort, pagination, sortMapper)
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<VacationCalendarDto[]>(
      `${VACATION_CALENDARS_URL}/GetByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<VacationCalendarDto>> => {
    const data = await get<VacationCalendarDto>(
      `${VACATION_CALENDARS_URL}`,
      `/${id}`,
    )
    return Promise.resolve({
      data,
    })
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<VacationCalendarDto>> => {
    const data = await get<VacationCalendarDto[]>(
      `${VACATION_CALENDARS_URL}/GetByConditions`,
      `/s=>new int[] {${ids.toString()}}.Contains(s.Id)`,
    )
    return Promise.resolve({
      data,
    })
  },
  create: async (
    resource: string,
    { data }: CreateParams<CreateVacationCalendarRequest>,
  ): Promise<CreateResult> => {
    const created = await post<
      CreateVacationCalendarRequest[],
      VacationCalendarDto[]
    >(`${VACATION_CALENDARS_URL}`, [{ ...data }])
    return Promise.resolve({ data: created[0] })
  },
  update: async (
    resource: string,
    { id, data, previousData }: UpdateParams<VacationCalendarDto>,
  ): Promise<UpdateResult> => {
    const update = await patch<
      { id: Identifier; [n: string]: any },
      VacationCalendarDto
    >(`${VACATION_CALENDARS_URL}`, {
      id,
      dateFrom:
        data.dateFrom !== previousData.dateFrom ? data.dateFrom : undefined,
      dateTo: data.dateTo !== previousData.dateTo ? data.dateTo : undefined,
      mainDoctorId:
        data.mainDoctorId !== previousData.mainDoctorId
          ? data.mainDoctorId
          : undefined,
      temporaryDoctorId:
        data.temporaryDoctorId !== previousData.temporaryDoctorId
          ? data.temporaryDoctorId
          : undefined,
    })
    return Promise.resolve({ data: update })
  },
  delete: async (
    resource: string,
    { id }: DeleteParams,
  ): Promise<DeleteResult> => {
    const data = await remove<VacationCalendarDto>(
      `${VACATION_CALENDARS_URL}`,
      `/${id}`,
    )
    return {
      data: {
        id: data.id,
      },
    }
  },
} as DataProvider

export interface CreateVacationCalendarRequest {
  readonly mainDoctorId?: number
  readonly temporaryDoctorId?: number
  readonly dateFrom?: number
  readonly dateTo?: DateTime
}

export default provider
