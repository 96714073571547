import React, { cloneElement, useContext } from 'react'
import {
  BooleanInput,
  CreateButton,
  Datagrid,
  Filter,
  FilterButton,
  FilterContext,
  GET_ONE,
  ListProps,
  Pagination,
  sanitizeFetchType,
  sanitizeListRestProps,
  SearchInput,
  TextField,
  TopToolbar,
  useListContext,
  useQueryWithStore,
} from 'react-admin'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { Authority } from '../../core/auth/Authority'
import { UserTypes } from '../../core/enum/UserTypes'
import { ResourceName } from '../../core/ResourceName'
import List from '../common/customized-ra-components/List'
import FilterProps from '../common/FilterProps'
import { useHasAuthority } from '../hooks/useHasAuthority'
import { UserBaseDto } from '../../core/dto/user/user-base.dto'
import FilteredReferenceInput from '../common/FilteredReferenceInput'
import { DoctorDto } from '../../core/dto/user/doctor.dto'
import SendBulkAdministrativeMessageButton from './PatientList/SendBulkAdministrativeMessageButton'

const PatientsActions = ({ ...props }) => {
  const { filters: filtersProp, basePath } = props
  const resource = ResourceName.PATIENTS
  const hasAuthority = useHasAuthority()

  const { displayedFilters, filterValues, showFilter } = useListContext(props)
  const filters = useContext(FilterContext) || filtersProp

  const { data: currentUser } = useQueryWithStore({
    type: sanitizeFetchType(GET_ONE),
    resource: ResourceName.CURRENT_USER,
    payload: {},
  })

  return (
    <TopToolbar {...sanitizeListRestProps(props)}>
      {filtersProp
        ? cloneElement(filtersProp, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })
        : filters && <FilterButton />}
      {localStorage.getItem('user_type') === UserTypes.WEB_USER && (
        <SendBulkAdministrativeMessageButton />
      )}
      {hasAuthority(Authority.CREATE_PATIENTS) && (
        <CreateButton
          resource={ResourceName.PATIENTS}
          to={{
            pathname: `${basePath}/create`,
            state:
              (currentUser as UserBaseDto)?.userType === UserTypes.DOCTOR
                ? { mainDoctorId: currentUser?.id }
                : undefined,
            search: new URLSearchParams({
              userType: UserTypes.PATIENT,
            }).toString(),
          }}
        />
      )}
    </TopToolbar>
  )
}

const PatientsFilters = (props: FilterProps) => {
  const hasAuthority = useHasAuthority()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Filter {...props}>
      <SearchInput source="fullNameSearch" alwaysOn />
      <BooleanInput source="active" alwaysOn />
      {localStorage.getItem('user_type') === UserTypes.DOCTOR &&
        hasAuthority(Authority.WEB_USER) && (
          <BooleanInput source="filterRelatedPatients" alwaysOn />
        )}
      {hasAuthority(Authority.WEB_USER) &&
        hasAuthority(Authority.VIEW_DOCTORS) && (
          <FilteredReferenceInput
            label="resources.patients.fields.mainDoctorId"
            source="mainDoctorId"
            reference={ResourceName.DOCTORS}
            sort={{ field: 'lastName', order: 'ASC' }}
            perPage={smallScreen ? 5 : 15}
            filterSource="fullNameSearch"
            filter={{
              doctorsOnly: true,
            }}
            selectWithPaginationInputProps={{
              optionText: (record: DoctorDto) =>
                `${record.firstName} ${record.lastName}`,
              showFilter: true,
            }}
          />
        )}
      {hasAuthority(Authority.WEB_USER) &&
        hasAuthority(Authority.VIEW_DOCTORS) && (
          <FilteredReferenceInput
            label="resources.patients.fields.temporaryDoctorId"
            source="temporaryDoctorId"
            reference={ResourceName.DOCTORS}
            sort={{ field: 'lastName', order: 'ASC' }}
            perPage={smallScreen ? 5 : 15}
            filterSource="fullNameSearch"
            filter={{
              doctorsOnly: true,
            }}
            selectWithPaginationInputProps={{
              optionText: (record: DoctorDto) =>
                `${record.firstName} ${record.lastName}`,
              showFilter: true,
            }}
          />
        )}
      {hasAuthority(Authority.WEB_USER) &&
        hasAuthority(Authority.VIEW_DOCTORS) && (
          <FilteredReferenceInput
            label="resources.patients.fields.doctorIds"
            source="doctorIds"
            reference={ResourceName.DOCTORS}
            sort={{ field: 'lastName', order: 'ASC' }}
            perPage={smallScreen ? 5 : 15}
            filterSource="fullNameSearch"
            filter={{
              doctorsOnly: true,
            }}
            selectWithPaginationInputProps={{
              optionText: (record: DoctorDto) =>
                `${record.firstName} ${record.lastName}`,
              showFilter: true,
            }}
          />
        )}
    </Filter>
  )
}

const PatientGrid = () => {
  const { loading } = useListContext()

  return (
    <Datagrid
      size="small"
      loaded={!loading}
      rowClick={(id) => `${ResourceName.PATIENTS}/${id}/show`}
      rowStyle={(record) => ({
        color: record && record.active !== true ? 'red' : 'inherit',
      })}
    >
      <TextField source="id" label="ID" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="pesel" />
      <TextField source="userName" />
    </Datagrid>
  )
}

export const PatientList = ({ ...props }: ListProps) => {
  const { data: user } = useQueryWithStore({
    type: sanitizeFetchType(GET_ONE),
    resource: ResourceName.CURRENT_USER,
    payload: {},
  })

  return (
    <List
      {...props}
      perPage={20}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
      exporter={false}
      filters={<PatientsFilters />}
      filterDefaultValues={{
        active: true,
        patientsOnly: true,
        filterRelatedPatients:
          (user as UserBaseDto)?.userType === UserTypes.DOCTOR,
        relatedPatientDoctorId: (user as UserBaseDto)?.id,
      }}
      actions={<PatientsActions />}
    >
      <PatientGrid />
    </List>
  )
}
