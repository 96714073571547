import {
  CreateParams,
  CreateResult,
  DataProvider,
  DeleteParams,
  DeleteResult,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  Identifier,
  UpdateParams,
  UpdateResult,
} from 'react-admin'
import { CHEMOTHERAPY_AILMENTS_URL } from '../api-urls'
import {
  get,
  getWithPagination,
  patch,
  post,
  remove,
} from '../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../common/get-by-conditions.utils'
import { RadOnkAPIGetListParams } from '../common/rad-onk-api-get-list.params'
import { ChemotherapyAilmentBaseDTO } from '../dto/chemotherapy-ailment/chemotherapy-ailment-base.dto'
import { ChemotherapyAilmentTypes } from '../enum/ChemotherapyAilmentTypes'
import filterMapper from './chemotherapy-ailments-filter.mapper'
import sortMapper from './chemotherapy-ailments-sort.mapper'
import { DiarrheaAilmentDTO } from '../dto/chemotherapy-ailment/diarrhea-ailment.dto'
import { HairLossAilmentDTO } from '../dto/chemotherapy-ailment/hair-loss-ailment.dto'
import { HandsTinglingAilmentDTO } from '../dto/chemotherapy-ailment/hands-tingling-ailment.dto'
import { NauseaAilmentDTO } from '../dto/chemotherapy-ailment/nausea-ailment.dto'
import { PainAndBurnAilmentDTO } from '../dto/chemotherapy-ailment/pain-and-burn-ailment.dto'
import { WeaknessAilmentDTO } from '../dto/chemotherapy-ailment/weakness-ailment.dto'

const provider = {
  getList: async (
    resource: string,
    { filter: { ...filter }, sort, pagination }: RadOnkAPIGetListParams,
  ): Promise<
    GetListResult<
      | DiarrheaAilmentDTO
      | HairLossAilmentDTO
      | HandsTinglingAilmentDTO
      | NauseaAilmentDTO
      | PainAndBurnAilmentDTO
      | WeaknessAilmentDTO
    >
  > => {
    const filterParams = `s=>${filterParamsComposer('s', filter, filterMapper)}`
    const pathParams = queryParamsComposer(sort, pagination, sortMapper)
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<ChemotherapyAilmentBaseDTO[]>(
      `${CHEMOTHERAPY_AILMENTS_URL}/GetByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<
    GetOneResult<
      | DiarrheaAilmentDTO
      | HairLossAilmentDTO
      | HandsTinglingAilmentDTO
      | NauseaAilmentDTO
      | PainAndBurnAilmentDTO
      | WeaknessAilmentDTO
    >
  > => {
    const data = await get<ChemotherapyAilmentBaseDTO>(
      `${CHEMOTHERAPY_AILMENTS_URL}`,
      `/${id}`,
    )
    return Promise.resolve({
      data,
    })
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<ChemotherapyAilmentBaseDTO>> => {
    const data = await get<ChemotherapyAilmentBaseDTO[]>(
      `${CHEMOTHERAPY_AILMENTS_URL}/GetByConditions`,
      `/a=>new int[] {${ids.toString()}}.Contains(a.Id)`,
    )
    return Promise.resolve({
      data,
    })
  },
  create: async (
    resource: string,
    { data }: CreateParams<CreateChemotherapyAilmentRequest>,
  ): Promise<CreateResult> => {
    const created = await post<
      CreateChemotherapyAilmentRequest[],
      ChemotherapyAilmentBaseDTO[]
    >(`${CHEMOTHERAPY_AILMENTS_URL}`, [data])
    return Promise.resolve({ data: created[0] })
  },
  update: async (
    resource: string,
    { data }: UpdateParams<ChemotherapyAilmentBaseDTO>,
  ): Promise<UpdateResult> => {
    const update = await patch<
      { id: Identifier; [n: string]: any },
      ChemotherapyAilmentBaseDTO
    >(`${CHEMOTHERAPY_AILMENTS_URL}`, data)
    return Promise.resolve({ data: update })
  },
  delete: async (
    resource: string,
    { id }: DeleteParams,
  ): Promise<DeleteResult> => {
    const data = await remove<ChemotherapyAilmentBaseDTO>(
      `${CHEMOTHERAPY_AILMENTS_URL}`,
      `/${id}`,
    )
    return {
      data: {
        id: data.id,
      },
    }
  },
} as DataProvider

export interface CreateChemotherapyAilmentRequest {
  readonly ailmentType?: ChemotherapyAilmentTypes
  readonly occurs?: boolean
  readonly ailmentSurveyId?: number
  readonly level?: number
}

export default provider
