var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import set from 'lodash/set';
import get from 'lodash/get';
import { RA_PREFERENCE_WORKSPACE } from './constants';
export var getPreferencesStorage = function () {
    if (localStorageAvailable == undefined) {
        localStorageAvailable = testLocalStorage();
    }
    return localStorageAvailable ? window.localStorage : memoryStorage;
};
/**
 * reads key preference from storage
 *
 * @param {string} key Path of the preference key to read, uses lodash.get
 *
 * @return {T} Preference value

 * @example // Read full preferences
 *
 * const preferences = readStorage<PreferenceType>();
 *
 * @example // read preference for a particular key
 *
 * const density = readStorage<string>('density');

 * @example // read preference for a deep key
 *
 * const columns = readStorage<string[]>('orders.list.columns');
 */
export var readStorage = function (key) {
    var preferences = JSON.parse(getPreferencesStorage().getItem(RA_PREFERENCE_WORKSPACE)) ||
        {};
    if (key) {
        return tryParse(get(preferences, key));
    }
    return (tryParse(preferences) || {});
};
/**
 * sets key preference to value on the preference storage
 *
 * @param {string} key Path of the preference key to update, uses lodash.set
 * @param {T} value New preference value
 *
 * @return {void}
 *
 * @example // Write full preferences to localstorage
 *
 * writeStorage<PreferenceType>('', {
 *      users: {
 *          lists: {
 *              columns: ['id', 'address'],
 *          },
 *      },
 *  });
 *
 * @example // Update preference for a particular key
 *
 * writeStorage<string>('density', 'small');
 *
 * @example // Update preference for a deep key
 *
 * writeStorage<string[]>('orders.list.columns', ['id', 'date', 'customer']);
 */
export var writeStorage = function (key, value) {
    var storage = getPreferencesStorage();
    try {
        var preferences = JSON.parse(storage.getItem(RA_PREFERENCE_WORKSPACE)) || {};
        var newPreferences = key === '' ? value : set(__assign({}, preferences), key, value);
        storage.setItem(RA_PREFERENCE_WORKSPACE, JSON.stringify(newPreferences));
        window.dispatchEvent(PreferencesChanged.create(newPreferences));
        return newPreferences;
    }
    catch (err) {
        if (err instanceof TypeError &&
            err.message.includes('circular structure')) {
            throw new TypeError('The object that was given to the writeStorage function has circular references.\n' +
                'For more information, check here: ' +
                'https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value');
        }
        throw err;
    }
};
export var overwriteStorage = function (value) {
    var storage = getPreferencesStorage();
    storage.setItem(RA_PREFERENCE_WORKSPACE, JSON.stringify(value));
};
var localStorageAvailable = undefined;
var storage = {};
var memoryStorage = {
    getItem: function (key) {
        return get(storage, key);
    },
    setItem: function (key, value) {
        set(storage, key, value);
    },
};
var tryParse = function (value) {
    try {
        return JSON.parse(value);
    }
    catch (_a) {
        return value;
    }
};
var testLocalStorage = function () {
    if (window.localStorage == undefined) {
        return false;
    }
    try {
        window.localStorage.setItem('test', 'test');
        window.localStorage.removeItem('test');
        return true;
    }
    catch (_a) {
        return false;
    }
};
export var PreferencesChanged = {
    eventName: 'onPreferencesChange',
    create: function (payload) {
        return new CustomEvent(PreferencesChanged.eventName, {
            detail: payload,
        });
    },
};
