import { useEffect, useContext } from 'react';
import { PreferencesEditorDialogContext } from './PreferencesEditorDialogContext';
export var EditorTitle = function (_a) {
    var title = _a.title;
    var setTitle = useContext(PreferencesEditorDialogContext).setTitle;
    useEffect(function () {
        setTitle(title);
    }, [title, setTitle]);
    return null;
};
