var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { getFieldLabelTranslationArgs, useTranslate, } from 'react-admin';
import { ReferenceFieldInput } from './ReferenceFieldInput';
import { FieldTypeInput } from './FieldTypeInput';
import { ReferenceResourceInput } from './ReferenceResourceInput';
import { useResourceModel } from './useResourceModel';
import { EditorTitle } from '../Editor';
/**
 * A component which provides a configuration UI to tweak a resource model field
 * @param {ReferenceFieldInputProps} props
 * @param {InferredElementDescription} props.field The field from the resource model
 * @param {String} props.resource The resource
 * @param {Function} props.onSave The callback called to save the field model
 */
export var ResourceModelFieldEditor = function (_a) {
    var _b;
    var field = _a.field, preferencesKey = _a.preferencesKey, resource = _a.resource;
    var translate = useTranslate();
    var _c = useResourceModel(resource, preferencesKey), model = _c[0], saveModel = _c[1];
    var handleTypeChange = function (event) {
        var _a;
        saveModel(__assign(__assign({}, model), { fields: __assign(__assign({}, model.fields), (_a = {}, _a[field.props.source] = __assign(__assign({}, field), { type: event.target.value }), _a)) }));
    };
    var handleReferenceResourceChange = function (event) {
        var _a;
        saveModel(__assign(__assign({}, model), { fields: __assign(__assign({}, model.fields), (_a = {}, _a[field.props.source] = __assign(__assign({}, field), { props: __assign(__assign({}, field.props), { reference: event.target.value }) }), _a)) }));
    };
    var handleReferenceFieldChange = function (referenceField) {
        var _a;
        saveModel(__assign(__assign({}, model), { fields: __assign(__assign({}, model.fields), (_a = {}, _a[field.props.source] = __assign(__assign({}, field), { children: __assign(__assign({}, field.children), { props: __assign(__assign({}, field.children
                        .props), { optionText: referenceField }) }) }), _a)) }));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(EditorTitle, { title: translate.apply(void 0, getFieldLabelTranslationArgs({
                label: field.props.label,
                resource: resource,
                source: field.props.source,
            })) }),
        React.createElement(FieldTypeInput, { label: "Type", onChange: handleTypeChange, value: field.type }),
        field.type === 'reference' && (React.createElement(React.Fragment, null,
            React.createElement(ReferenceResourceInput, { label: "Reference", onChange: handleReferenceResourceChange, value: field.props.reference }),
            React.createElement(ReferenceFieldInput, { label: "Field displayed", onChange: handleReferenceFieldChange, resource: field.props.reference, value: (_b = field.children.props) === null || _b === void 0 ? void 0 : _b.optionText })))));
};
