const mapping: Record<
  string,
  (lambdaParameter: string, value: any, otherValues: any) => string | null
> = {
  id: (lambdaParameter, value) => `${lambdaParameter}.Id.Equals(${value})`,
  name: (lambdaParameter, value) =>
    `${lambdaParameter}.Name.Contains(${value})`,
  externalId: (lambdaParameter, value) =>
    `${lambdaParameter}.ExternalId.Equals(${value})`,
}

const filterMapper = (key: string) =>
  mapping[key] as (
    lambdaParameter: string,
    value: any,
    otherValues: any,
  ) => string

export default filterMapper
