import React, { useState } from 'react'
import { useTranslate, useShowContext } from 'react-admin'
import MessageIcon from '@material-ui/icons/MessageOutlined'
import Button from '../../common/customized-mui-components/Button'
import { SendBulkAdministrativeMessageDialog } from './SendBulkAdministrativeMessageDialog'

const SendBulkAdministrativeMessageButton = () => {
  const { loading } = useShowContext()
  const [dialogOpen, setDialogOpen] = useState(false)
  const translate = useTranslate()

  return (
    <>
      <SendBulkAdministrativeMessageDialog
        open={dialogOpen}
        close={() => setDialogOpen(false)}
      />
      <Button
        variant="text"
        size="small"
        label={translate(
          'resources.patients.actions.sendBulkAdministrativeMessage',
        )}
        disabled={loading}
        onClick={() => setDialogOpen(true)}
      >
        <MessageIcon />
      </Button>
    </>
  )
}

export default SendBulkAdministrativeMessageButton
