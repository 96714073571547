const mapping: Record<
  string,
  (lambdaParameter: string, value: any, otherValues: any) => string | null
> = {
  date: (lambdaParameter, value) =>
    `(${lambdaParameter}.Date > DateTime.Parse("${
      value.split(';')[0]
    }").ToUniversalTime() && ${lambdaParameter}.Date < DateTime.Parse("${
      value.split(';')[1]
    }").ToUniversalTime())`,
  patientId: (lambdaParameter, value) =>
    `(${lambdaParameter}.PatientId.Equals(${value}))`,
  // Brachytherapy
  brachytherapyOnly: (lambdaParameter, value) =>
    value ? `(${lambdaParameter} is BrachytherapyScheduler)` : null,
  // Chemotherapy
  chemotherapyOnly: (lambdaParameter, value) =>
    value ? `(${lambdaParameter} is ChemotherapyScheduler)` : null,
  // Examination
  examinationOnly: (lambdaParameter, value) =>
    value ? `(${lambdaParameter} is ExaminationScheduler)` : null,
  // Recommendation
  recommendationOnly: (lambdaParameter, value) =>
    value ? `(${lambdaParameter} is RecommendationScheduler)` : null,
  description: (lambdaParameter, value) =>
    `(${lambdaParameter}.Description.Contains("${value}"))`,
  // Radiotherapy
  radiotherapyOnly: (lambdaParameter, value) =>
    value ? `(${lambdaParameter} is RadiotherapyScheduler)` : null,
  activityName: (lambdaParameter, value) =>
    `(${lambdaParameter}.ActivityName.Contains("${value}"))`,
  appointmentStatus: (lambdaParameter, value) =>
    `(${lambdaParameter}.AppointmentStatus.Equals(RadiotherapyAppointmentStatuses.${value}))`,
  radiotherapyMachineId: (lambdaParameter, value) =>
    `(${lambdaParameter}.RadiotherapyMachineId.Equals(${value}))`,

  filterRelatedPatientsScheduler: () => null,
  relatedPatientDoctorId: (lambdaParameter, value, otherValues) =>
    otherValues?.filterRelatedPatientsScheduler === false
      ? null
      : `(${lambdaParameter}.Patient.Doctors.Any(d=>d.Id.Equals(${value})) || ${lambdaParameter}.Patient.MainDoctorId.Equals(${value}) || (${lambdaParameter}.Patient.TemporaryDoctorId.Equals(${value})))`,
}

const filterMapper = (key: string) =>
  mapping[key] as (
    lambdaParameter: string,
    value: any,
    otherValues: any,
  ) => string

export default filterMapper
