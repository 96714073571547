const mapping: Record<
  string,
  (lambdaParameter: string, value: any, otherValues: any) => string | null
> = {
  date: (lambdaParameter, value) =>
    `(${lambdaParameter}.Date > DateTime.Parse("${
      value.split(';')[0]
    }").ToUniversalTime() && ${lambdaParameter}.Date < DateTime.Parse("${
      value.split(';')[1]
    }").ToUniversalTime())`,
  patientId: (lambdaParameter, value) =>
    `(${lambdaParameter}.PatientId.Equals(${value}))`,
  hidePatientsWithoutChemotherapyModule: (lambdaParameter, value) =>
    value === true
      ? `(${lambdaParameter}.Patient.Settings.IsChemotherapyModuleAvailable)`
      : '',
  filterRelatedPatientsAilmentSurveys: () => null,
  relatedPatientDoctorId: (lambdaParameter, value, otherValues) =>
    otherValues?.filterRelatedPatientsAilmentSurveys === false
      ? null
      : `(${lambdaParameter}.Patient.Doctors.Any(d=>d.Id.Equals(${value})) || ${lambdaParameter}.Patient.MainDoctorId.Equals(${value}) || (${lambdaParameter}.Patient.TemporaryDoctorId.Equals(${value})))`,
  hideEmptyAilmentSurveys: (lambdaParameter, value) =>
    value === true ? `(${lambdaParameter}.Ailments.Any())` : null,
}

const filterMapper = (key: string) =>
  mapping[key] as (
    lambdaParameter: string,
    value: any,
    otherValues: any,
  ) => string

export default filterMapper
