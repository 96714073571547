import * as React from 'react';
import { DialogTitle, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslate } from 'react-admin';
import { usePreferencesEditor } from '../usePreferencesEditor';
export var PreferencesEditorDialogTitle = function (props) {
    var title = props.title;
    var classes = useStyles(props);
    var translate = useTranslate();
    var _a = usePreferencesEditor(), disable = _a.disable, history = _a.history, setEditor = _a.setEditor;
    var handleBackClick = function () {
        if (history.location.pathname !== '/') {
            history.goBack();
        }
        else {
            setEditor(null);
        }
    };
    return (React.createElement(DialogTitle, { className: classes.root, id: "preferences-editor-dialog-title", disableTypography: true },
        !!history && (React.createElement(IconButton, { size: "small", "aria-label": translate('ra.action.back'), className: classes.backButton, onClick: handleBackClick },
            React.createElement(BackIcon, null))),
        React.createElement(Typography, { align: "center", color: "textSecondary" }, !!title && translate(title, { _: title })),
        React.createElement(IconButton, { "aria-label": translate('ra.action.close'), className: classes.closeButton, onClick: disable, size: "small" },
            React.createElement(CloseIcon, null))));
};
var useStyles = makeStyles(function (theme) { return ({
    root: {
        cursor: 'move',
        padding: theme.spacing(1.5),
    },
    backButton: {
        position: 'absolute',
        left: theme.spacing(1),
        top: theme.spacing(1.4),
        color: theme.palette.grey[500],
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}); }, {
    name: 'RaBaseEditorTitle',
});
