import { DataProvider } from 'react-admin'
import { RadiotherapyMachineDto } from '../../dto/radiotherapy-machine.dto'

const fakeRadiotherapyMachines: RadiotherapyMachineDto[] = []

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({
      data: fakeRadiotherapyMachines,
      total: fakeRadiotherapyMachines.length,
    }),
} as DataProvider

export default provider
