import { Box, Divider, Typography } from '@material-ui/core'
import React from 'react'
import {
  DateField,
  EditButton,
  FunctionField,
  ReferenceField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
  useShowContext,
  useTranslate,
} from 'react-admin'
import { Authority } from '../../core/auth/Authority'
import { ResourceName } from '../../core/ResourceName'
import { DeleteButtonWithConfirm } from '../common/DeleteButtonWithConfirm'
import { useHasAuthority } from '../hooks/useHasAuthority'
import { VacationCalendarDto } from '../../core/dto/vacation-calendar.dto'
import { DoctorDto } from '../../core/dto/user/doctor.dto'

const ShowTitle = () => {
  const translate = useTranslate()
  return <span>{translate('resources.vacation-calendars.titles.show')}</span>
}

const GeneralActions = ({ ...props }) => {
  const { record: vacation } = useShowContext<VacationCalendarDto>()
  const hasAuthority = useHasAuthority()

  return (
    <>
      <Divider />
      <Box p={1} display="block" textAlign="right">
        {hasAuthority(Authority.EDIT_VACATION_CALENDARS) && (
          <EditButton
            basePath={`/${ResourceName.VACATION_CALENDARS}`}
            record={vacation}
            variant="contained"
            style={{ margin: '2px' }}
          />
        )}
        {hasAuthority(Authority.EDIT_VACATION_CALENDARS) && (
          <DeleteButtonWithConfirm {...props} />
        )}
      </Box>
    </>
  )
}

const VacationCalendarLayout = ({ ...props }) => (
  <SimpleShowLayout {...props}>
    <TextField source="id" label="ID" />
    <ReferenceField
      source="mainDoctorId"
      reference={ResourceName.DOCTORS}
      link="show"
      emptyText="-"
      sortable={false}
    >
      <FunctionField<DoctorDto>
        source="mainDoctorId"
        render={(record?: DoctorDto) => (
          <Typography variant="body2">
            {record?.firstName} {record?.lastName}
          </Typography>
        )}
      />
    </ReferenceField>
    <ReferenceField
      source="temporaryDoctorId"
      reference={ResourceName.DOCTORS}
      link="show"
      emptyText="-"
      sortable={false}
    >
      <FunctionField<DoctorDto>
        source="temporaryDoctorId"
        render={(record?: DoctorDto) => (
          <Typography variant="body2">
            {record?.firstName} {record?.lastName}
          </Typography>
        )}
      />
    </ReferenceField>
    <DateField
      source="dateFrom"
      showTime
      options={{
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }}
      emptyText="-"
    />
    <DateField
      source="dateTo"
      showTime
      options={{
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }}
      emptyText="-"
    />
    <GeneralActions />
  </SimpleShowLayout>
)

export const VacationCalendarShow = (props: ShowProps) => (
  <Show {...props} actions={false} title={<ShowTitle />}>
    <VacationCalendarLayout />
  </Show>
)
