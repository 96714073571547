var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { forwardRef } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import { useTranslate } from 'ra-core';
export var OpenEditorButton = forwardRef(function (_a, ref) {
    var _b = _a.children, children = _b === void 0 ? React.createElement(SettingsIcon, null) : _b, _c = _a.label, label = _c === void 0 ? 'ra-preferences.no-code.action.show_editor' : _c, props = __rest(_a, ["children", "label"]);
    var translate = useTranslate();
    var translatedLabel = translate(label, { _: label });
    return (React.createElement(Tooltip, { title: translatedLabel },
        React.createElement(IconButton, __assign({ ref: ref, "aria-label": translatedLabel, color: "primary" }, props), children)));
});
OpenEditorButton.displayName = 'OpenEditorButton';
