import { fade, makeStyles } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import React, { useState } from 'react'
import {
  DELETE,
  sanitizeFetchType,
  useMutation,
  useRefresh,
  useShowContext,
  useNotify,
  useRedirect,
} from 'react-admin'
import { ExtendedTheme } from '../layout/app.theme'
import { ConfirmDialog } from './ConfirmDialog'
import Button from './customized-mui-components/Button'

const useStyles = makeStyles((theme: ExtendedTheme) => ({
  button: {
    margin: '2px',
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: fade(theme.palette.error.main, 0.62),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
}))

export const DeleteButtonWithConfirm = ({ ...props }) => {
  const { loading } = useShowContext()
  const { disabled, record, resource } = props
  const classes = useStyles()
  const refresh = useRefresh()
  const redirect = useRedirect()
  const notify = useNotify()
  const [mutate, { loading: mutationLoading }] = useMutation()
  const [dialogOpen, setDialogOpen] = useState(false)

  const handleDelete = async () => {
    setDialogOpen(false)
    await mutate(
      {
        type: sanitizeFetchType(DELETE),
        resource,
        payload: {
          id: record?.id,
        },
      },
      {
        returnPromise: true,
        onSuccess: () => {
          redirect('list', `/${resource}`)
          notify('ra.notification.deleted', undefined, undefined, false)
        },
      },
    )
    refresh()
  }

  const handleDialog = () => {
    setDialogOpen(true)
  }

  return (
    <>
      <ConfirmDialog
        open={dialogOpen}
        confirm={handleDelete}
        source="removeRecord"
        cancel={() => setDialogOpen(false)}
        action="remove"
        resource={resource}
      />
      <Button
        variant="contained"
        onClick={handleDialog}
        className={classes.button}
        label="ra.action.delete"
        startIcon={<Delete />}
        useSmallVersionBreakpoint={false}
        disabled={disabled || loading || mutationLoading}
      />
    </>
  )
}
