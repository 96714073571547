import {
  CreateParams,
  CreateResult,
  DataProvider,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  Identifier,
  UpdateParams,
  UpdateResult,
} from 'react-admin'
import { ADMINISTRATOR_URL } from '../../api-urls'
import { CacheableDataProviderExtensionResult } from '../../common/data-provider'
import {
  get,
  getWithPagination,
  patch,
  post,
  put,
} from '../../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../../common/get-by-conditions.utils'
import { RadOnkAPIGetListParams } from '../../common/rad-onk-api-get-list.params'
import { RoleChildrenDto } from '../../dto/role/role-children.dto'
import { RoleParentsDto } from '../../dto/role/role-parents.dto'
import { RolePermissionsDto } from '../../dto/role/role-permissions.dto'
import { RoleDto } from '../../dto/role/role.dto'
import filterMapper from './role-filter.mapper'
import { mapSortRoleParam } from './role-sort.mapper'

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: RadOnkAPIGetListParams,
  ): Promise<GetListResult<RoleDto>> => {
    const filterParams = `o=>${filterParamsComposer('o', filter, filterMapper)}`
    const pathParams = queryParamsComposer(sort, pagination, mapSortRoleParam)
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<RoleDto[]>(
      `${ADMINISTRATOR_URL}/GetRolesByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<RoleDto>> => {
    const data = await get<RoleDto>(
      `${ADMINISTRATOR_URL}/GetRoleById/`,
      id as string,
    )
    return Promise.resolve({
      data,
    })
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<RoleDto>> => {
    const data = await get<RoleDto[]>(
      ADMINISTRATOR_URL,
      `/GetRolesByConditions/r=>new int[] {${ids.toString()}}.Contains(r.Id)`,
    )
    return Promise.resolve({ data })
  },
  create: async (
    resource: string,
    { data }: CreateParams<RoleDto>,
  ): Promise<CreateResult> => {
    const created = await post<CreateRoleRequest, RoleDto>(
      `${ADMINISTRATOR_URL}/PostRole`,
      data,
    )
    return Promise.resolve({ data: created })
  },
  update: async (
    resource: string,
    { id, data, previousData }: UpdateParams<RoleDto>,
  ): Promise<UpdateResult> => {
    const update = await patch<{ id: Identifier; [n: string]: any }, RoleDto>(
      `${ADMINISTRATOR_URL}/PatchRole`,
      {
        id,
        name: data.name !== previousData.name ? data.name : undefined,
      },
    )
    return Promise.resolve({ data: update })
  },
  attachPermissions: async (
    resource: string,
    params: RolePermissionsDto,
  ): Promise<CacheableDataProviderExtensionResult<RolePermissionsDto>> => {
    const rolePermissions = await put<RolePermissionsDto, RolePermissionsDto>(
      `${ADMINISTRATOR_URL}/AttachPermissionsToRole`,
      params,
    )
    return {
      data: rolePermissions,
    }
  },
  detachPermissions: async (
    resource: string,
    params: RolePermissionsDto,
  ): Promise<CacheableDataProviderExtensionResult<RolePermissionsDto>> => {
    const rolePermissions = await put<RolePermissionsDto, RolePermissionsDto>(
      `${ADMINISTRATOR_URL}/DetachPermissionsFromRole`,
      params,
    )
    return {
      data: rolePermissions,
    }
  },
  attachParentRolesToRole: async (
    resource: string,
    params: RoleParentsDto,
  ): Promise<CacheableDataProviderExtensionResult<RoleParentsDto>> => {
    const roleParents = await put<RoleParentsDto, RoleParentsDto>(
      `${ADMINISTRATOR_URL}/AttachParentRolesToRole`,
      params,
    )
    return {
      data: roleParents,
    }
  },
  detachParentRolesFromRole: async (
    resource: string,
    params: RoleParentsDto,
  ): Promise<CacheableDataProviderExtensionResult<RoleParentsDto>> => {
    const roleParents = await put<RoleParentsDto, RoleParentsDto>(
      `${ADMINISTRATOR_URL}/DetachParentRolesFromRole`,
      params,
    )
    return {
      data: roleParents,
    }
  },
  attachChildRolesToRole: async (
    resource: string,
    params: RoleChildrenDto,
  ): Promise<CacheableDataProviderExtensionResult<RoleChildrenDto>> => {
    const roleParents = await put<RoleChildrenDto, RoleChildrenDto>(
      `${ADMINISTRATOR_URL}/AttachChildRolesToRole`,
      params,
    )
    return {
      data: roleParents,
    }
  },
  detachChildRolesFromRole: async (
    resource: string,
    params: RoleChildrenDto,
  ): Promise<CacheableDataProviderExtensionResult<RoleChildrenDto>> => {
    const roleParents = await put<RoleChildrenDto, RoleChildrenDto>(
      `${ADMINISTRATOR_URL}/DetachChildRolesFromRole`,
      params,
    )
    return {
      data: roleParents,
    }
  },
} as RoleDataProvider

interface RoleDataProvider extends DataProvider {
  attachPermissions: (
    resource: string,
    params: RolePermissionsDto,
  ) => Promise<CacheableDataProviderExtensionResult<RolePermissionsDto>>

  detachPermissions: (
    resource: string,
    params: RolePermissionsDto,
  ) => Promise<CacheableDataProviderExtensionResult<RolePermissionsDto>>

  attachParentRolesToRole: (
    resource: string,
    params: RoleParentsDto,
  ) => Promise<CacheableDataProviderExtensionResult<RoleParentsDto>>

  detachParentRolesFromRole: (
    resource: string,
    params: RoleParentsDto,
  ) => Promise<CacheableDataProviderExtensionResult<RoleParentsDto>>

  attachChildRolesToRole: (
    resource: string,
    params: RoleChildrenDto,
  ) => Promise<CacheableDataProviderExtensionResult<RoleChildrenDto>>

  detachChildRolesFromRole: (
    resource: string,
    params: RoleChildrenDto,
  ) => Promise<CacheableDataProviderExtensionResult<RoleChildrenDto>>
}

export interface CreateRoleRequest {
  readonly name: string
  readonly permissionsIds: number[]
}

export default provider
