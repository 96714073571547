import { Group, SupervisorAccount, VerifiedUser } from '@material-ui/icons'
import DashboardIcon from '@material-ui/icons/Dashboard'
import React from 'react'
import {
  getResources,
  ReduxState,
  ResourceDefinition,
  useTranslate,
} from 'react-admin'
import { useSelector } from 'react-redux'
import { ResourceName } from '../../core/ResourceName'
import { MultiLevelMenu } from '../../lib/@react-admin/ra-navigation'
import { MenuItem } from '../common/customized-ra-components/MenuItem'
import { ReactComponent as DoctorWF } from '../../assets/icons/Doctor-WF.svg'
import { ReactComponent as PatientWF } from '../../assets/icons/Patient-01-WF.svg'
import { ReactComponent as FormWF } from '../../assets/icons/Form-06-WF.svg'
import { ReactComponent as CalendarWF } from '../../assets/icons/Calendar-WF.svg'
import { ReactComponent as Calendar01WF } from '../../assets/icons/Calendar01-WF.svg'
import { ReactComponent as UserSettingsWF } from '../../assets/icons/User Settings-02-WF.svg'
import { useHasAuthority } from '../hooks/useHasAuthority'
import { Authority } from '../../core/auth/Authority'

export const AppMenu = () => {
  const hasAuthority = useHasAuthority()
  const availableResources = new Set(
    useSelector<ReduxState, ResourceDefinition[]>(getResources).map(
      (r) => r.name as ResourceName,
    ),
  )
  const translate = useTranslate()

  const anyAdministrationResourcesAvailable = (avRes: Set<ResourceName>) =>
    avRes.has(ResourceName.USERS) &&
    avRes.has(ResourceName.ROLES) &&
    avRes.has(ResourceName.PERMISSIONS)

  const anyWebUserManagementResourcesAvailable = (avRes: Set<ResourceName>) =>
    avRes.has(ResourceName.USERS)

  const anyDoctorManagementResourcesAvailable = (avRes: Set<ResourceName>) =>
    avRes.has(ResourceName.DOCTORS)

  const anyPatientManagementResourcesAvailable = (avRes: Set<ResourceName>) =>
    avRes.has(ResourceName.PATIENTS)

  const anyRadiotherapyAilmentSurveysManagementResourcesAvailable = (
    avRes: Set<ResourceName>,
  ) =>
    avRes.has(ResourceName.RADIOTHERAPY_AILMENT_SURVEYS) &&
    avRes.has(ResourceName.RADIOTHERAPY_AILMENTS)

  const anyChemotherapyAilmentSurveysManagementResourcesAvailable = (
    avRes: Set<ResourceName>,
  ) =>
    avRes.has(ResourceName.CHEMOTHERAPY_AILMENT_SURVEYS) &&
    avRes.has(ResourceName.CHEMOTHERAPY_AILMENTS)

  const anySchedulerResourcesAvailable = (avRes: Set<ResourceName>) =>
    avRes.has(ResourceName.SCHEDULERS)

  const anyVacationCalendarResourcesAvailable = (avRes: Set<ResourceName>) =>
    avRes.has(ResourceName.VACATION_CALENDARS)

  return (
    <MultiLevelMenu initialOpen>
      <MenuItem
        to="/"
        label={translate('ra.page.dashboard')}
        name="dashboard"
        icon={<DashboardIcon />}
        exact
      />
      {anyDoctorManagementResourcesAvailable(availableResources) &&
        hasAuthority(Authority.WEB_USER) && (
          <MenuItem
            name={ResourceName.DOCTORS}
            icon={<DoctorWF fill="currentColor" />}
            to={`/${ResourceName.DOCTORS}`}
            label={translate(`resources.${ResourceName.DOCTORS}.name`)}
          />
        )}
      {anyPatientManagementResourcesAvailable(availableResources) && (
        <MenuItem
          name={ResourceName.PATIENTS}
          icon={<PatientWF fill="currentColor" />}
          to={`/${ResourceName.PATIENTS}`}
          label={translate(`resources.${ResourceName.PATIENTS}.name`)}
        />
      )}
      {anyRadiotherapyAilmentSurveysManagementResourcesAvailable(
        availableResources,
      ) && (
        <MenuItem
          name={ResourceName.RADIOTHERAPY_AILMENT_SURVEYS}
          icon={<FormWF fill="currentColor" />}
          to={`/${ResourceName.RADIOTHERAPY_AILMENT_SURVEYS}`}
          label={translate(
            `resources.${ResourceName.RADIOTHERAPY_AILMENT_SURVEYS}.name`,
          )}
        />
      )}
      {anyChemotherapyAilmentSurveysManagementResourcesAvailable(
        availableResources,
      ) && (
        <MenuItem
          name={ResourceName.CHEMOTHERAPY_AILMENT_SURVEYS}
          icon={<FormWF fill="currentColor" />}
          to={`/${ResourceName.CHEMOTHERAPY_AILMENT_SURVEYS}`}
          label={translate(
            `resources.${ResourceName.CHEMOTHERAPY_AILMENT_SURVEYS}.name`,
          )}
        />
      )}
      {anySchedulerResourcesAvailable(availableResources) && (
        <MenuItem
          name={ResourceName.SCHEDULERS}
          icon={<Calendar01WF fill="currentColor" />}
          to={`/${ResourceName.SCHEDULERS}`}
          label={translate(`resources.${ResourceName.SCHEDULERS}.name`)}
        />
      )}
      {anyVacationCalendarResourcesAvailable(availableResources) && (
        <MenuItem
          name={ResourceName.VACATION_CALENDARS}
          icon={<CalendarWF fill="currentColor" />}
          to={`/${ResourceName.VACATION_CALENDARS}`}
          label={translate(`resources.${ResourceName.VACATION_CALENDARS}.name`)}
        />
      )}
      {anyWebUserManagementResourcesAvailable(availableResources) && (
        <MenuItem
          name={ResourceName.WEB_USERS}
          icon={<UserSettingsWF fill="currentColor" />}
          to={`/${ResourceName.WEB_USERS}`}
          label={translate(`resources.${ResourceName.WEB_USERS}.name`)}
        />
      )}
      {anyAdministrationResourcesAvailable(availableResources) && (
        <MenuItem
          name="administration"
          label={translate('side-menu.administration.name')}
          icon={<SupervisorAccount />}
        >
          <MenuItem
            name={ResourceName.ROLES}
            icon={<VerifiedUser />}
            to={`/${ResourceName.ROLES}`}
            label={translate(`resources.${ResourceName.ROLES}.name`)}
          />
          <MenuItem
            name={ResourceName.USERS}
            icon={<Group />}
            to={`/${ResourceName.USERS}`}
            label={translate(`resources.${ResourceName.USERS}.name`)}
          />
        </MenuItem>
      )}
    </MultiLevelMenu>
  )
}
