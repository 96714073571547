import React, { cloneElement, useContext } from 'react'
import {
  CreateButton,
  Datagrid,
  Filter,
  FilterButton,
  FilterContext,
  ListProps,
  Pagination,
  SearchInput,
  TextField,
  TopToolbar,
  sanitizeListRestProps,
  useListContext,
} from 'react-admin'
import { ResourceName } from '../../core/ResourceName'
import List from '../common/customized-ra-components/List'
import FilterProps from '../common/FilterProps'
import { useHasAuthority } from '../hooks/useHasAuthority'
import { Authority } from '../../core/auth/Authority'
import { UserTypes } from '../../core/enum/UserTypes'

const WebUsersActions = ({ ...props }) => {
  const { filters: filtersProp, basePath } = props
  const resource = ResourceName.WEB_USERS
  const hasAuthority = useHasAuthority()

  const { displayedFilters, filterValues, showFilter } = useListContext(props)
  const filters = useContext(FilterContext) || filtersProp

  return (
    <TopToolbar {...sanitizeListRestProps(props)}>
      {filtersProp
        ? cloneElement(filtersProp, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })
        : filters && <FilterButton />}
      {hasAuthority(Authority.CREATE_USERS) && (
        <CreateButton
          resource={ResourceName.WEB_USERS}
          to={{
            pathname: `${basePath}/create`,
            search: new URLSearchParams({
              userType: UserTypes.WEB_USER,
            }).toString(),
          }}
        />
      )}
    </TopToolbar>
  )
}

const WebUsersFilters = (props: FilterProps) => (
  <Filter {...props}>
    <SearchInput source="fullNameSearch" alwaysOn />
  </Filter>
)

const WebUsersGrid = () => {
  const { loading } = useListContext()

  return (
    <Datagrid
      size="small"
      loaded={!loading}
      rowClick={(id) => `${ResourceName.WEB_USERS}/${id}/show`}
    >
      <TextField source="id" label="ID" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="userName" />
    </Datagrid>
  )
}

export const WebUserList = ({ ...props }: ListProps) => (
  <List
    {...props}
    perPage={20}
    pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
    exporter={false}
    filters={<WebUsersFilters />}
    filterDefaultValues={{ webUsersOnly: true }}
    actions={<WebUsersActions />}
  >
    <WebUsersGrid />
  </List>
)
