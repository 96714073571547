var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { cloneElement, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { sanitizeListRestProps, useListContext, useResourceContext, useResourceDefinition, CreateButton, ExportButton, ListActions as RaListActions, TopToolbar, FilterContext, FilterButton, } from 'react-admin';
import { Breadcrumb } from '../layout';
export var ListActions = function (props) {
    var breadcrumb = props.breadcrumb, className = props.className, exporter = props.exporter, filtersProp = props.filters, rest = __rest(props, ["breadcrumb", "className", "exporter", "filters"]);
    var _a = useListContext(props), currentSort = _a.currentSort, displayedFilters = _a.displayedFilters, filterValues = _a.filterValues, basePath = _a.basePath, selectedIds = _a.selectedIds, showFilter = _a.showFilter, total = _a.total;
    var resource = useResourceContext(rest);
    var filters = useContext(FilterContext) || filtersProp;
    var hasCreate = useResourceDefinition(rest).hasCreate;
    return useMemo(function () { return (React.createElement(TopToolbar, __assign({ className: className }, sanitizeListRestProps(rest)),
        breadcrumb,
        filtersProp
            ? cloneElement(filtersProp, {
                resource: resource,
                showFilter: showFilter,
                displayedFilters: displayedFilters,
                filterValues: filterValues,
                context: 'button',
            })
            : filters && React.createElement(FilterButton, null),
        hasCreate && React.createElement(CreateButton, { basePath: basePath }),
        exporter !== false && (React.createElement(ExportButton, { disabled: total === 0, resource: resource, sort: currentSort, filterValues: filterValues })))); }, [resource, displayedFilters, filterValues, selectedIds, filters, total] // eslint-disable-line react-hooks/exhaustive-deps
    );
};
ListActions.propTypes = __assign(__assign({}, RaListActions.propTypes), { breadcrumb: PropTypes.element });
ListActions.defaultProps = {
    breadcrumb: React.createElement(Breadcrumb, { variant: "actions" }),
    selectedIds: [],
    onUnselectItems: function () { return null; },
};
