import React, { useEffect } from 'react'
import {
  TextField,
  makeStyles,
  Theme,
  createStyles,
  useTheme,
  useMediaQuery,
} from '@material-ui/core'
import { useField } from 'react-final-form'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import { ignoreTimezoneOffset } from '../../core/common/date-time'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      verticalAlign: 'center',
      marginBottom: theme.spacing(1),
    },
    mobileContainer: {
      display: 'flex',
      justifyContent: 'center',
      verticalAlign: 'center',
      marginBottom: theme.spacing(1),
      flexDirection: 'column',
    },
    input: {
      alignSelf: 'center',
    },
    icon: {
      marginTop: '4%',
    },
    mobileIcon: {
      alignSelf: 'center',
      transform: 'rotate(90deg)',
    },
  }),
)

export const DateFilterInput = ({
  source,
  type,
}: {
  source: string
  type?: string
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const xSmallScreen = useMediaQuery(theme.breakpoints.down('xs'))

  const defaultFromDate = React.useMemo(() => new Date(Date.now()), [])
  defaultFromDate.setHours(0, 0, 0, 0)
  const defaultToDate = new Date(Date.now())
  defaultToDate.setHours(23, 59, 0, 0)

  const [dateFrom, setDateFrom] = React.useState(
    ignoreTimezoneOffset(defaultFromDate).toJSON(),
  )
  const [dateTo, setDateTo] = React.useState(
    ignoreTimezoneOffset(defaultToDate).toJSON(),
  )

  const {
    input: { onChange },
    meta: { touched, error },
  } = useField(source)

  useEffect(() => {
    onChange([dateFrom, dateTo].join(';'))
  }, [dateFrom, dateTo, onChange, defaultFromDate])

  const convertedDate = (date: Date) =>
    type === 'date'
      ? new Date(date.getTime()).toJSON()?.substring(0, 10)
      : new Date(date.getTime()).toJSON()?.substring(0, 16)

  return (
    <span
      key="date"
      className={xSmallScreen ? classes.mobileContainer : classes.container}
    >
      <TextField
        variant="filled"
        value={convertedDate(new Date(Date.parse(dateFrom)))}
        name={source}
        type={type ?? 'datetime-local'}
        onChange={(e) =>
          setDateFrom(
            ignoreTimezoneOffset(new Date(Date.parse(e.target.value))).toJSON(),
          )
        }
        error={!!(touched && error)}
        helperText={touched && error}
        className={classes.input}
        size="small"
      />
      <ArrowRightAltIcon
        className={xSmallScreen ? classes.mobileIcon : classes.icon}
      />
      <TextField
        variant="filled"
        value={convertedDate(new Date(Date.parse(dateTo)))}
        name={source}
        type={type ?? 'datetime-local'}
        onChange={(e) =>
          setDateTo(
            ignoreTimezoneOffset(new Date(Date.parse(e.target.value))).toJSON(),
          )
        }
        error={!!(touched && error)}
        helperText={touched && error}
        size="small"
      />
    </span>
  )
}
