import { Checkbox, CheckboxProps, Typography } from '@material-ui/core'
import { SwitchBaseProps } from '@material-ui/core/internal/SwitchBase'
import React, { MouseEventHandler } from 'react'
import { useRecordContext } from 'react-admin'

export interface CheckboxFieldProps extends CheckboxProps {
  sortable?: boolean
  label?: string
  source: string
  checkBySource?: (sourceValue: any) => SwitchBaseProps['checked']
  onClick?: (sourceValue: any) => MouseEventHandler | undefined
}

const sanitizeFieldRestProps: (props: any) => any = ({
  addLabel, // eslint-disable-line @typescript-eslint/no-unused-vars
  allowEmpty, // eslint-disable-line @typescript-eslint/no-unused-vars
  basePath, // eslint-disable-line @typescript-eslint/no-unused-vars
  cellClassName, // eslint-disable-line @typescript-eslint/no-unused-vars
  className, // eslint-disable-line @typescript-eslint/no-unused-vars
  emptyText, // eslint-disable-line @typescript-eslint/no-unused-vars
  formClassName, // eslint-disable-line @typescript-eslint/no-unused-vars
  fullWidth, // eslint-disable-line @typescript-eslint/no-unused-vars
  headerClassName, // eslint-disable-line @typescript-eslint/no-unused-vars
  label, // eslint-disable-line @typescript-eslint/no-unused-vars
  linkType, // eslint-disable-line @typescript-eslint/no-unused-vars
  link, // eslint-disable-line @typescript-eslint/no-unused-vars
  locale, // eslint-disable-line @typescript-eslint/no-unused-vars
  record, // eslint-disable-line @typescript-eslint/no-unused-vars
  refetch, // eslint-disable-line @typescript-eslint/no-unused-vars
  resource, // eslint-disable-line @typescript-eslint/no-unused-vars
  sortable, // eslint-disable-line @typescript-eslint/no-unused-vars
  sortBy, // eslint-disable-line @typescript-eslint/no-unused-vars
  sortByOrder, // eslint-disable-line @typescript-eslint/no-unused-vars
  source, // eslint-disable-line @typescript-eslint/no-unused-vars
  textAlign, // eslint-disable-line @typescript-eslint/no-unused-vars
  translateChoice, // eslint-disable-line @typescript-eslint/no-unused-vars
  ...props
}) => props

export const CheckboxField = ({
  sortable,
  label,
  source,
  checkBySource,
  onClick,
  className,
  style,
  ...props
}: CheckboxFieldProps): JSX.Element => {
  const record = useRecordContext()
  const value = record[source as string]
  return (
    <Typography aria-label={label} className={className} style={style}>
      <Checkbox
        aria-sort={sortable ? undefined : 'none'}
        {...(sanitizeFieldRestProps(props) as CheckboxProps)}
        checked={checkBySource && checkBySource(value)}
        onClick={onClick && onClick(value)}
        color="primary"
      />
    </Typography>
  )
}
