import * as React from 'react';
import { useEffect } from 'react';
import { createMemoryHistory } from 'history';
import { Route, Router } from 'react-router-dom';
import { usePreferencesEditor } from '../usePreferencesEditor';
var history = createMemoryHistory();
export var PreferencesEditor = function (props) {
    var children = props.children;
    var setHistory = usePreferencesEditor().setHistory;
    useEffect(function () {
        setHistory(history);
        return function () {
            setHistory(null);
        };
    }, [setHistory]);
    return (React.createElement(Router, { history: history },
        React.createElement(Route, { path: "/", render: function () { return children; } })));
};
