import {
  CreateParams,
  CreateResult,
  DataProvider,
  DeleteParams,
  DeleteResult,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  Identifier,
  UpdateParams,
  UpdateResult,
} from 'react-admin'
import { ADMINISTRATOR_URL } from '../../api-urls'
import { convertFileToBase64String } from '../../common/converters'
import {
  CacheableDataProviderExtensionResult,
  DataProviderExtensionResult,
} from '../../common/data-provider'
import {
  get,
  getWithPagination,
  patch,
  post,
  put,
  remove,
} from '../../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../../common/get-by-conditions.utils'
import { RadOnkAPIGetListParams } from '../../common/rad-onk-api-get-list.params'
import { RoleDto } from '../../dto/role/role.dto'
import { DoctorDto } from '../../dto/user/doctor.dto'
import { PatientDto } from '../../dto/user/patient.dto'
import { UserBaseDto } from '../../dto/user/user-base.dto'
import { WebUserDto } from '../../dto/user/web-user.dto'
import { UserTypes } from '../../enum/UserTypes'
import filterMapper from './users-filter.mapper'
import { mapSortUserParam } from './users-sort.mapper'

const provider = {
  getList: async (
    resource: string,
    { filter: { ...filter }, sort, pagination }: RadOnkAPIGetListParams,
  ): Promise<GetListResult<WebUserDto | DoctorDto | PatientDto>> => {
    const filterParams = `o=>${filterParamsComposer('o', filter, filterMapper)}`
    const pathParams = queryParamsComposer(sort, pagination, mapSortUserParam)
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<WebUserDto[] | DoctorDto[] | PatientDto[]>(
      `${ADMINISTRATOR_URL}/GetUsersByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<WebUserDto | DoctorDto | PatientDto>> => {
    const data = await get<WebUserDto | DoctorDto | PatientDto>(
      `${ADMINISTRATOR_URL}/GetUserById`,
      `/${id}`,
    )
    return Promise.resolve({
      data,
    })
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<UserBaseDto>> => {
    const data = await get<UserBaseDto[]>(
      `${ADMINISTRATOR_URL}/GetUsersByConditions`,
      `/u=>new int[] {${ids.toString()}}.Contains(u.Id)`,
    )
    return Promise.resolve({
      data,
    })
  },
  create: async (
    resource: string,
    { data }: CreateParams<CreateWebUserRequest>,
  ): Promise<CreateResult> => {
    let userCreateMethod = null
    switch ((data as any).userType) {
      case UserTypes.WEB_USER:
        userCreateMethod = 'PostWebUser'
        break
      case UserTypes.DOCTOR:
        userCreateMethod = 'PostDoctor'
        break
      case UserTypes.PATIENT:
        userCreateMethod = 'PostPatient'
        break
      default:
        break
    }

    const { userType, blobPhoto, ...restData } = data as any

    const created = await post<CreateWebUserRequest, UserBaseDto>(
      `${ADMINISTRATOR_URL}/${userCreateMethod}`,
      {
        ...restData,
        base64Photo:
          userType === UserTypes.DOCTOR && blobPhoto
            ? await convertFileToBase64String(blobPhoto?.rawFile)
            : undefined,
      },
    )
    return Promise.resolve({ data: created })
  },
  update: async (
    resource: string,
    { id, data, previousData }: UpdateParams<UserBaseDto>,
  ): Promise<UpdateResult> => {
    let userUpdateMethod = null
    switch (data.userType) {
      case UserTypes.WEB_USER:
        userUpdateMethod = 'PatchWebUser'
        break
      case UserTypes.DOCTOR:
        userUpdateMethod = 'PatchDoctor'
        break
      case UserTypes.PATIENT:
        userUpdateMethod = 'PatchPatient'
        break
      default:
        break
    }

    const update = await patch<
      { id: Identifier; [n: string]: any },
      UserBaseDto
    >(`${ADMINISTRATOR_URL}/${userUpdateMethod}`, {
      id,
      firstName:
        data.firstName !== previousData.firstName ? data.firstName : undefined,
      lastName:
        data.lastName !== previousData.lastName ? data.lastName : undefined,
      userName:
        data.userName !== previousData.userName ? data.userName : undefined,
      requirePasswordUpdate:
        data.requirePasswordUpdate !== previousData.requirePasswordUpdate
          ? data.requirePasswordUpdate
          : undefined,
      email:
        (data.userType === UserTypes.WEB_USER ||
          data.userType === UserTypes.DOCTOR) &&
        (data as WebUserDto).email !== previousData.email
          ? (data as WebUserDto).email
          : undefined,
      base64Photo:
        data.userType === UserTypes.DOCTOR && (data as any).blobPhoto
          ? await convertFileToBase64String((data as any)?.blobPhoto?.rawFile)
          : undefined,
      pesel:
        data.userType === UserTypes.PATIENT &&
        (data as PatientDto).pesel !== previousData.pesel
          ? (data as PatientDto).pesel
          : undefined,
      mainDoctorId:
        data.userType === UserTypes.PATIENT &&
        (data as PatientDto).mainDoctorId !== previousData.mainDoctorId
          ? (data as PatientDto).mainDoctorId
          : undefined,
      temporaryDoctorId:
        data.userType === UserTypes.PATIENT &&
        (data as PatientDto).temporaryDoctorId !==
          previousData.temporaryDoctorId
          ? (data as PatientDto).temporaryDoctorId
          : undefined,
      isRadiotherapyModuleAvailable:
        data.userType === UserTypes.PATIENT &&
        (data as PatientDto).isRadiotherapyModuleAvailable !==
          previousData.isRadiotherapyModuleAvailable
          ? (data as PatientDto).isRadiotherapyModuleAvailable
          : undefined,
      isChemotherapyModuleAvailable:
        data.userType === UserTypes.PATIENT &&
        (data as PatientDto).isChemotherapyModuleAvailable !==
          previousData.isChemotherapyModuleAvailable
          ? (data as PatientDto).isChemotherapyModuleAvailable
          : undefined,
      isRadiochemotherapyModuleAvailable:
        data.userType === UserTypes.PATIENT &&
        (data as PatientDto).isRadiochemotherapyModuleAvailable !==
          previousData.isRadiochemotherapyModuleAvailable
          ? (data as PatientDto).isRadiochemotherapyModuleAvailable
          : undefined,
      isBrachytherapyModuleAvailable:
        data.userType === UserTypes.PATIENT &&
        (data as PatientDto).isBrachytherapyModuleAvailable !==
          previousData.isBrachytherapyModuleAvailable
          ? (data as PatientDto).isBrachytherapyModuleAvailable
          : undefined,
      tag:
        data.userType === UserTypes.PATIENT &&
        (data as PatientDto).tag !== previousData.tag
          ? (data as PatientDto).tag
          : undefined,
      // active:
      //   data.userType === UserTypes.PATIENT &&
      //   (data as PatientDto).active !== previousData.active
      //     ? (data as PatientDto).active
      //     : undefined,
      diagnosis:
        data.userType === UserTypes.PATIENT &&
        (data as PatientDto).diagnosis !== previousData.diagnosis
          ? (data as PatientDto).diagnosis
          : undefined,
      patientRadiotherapySurveyInterval:
        data.userType === UserTypes.PATIENT &&
        (data as PatientDto).patientRadiotherapySurveyInterval !==
          previousData.patientRadiotherapySurveyInterval
          ? (data as PatientDto).patientRadiotherapySurveyInterval
          : undefined,
      patientChemotherapySurveyInterval:
        data.userType === UserTypes.PATIENT &&
        (data as PatientDto).patientChemotherapySurveyInterval !==
          previousData.patientChemotherapySurveyInterval
          ? (data as PatientDto).patientChemotherapySurveyInterval
          : undefined,
    })
    return Promise.resolve({ data: update })
  },
  delete: async (
    resource: string,
    { id }: DeleteParams,
  ): Promise<DeleteResult> => {
    const data = await remove<UserIdResponse>(
      `${ADMINISTRATOR_URL}/DeleteUser`,
      `/${id}`,
    )
    return {
      data: {
        id: data.userId,
      },
    }
  },
  changeUserPassword: async (
    resource: string,
    params: ChangeUserPasswordRequest,
  ): Promise<DataProviderExtensionResult<ChangeUserPasswordResponse>> => {
    const newPassword = await put<
      ChangeUserPasswordRequest,
      ChangeUserPasswordResponse
    >(`${ADMINISTRATOR_URL}/ChangeUserPassword`, params)
    return {
      data: newPassword,
    }
  },
  generateResetUserPasswordToken: async (
    resource: string,
    params: GenerateResetUserPasswordTokenRequest,
  ): Promise<
    DataProviderExtensionResult<GenerateResetUserPasswordTokenResponse>
  > => {
    const token = await put<
      GenerateResetUserPasswordTokenRequest,
      GenerateResetUserPasswordTokenResponse
    >(`${ADMINISTRATOR_URL}/GenerateResetUserPasswordToken`, params)
    return {
      data: token,
    }
  },
  attachRoles: async (
    resource: string,
    params: UserRoles,
  ): Promise<DataProviderExtensionResult<UserRoles>> => {
    const token = await put<UserRoles, UserRoles>(
      `${ADMINISTRATOR_URL}/AttachRolesToUser`,
      params,
    )
    return {
      data: token,
    }
  },
  detachRoles: async (
    resource: string,
    params: UserRoles,
  ): Promise<DataProviderExtensionResult<UserRoles>> => {
    const token = await put<UserRoles, UserRoles>(
      `${ADMINISTRATOR_URL}/DetachRolesFromUser`,
      params,
    )
    return {
      data: token,
    }
  },
  getRoles: async (
    resource: string,
    { userId }: GetRolesParams,
  ): Promise<DataProviderExtensionResult<RoleDto[]>> => {
    const token = await get<RoleDto[]>(
      `${ADMINISTRATOR_URL}/GetUserRoles/`,
      userId as string,
    )
    return {
      data: token,
    }
  },
  attachPatientsToDoctor: async (
    resource: string,
    params: DoctorPatients,
  ): Promise<DataProviderExtensionResult<DoctorPatients>> => {
    const token = await put<DoctorPatients, DoctorPatients>(
      `${ADMINISTRATOR_URL}/AttachPatiensToDoctor`,
      params,
    )
    return {
      data: token,
    }
  },
  detachPatientsFromDoctor: async (
    resource: string,
    params: DoctorPatients,
  ): Promise<DataProviderExtensionResult<DoctorPatients>> => {
    const token = await put<DoctorPatients, DoctorPatients>(
      `${ADMINISTRATOR_URL}/DetachPatiensFromDoctor`,
      params,
    )
    return {
      data: token,
    }
  },
  attachDoctorsToPatient: async (
    resource: string,
    params: PatientDoctors,
  ): Promise<DataProviderExtensionResult<PatientDoctors>> => {
    const token = await put<PatientDoctors, PatientDoctors>(
      `${ADMINISTRATOR_URL}/AttachDoctorsToPatient`,
      params,
    )
    return {
      data: token,
    }
  },
  detachDoctorsFromPatient: async (
    resource: string,
    params: PatientDoctors,
  ): Promise<DataProviderExtensionResult<PatientDoctors>> => {
    const token = await put<PatientDoctors, PatientDoctors>(
      `${ADMINISTRATOR_URL}/DetachDoctorsFromPatient`,
      params,
    )
    return {
      data: token,
    }
  },
  markPatientRadiotherapyAsDeactivated: async (
    resource: string,
    params: MarkPatientRadiotherapyAsDeactivatedParams,
  ): Promise<DataProviderExtensionResult<undefined>> => {
    const marked = await put<undefined, undefined>(
      `${ADMINISTRATOR_URL}/MarkPatientRadiotherapyAsDeactivated/${params.patientId}`,
      undefined,
    )
    return {
      data: marked,
    }
  },
  markPatientChemotherapyAsDeactivated: async (
    resource: string,
    params: MarkPatientChemotherapyAsDeactivatedParams,
  ): Promise<DataProviderExtensionResult<undefined>> => {
    const marked = await put<undefined, undefined>(
      `${ADMINISTRATOR_URL}/MarkPatientChemotherapyAsDeactivated/${params.patientId}`,
      undefined,
    )
    return {
      data: marked,
    }
  },
  deactivatePatient: async (
    resource: string,
    params: DeactivatePatientParams,
  ): Promise<DataProviderExtensionResult<undefined>> => {
    const marked = await put<undefined, undefined>(
      `${ADMINISTRATOR_URL}/DeactivatePatient/${params.patientId}`,
      undefined,
    )
    return {
      data: marked,
    }
  },
} as UserDataProvider

interface UserDataProvider extends DataProvider {
  changeUserPassword: (
    resource: string,
    params: ChangeUserPasswordRequest,
  ) => Promise<CacheableDataProviderExtensionResult<ChangeUserPasswordResponse>>

  generateResetUserPasswordToken: (
    resource: string,
    params: GenerateResetUserPasswordTokenRequest,
  ) => Promise<
    CacheableDataProviderExtensionResult<GenerateResetUserPasswordTokenResponse>
  >

  attachRoles: (
    resource: string,
    params: UserRoles,
  ) => Promise<CacheableDataProviderExtensionResult<UserRoles>>

  detachRoles: (
    resource: string,
    params: UserRoles,
  ) => Promise<CacheableDataProviderExtensionResult<UserRoles>>

  getRoles: (
    resource: string,
    params: GetRolesParams,
  ) => Promise<CacheableDataProviderExtensionResult<RoleDto[]>>

  attachPatientsToDoctor: (
    resource: string,
    params: DoctorPatients,
  ) => Promise<CacheableDataProviderExtensionResult<DoctorPatients>>

  detachPatientsFromDoctor: (
    resource: string,
    params: DoctorPatients,
  ) => Promise<CacheableDataProviderExtensionResult<DoctorPatients>>

  attachDoctorsToPatient: (
    resource: string,
    params: PatientDoctors,
  ) => Promise<CacheableDataProviderExtensionResult<PatientDoctors>>

  detachDoctorsFromPatient: (
    resource: string,
    params: PatientDoctors,
  ) => Promise<CacheableDataProviderExtensionResult<PatientDoctors>>

  markPatientRadiotherapyAsDeactivated: (
    resource: string,
    params: MarkPatientRadiotherapyAsDeactivatedParams,
  ) => Promise<DataProviderExtensionResult<undefined>>

  markPatientChemotherapyAsDeactivated: (
    resource: string,
    params: MarkPatientChemotherapyAsDeactivatedParams,
  ) => Promise<DataProviderExtensionResult<undefined>>

  deactivatePatient: (
    resource: string,
    params: DeactivatePatientParams,
  ) => Promise<DataProviderExtensionResult<undefined>>
}

export interface CreateWebUserRequest {
  readonly firstName: string
  readonly lastName: string
  readonly userName: string
  readonly password: string
  readonly companyId: number
  readonly requirePasswordUpdate: boolean
  readonly email: string
}

export interface CreateDoctorRequest extends CreateWebUserRequest {
  readonly firstName: string
  readonly lastName: string
  readonly base64Photo: string
}

export interface CreatePatientRequest {
  readonly firstName: string
  readonly lastName: string
  readonly userName: string
  readonly password: string
  readonly pesel: string
  readonly diagnosis: string
}

interface ChangeUserPasswordRequest {
  readonly userId: number
  readonly newPassword: string
}

interface ChangeUserPasswordResponse {
  readonly password: string
  readonly id: number
  readonly userName: string
}

interface GenerateResetUserPasswordTokenRequest {
  readonly userId: number
}

interface GenerateResetUserPasswordTokenResponse {
  readonly userId: number
  readonly token: string
}

interface UserRoles {
  readonly userId: number
  readonly rolesIds: number[]
}

interface GetRolesParams {
  userId: Identifier
}

interface DoctorPatients {
  readonly doctorId: number
  readonly patientIds: number[]
}

interface PatientDoctors {
  readonly patientId: number
  readonly doctorIds: number[]
}

interface UserIdResponse {
  readonly userId: string
}

interface MarkPatientRadiotherapyAsDeactivatedParams {
  readonly patientId: number
}

interface MarkPatientChemotherapyAsDeactivatedParams {
  readonly patientId: number
}

interface DeactivatePatientParams {
  readonly patientId: number
}

export default provider
