import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  DateField,
  DateTimeInput,
  Edit,
  EditProps,
  FunctionField,
  ReferenceField,
  SaveButton,
  ShowButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  ToolbarProps,
  useEditContext,
  useTranslate,
} from 'react-admin'
import { Typography } from '@material-ui/core'
import { RadiotherapySchedulerDTO } from '../../core/dto/scheduler/radiotherapy-scheduler.dto'
import { SchedulerBaseDto } from '../../core/dto/scheduler/scheduler-base.dto'
import { SchedulerTypes } from '../../core/enum/SchedulerTypes'
import { ResourceName } from '../../core/ResourceName'
import { PatientDto } from '../../core/dto/user/patient.dto'
import { RadiotherapyMachineDto } from '../../core/dto/radiotherapy-machine.dto'

export const validateSchedulerEdit = (values: SchedulerBaseDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.patientId) {
    errors.patientId = 'ra.validation.required'
  }
  if (!values.date) {
    errors.date = 'ra.validation.required'
  }
  return errors
}

const EditTitle = () => {
  const translate = useTranslate()
  return <span>{translate('resources.schedulers.titles.edit')}</span>
}

const EditToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ShowButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

const SchedulerEditForm = ({ ...props }) => {
  const { record: scheduler } = useEditContext()
  const translate = useTranslate()

  return (
    <SimpleForm
      {...props}
      redirect="show"
      validate={validateSchedulerEdit}
      toolbar={<EditToolbar />}
    >
      <TextField source="id" label="ID" />
      <ReferenceField
        source="patientId"
        reference={ResourceName.PATIENTS}
        link={false}
        emptyText="-"
        sortable={false}
      >
        <FunctionField<PatientDto>
          source="patientId"
          render={(record?: PatientDto) => (
            <Typography variant="body2">
              {record?.firstName} {record?.lastName}
            </Typography>
          )}
        />
      </ReferenceField>
      {scheduler?.schedulerType === SchedulerTypes.RADIOTHERAPY ? (
        <DateField
          source="date"
          showTime
          options={{
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          }}
          emptyText="-"
        />
      ) : (
        <DateTimeInput
          source="date"
          parse={(value: string) => (value === '' ? null : value)}
          isRequired
        />
      )}
      <FunctionField<SchedulerBaseDto>
        source="schedulerType"
        sortable={false}
        render={(record?: SchedulerBaseDto) => (
          <div>
            {translate(
              `resources.enums.schedulerTypes.${record?.schedulerType}`,
            )}
          </div>
        )}
      />
      {(scheduler?.schedulerType === SchedulerTypes.EXAMINATION ||
        scheduler?.schedulerType === SchedulerTypes.RECOMMENDATION) && (
        <TextInput source="description" multiline />
      )}
      {scheduler?.schedulerType === SchedulerTypes.RADIOTHERAPY && (
        <ReferenceField
          source="radiotherapyMachineId"
          reference={ResourceName.PATIENTS}
          link={false}
          emptyText="-"
          sortable={false}
        >
          <FunctionField<RadiotherapyMachineDto>
            source="radiotherapyMachineId"
            render={(record?: RadiotherapyMachineDto) => (
              <Typography variant="body2">{record?.name}</Typography>
            )}
          />
        </ReferenceField>
      )}
      {scheduler?.schedulerType === SchedulerTypes.RADIOTHERAPY && (
        <TextField source="activityName" />
      )}
      {scheduler?.schedulerType === SchedulerTypes.RADIOTHERAPY && (
        <FunctionField<SchedulerBaseDto>
          source="appointmentStatus"
          render={(record?: RadiotherapySchedulerDTO) => (
            <div>
              {translate(
                `resources.enums.radiotherapyAppointmentStatuses.${record?.appointmentStatus}`,
              )}
            </div>
          )}
        />
      )}
    </SimpleForm>
  )
}

export const SchedulerEdit = (props: EditProps) => (
  <Edit {...props} title={<EditTitle />} actions={false}>
    <SchedulerEditForm />
  </Edit>
)
