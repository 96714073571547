import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core'
import React from 'react'
import {
  sanitizeFetchType,
  useMutation,
  useNotify,
  useTranslate,
} from 'ra-core'
import { Close, Cancel } from '@material-ui/icons'
import MessageIcon from '@material-ui/icons/MessageOutlined'
import { SaveButton, Toolbar } from 'react-admin'
import DraggableComponent from '../../common/DraggableComponent'
import Button from '../../common/customized-mui-components/Button'
import { SendBulkAdministrativeMessageForm } from './SendBulkAdministrativeMessageForm'
import { ResourceName } from '../../../core/ResourceName'

const useStyles = makeStyles(() =>
  createStyles({
    bulkStateActions: {
      height: '16px',
      background: 'rgba(0,0,0,0.2)',
      minWidth: '300px',
      userSelect: 'none',
    },
    bulkProgressTitle: {
      marginLeft: '10px',
      marginRight: 'auto',
    },
    bulkProgressCloseButton: {
      margin: 0,
    },
    toolbar: {
      gap: 0,
      display: 'flex',
      alignItems: 'center',
      margin: 0,
      backgroundColor: 'transparent',
      flex: '0 0 auto',
      padding: '8px',
      justifyContent: 'flex-end',
    },
    toolbarButton: {
      margin: '2%',
      width: '96%',
    },
  }),
)

const SendBulkAdministrativeMessageToolbar = ({ ...props }) => {
  const { onClose } = props
  const notify = useNotify()
  const classes = useStyles()
  const [mutate] = useMutation()
  const translate = useTranslate()

  const handleOnSave = ({ ...params }) => {
    mutate(
      {
        type: sanitizeFetchType('sendAdministrativeMessageToManyPatients'),
        resource: ResourceName.CURRENT_USER,
        payload: { data: params },
      },
      {
        returnPromise: true,
        onSuccess: () => {
          notify('ra.notification.created', 'success')
          onClose()
        },
      },
    )
  }

  return (
    <Toolbar {...props} className={classes.toolbar}>
      <SaveButton
        size="small"
        className={classes.toolbarButton}
        label="ra.action.send"
        onSave={handleOnSave}
        icon={<MessageIcon />}
      />
      <Button
        variant="contained"
        onClick={onClose}
        label={translate('const.cancel')}
        useSmallVersionBreakpoint={false}
        className={classes.toolbarButton}
      >
        <Cancel />
      </Button>
    </Toolbar>
  )
}

export const SendBulkAdministrativeMessageDialog = ({
  open,
  close,
}: {
  open: boolean
  close: () => void
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  const handleClose = () => {
    close()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick
      disableEscapeKeyDown
      PaperComponent={DraggableComponent}
      aria-labelledby="draggable-dialog-title"
      maxWidth="lg"
    >
      <DialogActions
        className={classes.bulkStateActions}
        style={{ cursor: 'move' }}
        id="draggable-dialog-title"
      >
        <Typography className={classes.bulkProgressTitle}>
          {translate(
            'resources.patients.dialogs.sendBulkAdministrativeMessage.title',
          )}
        </Typography>
        <IconButton
          size="small"
          className={classes.bulkProgressCloseButton}
          onClick={handleClose}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogActions>
      <DialogContent>
        <SendBulkAdministrativeMessageForm
          toolbar={
            <SendBulkAdministrativeMessageToolbar onClose={handleClose} />
          }
        />
      </DialogContent>
    </Dialog>
  )
}
