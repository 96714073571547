import { useMediaQuery, useTheme } from '@material-ui/core'
import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  DateField,
  Edit,
  EditProps,
  SaveButton,
  ShowButton,
  SimpleForm,
  TextField,
  Toolbar,
  ToolbarProps,
  useTranslate,
} from 'react-admin'
import { RadiotherapyAilmentSurveyDTO } from '../../core/dto/radiotherapy-ailment-survey/radiotherapy-ailment-survey.dto'
import { ResourceName } from '../../core/ResourceName'
import FilteredReferenceInput from '../common/FilteredReferenceInput'
import { PatientDto } from '../../core/dto/user/patient.dto'

export const validateRadiotherapyAilmentSurveyEdit = (
  values: RadiotherapyAilmentSurveyDTO,
) => {
  const errors: { [n: string]: string } = {}
  if (!values.patientId) {
    errors.patientId = 'ra.validation.required'
  }
  if (!values.date) {
    errors.date = 'ra.validation.required'
  }
  return errors
}

const EditTitle = () => {
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.radiotherapy-ailment-surveys.titles.edit')}
    </span>
  )
}

const EditToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ShowButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

const RadiotherapyAilmentSurveyEditForm = ({ ...props }) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <SimpleForm
      {...props}
      redirect="show"
      validate={validateRadiotherapyAilmentSurveyEdit}
      toolbar={<EditToolbar />}
    >
      <TextField source="id" label="ID" />
      <FilteredReferenceInput
        source="patientId"
        defaultValue={null}
        reference={ResourceName.PATIENTS}
        sort={{ field: 'lastName', order: 'ASC' }}
        perPage={smallScreen ? 5 : 15}
        allowEmpty
        filterSource="fullNameSearch"
        filter={{ patientsOnly: true, active: true }}
        emptyText="-"
        selectWithPaginationInputProps={{
          optionText: (record: PatientDto) =>
            `${record.firstName} ${record.lastName} (${record.pesel})`,
          showFilter: true,
        }}
      />
      <DateField
        source="date"
        showTime
        options={{
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        }}
        emptyText="-"
      />
    </SimpleForm>
  )
}

export const RadiotherapyAilmentSurveyEdit = (props: EditProps) => (
  <Edit {...props} title={<EditTitle />} actions={false}>
    <RadiotherapyAilmentSurveyEditForm />
  </Edit>
)
