import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  makeStyles,
  TextField,
  Theme,
  ThemeProvider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import PrintIcon from '@material-ui/icons/Print'
import React, { useRef, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useHistory, useLocation } from 'react-router'
import { useReactToPrint } from 'react-to-print'
import Button from '../../../common/customized-mui-components/Button'
import { ResourceName } from '../../../../core/ResourceName'
import { CreatePatientRequest } from '../../../../core/administration/users/users.provider'
import { extendedLightTheme } from '../../../layout/app.theme'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogModal: {
      '& .MuiDialog-paper': {
        width: '100%',
        height: '100%',
      },
    },
    bulkStateActions: {
      height: '16px',
      background: 'rgba(0,0,0,0.2)',
      minWidth: '300px',
      userSelect: 'none',
    },
    content: {
      background: 'white',
      height: '100%',
    },
    printContent: {
      '& .MuiTextField-root > label': {
        color: 'black',
      },
      '& .MuiInputBase-input': {
        color: 'black',
      },
      display: 'grid',
      padding: theme.spacing(5),
    },
    bulkProgressTitle: {
      marginLeft: '10px',
      marginRight: 'auto',
    },
    bulkProgressCloseButton: {
      margin: 0,
    },
    actionsButton: {
      margin: '2%',
      width: '96%',
    },
  }),
)

const PrintPatientDataModal = () => {
  const { state, search } = useLocation()
  const contentToPrintRef = useRef<HTMLDivElement>(null)
  const classes = useStyles()
  const history = useHistory()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [open, setOpen] = useState(true)
  const translate = useTranslate()
  const patientIdRaw = new URLSearchParams(search).get('id')
  const patientId: number | undefined =
    patientIdRaw !== null ? Number(patientIdRaw) : undefined

  const onClose = async () => {
    history.push(
      `/${ResourceName.PATIENTS}${patientId ? `/${patientId}/show` : ''}`,
    )
  }

  const handlePrint = useReactToPrint({
    content: () => contentToPrintRef.current,
    documentTitle: translate(
      'resources.patients.dialogs.printPatientData.fileName',
    ),
  })

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false)
      }}
      maxWidth="xl"
      fullScreen={smallScreen}
      disableBackdropClick
      disableEscapeKeyDown
      className={classes.dialogModal}
    >
      <DialogActions
        className={classes.bulkStateActions}
        id="draggable-dialog-title"
      >
        <Typography className={classes.bulkProgressTitle}>
          {translate('resources.patients.dialogs.printPatientData.title')}
        </Typography>
        <IconButton
          size="small"
          className={classes.bulkProgressCloseButton}
          onClick={onClose}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogActions>
      <DialogContent className={classes.content}>
        <>
          <ThemeProvider theme={extendedLightTheme}>
            <div ref={contentToPrintRef} className={classes.printContent}>
              <TextField
                label={translate('resources.patients.fields.firstName')}
                defaultValue={(state as CreatePatientRequest)?.firstName}
                InputProps={{ readOnly: true }}
              />
              <TextField
                label={translate('resources.patients.fields.lastName')}
                defaultValue={(state as CreatePatientRequest)?.lastName}
                InputProps={{ readOnly: true }}
              />
              <TextField
                label={translate('resources.patients.fields.userName')}
                defaultValue={(state as CreatePatientRequest)?.userName}
                InputProps={{ readOnly: true }}
              />
              <TextField
                label={translate('resources.patients.fields.password')}
                defaultValue={(state as CreatePatientRequest)?.password}
                InputProps={{ readOnly: true }}
              />
            </div>
          </ThemeProvider>
        </>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handlePrint}
          label={translate('resources.patients.dialogs.printPatientData.print')}
          useSmallVersionBreakpoint={false}
          className={classes.actionsButton}
        >
          <PrintIcon />
        </Button>
        <Button
          variant="contained"
          onClick={onClose}
          label={translate('const.close')}
          useSmallVersionBreakpoint={false}
          className={classes.actionsButton}
        />
      </DialogActions>
    </Dialog>
  )
}

export default PrintPatientDataModal
