import { createStyles, fade, makeStyles, Theme } from '@material-ui/core'
import React, { useState } from 'react'
import { useTranslate, useShowContext } from 'react-admin'
import { Authority } from '../../../../core/auth/Authority'
import Button from '../../../common/customized-mui-components/Button'
import { useHasAuthority } from '../../../hooks/useHasAuthority'
import { DeactivatePatientDialog } from '../UserDialogs'
import { PatientDto } from '../../../../core/dto/user/patient.dto'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: '2px',
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      '&:hover': {
        backgroundColor: fade(theme.palette.error.main, 0.62),
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
  }),
)

const DeactivatePatientButton = () => {
  const { loading } = useShowContext()
  const [dialogOpen, setDialogOpen] = useState(false)
  const translate = useTranslate()
  const classes = useStyles()
  const hasAuthority = useHasAuthority()
  const { record: patient } = useShowContext<PatientDto>()

  return (
    <>
      <DeactivatePatientDialog
        open={dialogOpen}
        close={() => setDialogOpen(false)}
      />
      <Button
        className={classes.button}
        variant="contained"
        label={translate('resources.users.actions.deactivatePatient')}
        disabled={
          (!hasAuthority(Authority.EDIT_USERS) &&
            !hasAuthority(Authority.EDIT_PATIENTS)) ||
          patient?.active !== true ||
          loading
        }
        onClick={() => setDialogOpen(true)}
      />
    </>
  )
}

export default DeactivatePatientButton
