import { Box, Divider } from '@material-ui/core'
import React from 'react'
import {
  BooleanInput,
  Datagrid,
  EditButton,
  ImageField,
  List,
  Pagination,
  ReferenceField,
  ResourceContextProvider,
  SearchInput,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  TitleProps,
  useShowContext,
  useTranslate,
} from 'react-admin'
import { Authority } from '../../core/auth/Authority'
import { UserBaseDto } from '../../core/dto/user/user-base.dto'
import { ResourceName } from '../../core/ResourceName'
import { DeleteButtonWithConfirm } from '../common/DeleteButtonWithConfirm'
import RelationTab from '../common/RelationTab'
import { useHasAuthority } from '../hooks/useHasAuthority'
import { DoctorDto } from '../../core/dto/user/doctor.dto'
import ChangePasswordButton from '../administration/users/UserShow/UserChangePasswordButton'

interface DoctorTitleProps extends TitleProps {
  record?: UserBaseDto
}

const ShowTitle = (data: DoctorTitleProps) => {
  const { record: doctor } = data
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.doctors.titles.show')}: {doctor?.firstName}{' '}
      {doctor?.lastName}
    </span>
  )
}

const DoctorPatients = ({ ...props }) => {
  const { record: user } = props
  const hasAuthority = useHasAuthority()

  const patientsFilters = () => [
    <SearchInput source="fullNameSearch" alwaysOn />,
    <BooleanInput
      label="common.related-records.actions.attached-only"
      source="filterPatientDoctorId"
      alwaysOn
    />,
  ]

  return (
    <RelationTab<UserBaseDto>
      resource={ResourceName.PATIENTS}
      source="patientIds"
      mode={hasAuthority(Authority.EDIT_DOCTORS) ? 'edit' : 'show'}
      attachMethod="attachPatientsToDoctor"
      detachMethod="detachPatientsFromDoctor"
      attachRequestPayload={(u, ids) => ({
        doctorId: u.id,
        patientIds: ids,
      })}
      detachRequestPayload={(r, ids) => ({
        doctorId: r.id,
        patientIds: ids,
      })}
      refetchListAfterChange={(filters) =>
        filters?.filterPatientDoctorId === true
      }
      filters={patientsFilters()}
      filterDefaultValues={{
        patientDoctorId: user?.id,
        filterPatientDoctorId: true,
        patientsOnly: true,
        active: true,
      }}
      sort={{
        field: 'id',
        order: 'DESC',
      }}
    >
      <TextField source="id" label="ID" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="pesel" />
      <TextField source="userName" />
    </RelationTab>
  )
}

const MainPatients = () => {
  const { record: user, basePath } = useShowContext()

  const mainPatientsFilters = () => [
    <SearchInput source="fullNameSearch" alwaysOn />,
  ]

  return (
    <ResourceContextProvider value={ResourceName.PATIENTS}>
      <List
        basePath={basePath}
        exporter={false}
        title=" "
        filters={mainPatientsFilters()}
        filterDefaultValues={{
          patientsOnly: true,
          active: true,
          filterPatientMainDoctorId: true,
          patientMainDoctorId: user?.id,
        }}
        sort={{
          field: 'id',
          order: 'DESC',
        }}
        pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100]} />}
        perPage={20}
        actions={<></>}
      >
        <>
          <Datagrid size="small" style={{ tableLayout: 'fixed' }}>
            <TextField source="id" label="ID" />
            <ReferenceField
              source="id"
              sortBy="firstName"
              label="resources.users.fields.firstName"
              reference={ResourceName.PATIENTS}
              link={(record) => `/${ResourceName.PATIENTS}/${record.id}/show`}
            >
              <TextField source="firstName" />
            </ReferenceField>
            <ReferenceField
              source="id"
              sortBy="lastName"
              label="resources.users.fields.lastName"
              reference={ResourceName.PATIENTS}
              link={(record) => `/${ResourceName.PATIENTS}/${record.id}/show`}
            >
              <TextField source="lastName" />
            </ReferenceField>
            <TextField source="pesel" />
            <TextField source="userName" />
          </Datagrid>
        </>
      </List>
    </ResourceContextProvider>
  )
}

const TemporaryPatients = () => {
  const { record: user, basePath } = useShowContext()

  const temporaryPatientsFilters = () => [
    <SearchInput source="fullNameSearch" alwaysOn />,
  ]

  return (
    <ResourceContextProvider value={ResourceName.PATIENTS}>
      <List
        basePath={basePath}
        exporter={false}
        title=" "
        filters={temporaryPatientsFilters()}
        filterDefaultValues={{
          patientsOnly: true,
          active: true,
          filterPatientTemporaryDoctorId: true,
          patientTemporaryDoctorId: user?.id,
        }}
        sort={{
          field: 'id',
          order: 'DESC',
        }}
        pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100]} />}
        perPage={20}
        actions={<></>}
      >
        <>
          <Datagrid size="small" style={{ tableLayout: 'fixed' }}>
            <TextField source="id" label="ID" />
            <ReferenceField
              source="id"
              sortBy="firstName"
              label="resources.users.fields.firstName"
              reference={ResourceName.PATIENTS}
              link={(record) => `/${ResourceName.PATIENTS}/${record.id}/show`}
            >
              <TextField source="firstName" />
            </ReferenceField>
            <ReferenceField
              source="id"
              sortBy="lastName"
              label="resources.users.fields.lastName"
              reference={ResourceName.PATIENTS}
              link={(record) => `/${ResourceName.PATIENTS}/${record.id}/show`}
            >
              <TextField source="lastName" />
            </ReferenceField>
            <TextField source="pesel" />
            <TextField source="userName" />
          </Datagrid>
        </>
      </List>
    </ResourceContextProvider>
  )
}

const GeneralActions = ({ ...props }) => {
  const { record: doctor } = useShowContext<DoctorDto>()
  const hasAuthority = useHasAuthority()

  return (
    <>
      <Divider />
      <Box p={1} display="block" textAlign="right">
        <ChangePasswordButton />
        <EditButton
          basePath={`/${ResourceName.DOCTORS}`}
          record={doctor}
          variant="contained"
          style={{ margin: '2px' }}
          disabled={!hasAuthority(Authority.EDIT_DOCTORS)}
        />
        <DeleteButtonWithConfirm
          {...props}
          disabled={!hasAuthority(Authority.EDIT_DOCTORS)}
        />
      </Box>
    </>
  )
}

const DoctorTabs = ({ ...props }) => {
  const hasAuthority = useHasAuthority()

  return (
    <TabbedShowLayout {...props}>
      <Tab label="resources.doctors.tabs.general">
        <TextField source="id" label="ID" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="userName" />
        <TextField source="email" emptyText="-" />
        <ImageField source="base64Photo" />
        {/* <FunctionField<UserBaseDto>
          source="requirePasswordUpdate"
          render={(record?: UserBaseDto) => (
            <div>
              {record?.requirePasswordUpdate
                ? translate('const.yes')
                : translate('const.no')}
            </div>
          )}
        /> */}
        <GeneralActions />
      </Tab>
      {hasAuthority(Authority.VIEW_PATIENTS) && (
        <Tab label="resources.doctors.tabs.patients" path="patients">
          <DoctorPatients />
        </Tab>
      )}
      {hasAuthority(Authority.VIEW_PATIENTS) && (
        <Tab label="resources.doctors.tabs.mainPatients" path="mainPatients">
          <MainPatients />
        </Tab>
      )}
      {hasAuthority(Authority.VIEW_PATIENTS) && (
        <Tab
          label="resources.doctors.tabs.temporaryPatients"
          path="temporaryPatients"
        >
          <TemporaryPatients />
        </Tab>
      )}
    </TabbedShowLayout>
  )
}

export const DoctorShow = (props: ShowProps) => (
  <Show {...props} actions={false} title={<ShowTitle />}>
    <DoctorTabs />
  </Show>
)
