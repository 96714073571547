export enum Authority {
  // User management
  VIEW_USER_MANAGEMENT,

  // Users
  VIEW_USERS,
  EDIT_USERS,
  CREATE_USERS,

  // Roles
  VIEW_ROLES,
  EDIT_ROLES,
  CREATE_ROLES,

  // Doctors
  VIEW_DOCTORS,
  EDIT_DOCTORS,
  CREATE_DOCTORS,

  // Patients
  VIEW_PATIENTS,
  EDIT_PATIENTS,
  CREATE_PATIENTS,

  // Radiotherapy ailments
  VIEW_RADIOTHERAPY_AILMENTS,
  EDIT_RADIOTHERAPY_AILMENTS,
  CREATE_RADIOTHERAPY_AILMENTS,

  // Radiotherapy ailment surveys
  VIEW_RADIOTHERAPY_AILMENT_SURVEYS,
  EDIT_RADIOTHERAPY_AILMENT_SURVEYS,
  CREATE_RADIOTHERAPY_AILMENT_SURVEYS,

  // Chemotherapy ailments
  VIEW_CHEMOTHERAPY_AILMENTS,
  EDIT_CHEMOTHERAPY_AILMENTS,
  CREATE_CHEMOTHERAPY_AILMENTS,

  // Chemotherapy ailment surveys
  VIEW_CHEMOTHERAPY_AILMENT_SURVEYS,
  EDIT_CHEMOTHERAPY_AILMENT_SURVEYS,
  CREATE_CHEMOTHERAPY_AILMENT_SURVEYS,

  // Schedulers
  VIEW_SCHEDULERS,
  EDIT_SCHEDULERS,
  CREATE_SCHEDULERS,

  // Radiotherapy machines
  VIEW_RADIOTHERAPY_MACHINES,

  // Vacation calendars
  VIEW_VACATION_CALENDARS,
  EDIT_VACATION_CALENDARS,
  CREATE_VACATION_CALENDARS,

  // Custom user type roles
  WEB_USER,
}
