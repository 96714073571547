import { makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { ImageInput, ImageInputProps, useRecordContext } from 'react-admin'
import { convertFileToBase64String } from '../../core/common/converters'

const useStyles = makeStyles({
  image: {
    margin: '0.5rem',
    maxHeight: '10rem',
  },
})

export type Base64ImageInputProps = {
  outputSource: string
} & ImageInputProps

const Base64ImageInput = (props: Base64ImageInputProps) => {
  const { source, outputSource } = props
  const classes = useStyles()
  const record = useRecordContext()
  const [imagePreview, setImagePreview] = useState<string>(record[source])
  const onPhotoChange = async (e: any) => {
    setImagePreview(await convertFileToBase64String(e))
  }

  return (
    <ImageInput
      {...props}
      source={outputSource}
      accept="image/*"
      placeholder={
        imagePreview ? (
          <img src={imagePreview} alt="imageInput" className={classes.image} />
        ) : undefined
      }
      onChange={(e) => onPhotoChange(e)}
    >
      <></>
    </ImageInput>
  )
}

export default Base64ImageInput
