export type DateTime =
  `${number}-${number}-${number}T${number}:${number}:${number}.${number}` // 2021-07-13T15:11:06.771267

export const formatDate = (value: string | undefined) => {
  if (value == null || value === '') {
    return ''
  }
  return value.includes('T') ? value.substring(0, 10) : value
}

export const formatTime = (value: string | undefined) => {
  if (value == null || value === '') {
    return ''
  }
  return value.includes('T') ? value.substring(11, 16) : value
}

export const ignoreTimezoneOffset = (value: Date) => {
  const tzoffset = value.getTimezoneOffset() * 60000 //offset in milliseconds
  return new Date(value.getTime() - tzoffset)
}

export const getCurrentDateTime = () =>
  ignoreTimezoneOffset(new Date(Date.now()))
    .toJSON()
    .substring(0, 23) as DateTime

export const convertToDateTime = (date: Date) =>
  ignoreTimezoneOffset(date).toJSON().substring(0, 23) as DateTime

export const getFullMonthRangeFromDate = (date: Date) => {
  const dateFrom = new Date(date.getFullYear(), date.getMonth())
  dateFrom.setDate(dateFrom.getDate())
  const dateTo = new Date(dateFrom)
  dateTo.setDate(dateTo.getDate() + 31)
  return {
    dateFrom: ignoreTimezoneOffset(dateFrom),
    dateTo: ignoreTimezoneOffset(dateTo),
  }
}
