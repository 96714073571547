import { DataProvider, sanitizeFetchType } from 'react-admin'
import permissionsProvider from './administration/permissions/permissions.provider'
import rolesProvider from './administration/roles/roles.provider'
import usersProvider from './administration/users/users.provider'
import { ResourceName } from './ResourceName'
import currentUserProvider from './current-user/current-user.provider'
import schedulersProvider from './schedulers/schedulers.provider'
import radiotherapyAilmentSurveysProvider from './radiotherapy-ailment-surveys/radiotherapy-ailment-surveys.provider'
import radiotherapyAilmentsProvider from './radiotherapy-ailments/radiotherapy-ailments.provider'
import radiotherapyMachinesProvider from './radiotherapy-machines/radiotherapy-machines.provider'
import vacationCalendarsProvider from './vacation-calendars/vacation-calendars.provider'
import chemotherapyAilmentsProvider from './chemotherapy-ailments/chemotherapy-ailments.provider'
import chemotherapyAilmentSurveysProvider from './chemotherapy-ailment-surveys/chemotherapy-ailment-surveys.provider'

const dataProviders: Record<ResourceName, DataProvider> = {
  [ResourceName.CURRENT_USER]: currentUserProvider,
  [ResourceName.ROLES]: rolesProvider,
  [ResourceName.PERMISSIONS]: permissionsProvider,
  [ResourceName.USERS]: usersProvider,
  [ResourceName.DOCTORS]: usersProvider,
  [ResourceName.PATIENTS]: usersProvider,
  [ResourceName.WEB_USERS]: usersProvider,
  [ResourceName.RADIOTHERAPY_AILMENTS]: radiotherapyAilmentsProvider,
  [ResourceName.RADIOTHERAPY_AILMENT_SURVEYS]:
    radiotherapyAilmentSurveysProvider,
  [ResourceName.CHEMOTHERAPY_AILMENTS]: chemotherapyAilmentsProvider,
  [ResourceName.CHEMOTHERAPY_AILMENT_SURVEYS]:
    chemotherapyAilmentSurveysProvider,
  [ResourceName.SCHEDULERS]: schedulersProvider,
  [ResourceName.RADIOTHERAPY_MACHINES]: radiotherapyMachinesProvider,
  [ResourceName.VACATION_CALENDARS]: vacationCalendarsProvider,
}

export default (
  fetchType: string,
  resource: string,
  params: unknown,
): Promise<unknown> => {
  const selectedProvider = dataProviders[resource as ResourceName]
  const type = sanitizeFetchType(fetchType)
  return selectedProvider[type](resource, params)
}
