import * as React from 'react';
import { List, ListItemText } from '@material-ui/core';
import { getFieldLabelTranslationArgs, useGetResourceLabel, useTranslate, } from 'react-admin';
import { ResourceModelFieldEditor } from './ResourceModelFieldEditor';
import { useResourceModel } from './useResourceModel';
import { ResetSettingsButton } from '../ResetSettingsButton';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ListItemLink } from '../ListItemLink';
import { EditorTitle } from '../Editor';
/**
 * A component which provides a configuration UI to tweak a resource model
 * @param {ReferenceFieldInputProps} props
 * @param {String} props.resource The resource
 * @param {String} props.preferencesKey The preference key which defaults to the resource name
 */
export var ResourceModelEditor = function (_a) {
    var resource = _a.resource, _b = _a.preferencesKey, preferencesKey = _b === void 0 ? resource : _b;
    var model = useResourceModel(resource, preferencesKey)[0];
    var routeMatch = useRouteMatch();
    var translate = useTranslate();
    var getResourceLabel = useGetResourceLabel();
    if (!model || !model.fields)
        return null;
    return (React.createElement(Switch, null,
        Object.keys(model.fields).map(function (field) { return (React.createElement(Route, { key: "route-" + field, path: routeMatch.path + "/" + field, render: function () { return (React.createElement(ResourceModelFieldEditor, { field: model.fields[field], resource: resource, preferencesKey: preferencesKey })); } })); }),
        React.createElement(Route, { path: routeMatch.path, render: function () { return (React.createElement(React.Fragment, null,
                React.createElement(EditorTitle, { title: getResourceLabel(resource, 2) }),
                React.createElement(List, { dense: true }, Object.keys(model.fields).map(function (field) { return (React.createElement(ListItemLink, { key: "link-" + field, to: routeMatch.path + "/" + field },
                    React.createElement(ListItemText, { primary: translate.apply(void 0, getFieldLabelTranslationArgs({
                            label: model.fields[field].props
                                .label,
                            resource: resource,
                            source: model.fields[field]
                                .props.source,
                        })) }))); })),
                React.createElement(ResetSettingsButton, { preferencesKey: preferencesKey }))); } })));
};
