import { Box, Divider, Typography } from '@material-ui/core'
import React, { ReactElement, cloneElement, useContext } from 'react'
import {
  BooleanInput,
  CreateButton,
  EditButton,
  FilterButton,
  FilterContext,
  FunctionField,
  Link,
  NumberField,
  ReferenceField,
  ResourceContextProvider,
  sanitizeListRestProps,
  SearchInput,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  TitleProps,
  TopToolbar,
  useListContext,
  useQueryWithStore,
  useRecordContext,
  useShowContext,
  useTranslate,
} from 'react-admin'
import { Authority } from '../../core/auth/Authority'
import { PatientDto } from '../../core/dto/user/patient.dto'
import { UserBaseDto } from '../../core/dto/user/user-base.dto'
import { ResourceName } from '../../core/ResourceName'
import { RadiotherapyAilmentSurveyList } from '../radiotherapy-ailment-surveys/RadiotherapyAilmentSurveyList'
import { DateFilterInput } from '../common/DateFilterInput'
import { DeleteButtonWithConfirm } from '../common/DeleteButtonWithConfirm'
import RelationTab from '../common/RelationTab'
import { useHasAuthority } from '../hooks/useHasAuthority'
import { SchedulerList } from '../schedulers/SchedulerList'
import ChangePasswordButton from '../administration/users/UserShow/UserChangePasswordButton'
import Button from '../common/customized-mui-components/Button'
import { GetMyPatientChatResponse } from '../../core/current-user/current-user.provider'
import { DoctorDto } from '../../core/dto/user/doctor.dto'
import PatientMarkRadioteraphyAsDeactivatedButton from '../administration/users/UserShow/PatientMarkRadioteraphyAsDeactivatedButton'
import PatientMarkChemoteraphyAsDeactivatedButton from '../administration/users/UserShow/PatientMarkChemoteraphyAsDeactivatedButton'
import { ChemotherapyAilmentSurveyList } from '../chemotherapy-ailment-surveys/ChemotherapyAilmentSurveyList'
import DeactivatePatientButton from '../administration/users/UserShow/DeactivatePatientButton'

interface PatientTitleProps extends TitleProps {
  record?: UserBaseDto
}

const ShowTitle = (data: PatientTitleProps) => {
  const { record: patient } = data
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.patients.titles.show')}: {patient?.firstName}{' '}
      {patient?.lastName}
    </span>
  )
}

const PatientDocotrs = ({ ...props }) => {
  const { record: patient } = props
  const hasAuthority = useHasAuthority()

  const patientsFilters = () => [
    <SearchInput source="fullNameSearch" alwaysOn />,
    <BooleanInput
      label="common.related-records.actions.attached-only"
      source="filterDoctorPatientId"
      alwaysOn
    />,
  ]

  return (
    <RelationTab<UserBaseDto>
      resource={ResourceName.DOCTORS}
      source="doctorIds"
      mode={hasAuthority(Authority.EDIT_PATIENTS) ? 'edit' : 'show'}
      attachMethod="attachDoctorsToPatient"
      detachMethod="detachDoctorsFromPatient"
      attachRequestPayload={(u, ids) => ({
        patientId: u.id,
        doctorIds: ids,
      })}
      detachRequestPayload={(r, ids) => ({
        patientId: r.id,
        doctorIds: ids,
      })}
      refetchListAfterChange={(filters) =>
        filters?.filterDoctorPatientId === true
      }
      filters={patientsFilters() as ReactElement[]}
      filterDefaultValues={{
        doctorPatientId: patient?.id,
        filterDoctorPatientId: true,
        doctorsOnly: true,
      }}
      sort={{
        field: 'id',
        order: 'DESC',
      }}
    >
      <TextField source="id" label="ID" />
      <TextField source="firstName" />
      <TextField source="lastName" />
    </RelationTab>
  )
}

const SchedulersListActions = ({ ...props }) => {
  const { filters: filtersProp, basePath } = props
  const resource = ResourceName.SCHEDULERS
  const hasAuthority = useHasAuthority()

  const { displayedFilters, filterValues, showFilter } = useListContext(props)
  const filters = useContext(FilterContext) || filtersProp
  const patient = useRecordContext(props)

  return (
    <TopToolbar {...sanitizeListRestProps(props)}>
      {filtersProp
        ? cloneElement(filtersProp, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })
        : filters && <FilterButton />}
      {hasAuthority(Authority.CREATE_SCHEDULERS) && (
        <CreateButton
          resource={ResourceName.SCHEDULERS}
          to={{
            pathname: `${basePath}/create`,
            state: { patientId: patient?.id },
          }}
        />
      )}
    </TopToolbar>
  )
}

const Schedulers = () => {
  const { record: patientData } = useShowContext<PatientDto>()
  return (
    <ResourceContextProvider value={ResourceName.SCHEDULERS}>
      <SchedulerList
        title=" "
        basePath={`/${ResourceName.SCHEDULERS}`}
        filters={[<DateFilterInput source="date" key="date" />]}
        filterDefaultValues={{ patientId: patientData?.id }}
        actions={<SchedulersListActions />}
      />
    </ResourceContextProvider>
  )
}

const RadiotherapyAilmentSurveysListActions = ({ ...props }) => {
  const { filters: filtersProp, basePath } = props
  const resource = ResourceName.RADIOTHERAPY_AILMENT_SURVEYS
  const hasAuthority = useHasAuthority()

  const { displayedFilters, filterValues, showFilter } = useListContext(props)
  const filters = useContext(FilterContext) || filtersProp
  const patient = useRecordContext(props)

  return (
    <TopToolbar {...sanitizeListRestProps(props)}>
      {filtersProp
        ? cloneElement(filtersProp, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })
        : filters && <FilterButton />}
      {hasAuthority(Authority.CREATE_RADIOTHERAPY_AILMENT_SURVEYS) && (
        <CreateButton
          resource={ResourceName.RADIOTHERAPY_AILMENT_SURVEYS}
          to={{
            pathname: `${basePath}/create`,
            state: { patientId: patient?.id },
          }}
        />
      )}
    </TopToolbar>
  )
}

const ChemotherapyAilmentSurveysListActions = ({ ...props }) => {
  const { filters: filtersProp, basePath } = props
  const resource = ResourceName.CHEMOTHERAPY_AILMENT_SURVEYS
  const hasAuthority = useHasAuthority()

  const { displayedFilters, filterValues, showFilter } = useListContext(props)
  const filters = useContext(FilterContext) || filtersProp
  const patient = useRecordContext(props)

  return (
    <TopToolbar {...sanitizeListRestProps(props)}>
      {filtersProp
        ? cloneElement(filtersProp, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })
        : filters && <FilterButton />}
      {hasAuthority(Authority.CREATE_CHEMOTHERAPY_AILMENT_SURVEYS) && (
        <CreateButton
          resource={ResourceName.CHEMOTHERAPY_AILMENT_SURVEYS}
          to={{
            pathname: `${basePath}/create`,
            state: { patientId: patient?.id },
          }}
        />
      )}
    </TopToolbar>
  )
}

const RadiotherapyAilmentSurveys = () => {
  const { record: patientData } = useShowContext<PatientDto>()
  return (
    <ResourceContextProvider value={ResourceName.RADIOTHERAPY_AILMENT_SURVEYS}>
      <RadiotherapyAilmentSurveyList
        title=" "
        basePath={`/${ResourceName.RADIOTHERAPY_AILMENT_SURVEYS}`}
        filters={[<DateFilterInput source="date" key="date" />]}
        filterDefaultValues={{
          patientId: patientData?.id,
          hidePatientsWithoutRadiotherapyModule: true,
        }}
        actions={<RadiotherapyAilmentSurveysListActions />}
      />
    </ResourceContextProvider>
  )
}

const ChemotherapyAilmentSurveys = () => {
  const { record: patientData } = useShowContext<PatientDto>()
  return (
    <ResourceContextProvider value={ResourceName.CHEMOTHERAPY_AILMENT_SURVEYS}>
      <ChemotherapyAilmentSurveyList
        title=" "
        basePath={`/${ResourceName.CHEMOTHERAPY_AILMENT_SURVEYS}`}
        filters={[<DateFilterInput source="date" key="date" />]}
        filterDefaultValues={{
          patientId: patientData?.id,
          hidePatientsWithoutChemotherapyModule: true,
        }}
        actions={<ChemotherapyAilmentSurveysListActions />}
      />
    </ResourceContextProvider>
  )
}

const GeneralActions = ({ ...props }) => {
  const { record: patient } = useShowContext<PatientDto>()
  const hasAuthority = useHasAuthority()
  const { data: patientChat } = useQueryWithStore({
    type: 'getMyPatientChat',
    resource: ResourceName.CURRENT_USER,
    payload: { patientId: patient?.id },
  })

  return (
    <>
      <Divider />
      <Box p={1} display="block" textAlign="right">
        <Link
          to={`/chat?id=${(patientChat as GetMyPatientChatResponse)?.chatId}`}
          onClick={(e) => {
            if (!(patientChat as GetMyPatientChatResponse)?.chatId)
              e.preventDefault()
          }}
        >
          <Button
            label="resources.patients.actions.open-chat"
            variant="contained"
            disabled={!(patientChat as GetMyPatientChatResponse)?.chatId}
            style={{ margin: '2px' }}
          />
        </Link>
        <PatientMarkRadioteraphyAsDeactivatedButton />
        <PatientMarkChemoteraphyAsDeactivatedButton />
        <ChangePasswordButton />
        <EditButton
          basePath={`/${ResourceName.PATIENTS}`}
          record={patient}
          variant="contained"
          style={{ margin: '2px' }}
          disabled={!hasAuthority(Authority.EDIT_PATIENTS)}
        />
        <DeactivatePatientButton />
        <DeleteButtonWithConfirm
          {...props}
          disabled={!hasAuthority(Authority.EDIT_PATIENTS)}
        />
      </Box>
    </>
  )
}

const PatientTabs = ({ ...props }) => {
  const { record: patient } = useShowContext<PatientDto>()
  const translate = useTranslate()
  const hasAuthority = useHasAuthority()

  return (
    <TabbedShowLayout {...props}>
      <Tab label="resources.patients.tabs.general">
        <TextField source="id" label="ID" />
        <FunctionField<PatientDto>
          source="active"
          render={(record?: PatientDto) => (
            <div>
              {record?.active === true
                ? translate('const.yes')
                : translate('const.no')}
            </div>
          )}
        />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="pesel" emptyText="-" />
        <TextField source="userName" />
        <ReferenceField
          source="mainDoctorId"
          reference={ResourceName.DOCTORS}
          link="show"
          emptyText="-"
        >
          <FunctionField<DoctorDto>
            source="mainDoctorId"
            render={(record?: DoctorDto) => (
              <Typography variant="body2">
                {record?.firstName} {record?.lastName}
              </Typography>
            )}
          />
        </ReferenceField>
        <ReferenceField
          source="temporaryDoctorId"
          reference={ResourceName.DOCTORS}
          link="show"
          emptyText="-"
        >
          <FunctionField<DoctorDto>
            source="temporaryDoctorId"
            render={(record?: DoctorDto) => (
              <Typography variant="body2">
                {record?.firstName} {record?.lastName}
              </Typography>
            )}
          />
        </ReferenceField>
        <FunctionField<PatientDto>
          source="isRadiotherapyModuleAvailable"
          render={(record?: PatientDto) => (
            <div>
              {record?.isRadiotherapyModuleAvailable
                ? translate('const.yes')
                : translate('const.no')}
            </div>
          )}
        />
        <FunctionField<PatientDto>
          source="isRadiochemotherapyModuleAvailable"
          render={(record?: PatientDto) => (
            <div>
              {record?.isRadiochemotherapyModuleAvailable
                ? translate('const.yes')
                : translate('const.no')}
            </div>
          )}
        />
        <FunctionField<PatientDto>
          source="isChemotherapyModuleAvailable"
          render={(record?: PatientDto) => (
            <div>
              {record?.isChemotherapyModuleAvailable
                ? translate('const.yes')
                : translate('const.no')}
            </div>
          )}
        />
        <FunctionField<PatientDto>
          source="isBrachytherapyModuleAvailable"
          render={(record?: PatientDto) => (
            <div>
              {record?.isBrachytherapyModuleAvailable
                ? translate('const.yes')
                : translate('const.no')}
            </div>
          )}
        />
        <FunctionField<PatientDto>
          source="tag"
          sortable={false}
          render={(record?: PatientDto) => (
            <div>
              {record?.tag
                ? translate(`resources.enums.patientTags.${record?.tag}`)
                : '-'}
            </div>
          )}
        />
        <NumberField
          source="patientRadiotherapySurveyInterval"
          sortable={false}
        />
        <NumberField
          source="patientChemotherapySurveyInterval"
          sortable={false}
        />
        <TextField source="diagnosis" emptyText="-" />
        <GeneralActions />
      </Tab>
      {hasAuthority(Authority.VIEW_DOCTORS) && (
        <Tab label="resources.patients.tabs.doctors" path="doctors">
          <PatientDocotrs />
        </Tab>
      )}
      {hasAuthority(Authority.VIEW_SCHEDULERS) &&
        hasAuthority(Authority.VIEW_SCHEDULERS) && (
          <Tab label="resources.patients.tabs.schedulers" path="schedulers">
            <Schedulers />
          </Tab>
        )}
      {hasAuthority(Authority.VIEW_RADIOTHERAPY_AILMENT_SURVEYS) &&
        hasAuthority(Authority.VIEW_RADIOTHERAPY_AILMENTS) &&
        patient?.isRadiotherapyModuleAvailable && (
          <Tab
            label="resources.patients.tabs.radiotherapyAilmentSurveys"
            path="radiotherapyAilmentSurveys"
          >
            <RadiotherapyAilmentSurveys />
          </Tab>
        )}
      {hasAuthority(Authority.VIEW_CHEMOTHERAPY_AILMENT_SURVEYS) &&
        hasAuthority(Authority.VIEW_CHEMOTHERAPY_AILMENTS) &&
        patient?.isChemotherapyModuleAvailable && (
          <Tab
            label="resources.patients.tabs.chemotherapyAilmentSurveys"
            path="chemotherapyAilmentSurveys"
          >
            <ChemotherapyAilmentSurveys />
          </Tab>
        )}
    </TabbedShowLayout>
  )
}

export const PatientShow = (props: ShowProps) => (
  <Show {...props} actions={false} title={<ShowTitle />}>
    <PatientTabs />
  </Show>
)
