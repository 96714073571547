import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Settings } from '@material-ui/icons'
import React, { lazy } from 'react'
import {
  AppBar,
  AppBarProps,
  CoreLayoutProps,
  UserMenu,
  UserMenuProps,
  MenuItemLink,
  useTranslate,
} from 'react-admin'
import { useSelector } from 'react-redux'
import { extendedDarkTheme, extendedLightTheme } from './app.theme'
import { ThemeState } from '../../core/common/theme.reducer'
import { AppMenu } from './AppMenu'
import { Layout } from '../../lib/@react-admin/ra-enterprise/esm/src'
import { AppLocationContext } from '../../lib/@react-admin/ra-navigation/esm/src'

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
})

const AppUserMenu = (props: UserMenuProps) => {
  const translate = useTranslate()
  return (
    <UserMenu {...props}>
      <MenuItemLink
        to="/configuration"
        primaryText={translate('settings.name')}
        leftIcon={<Settings />}
      />
    </UserMenu>
  )
}

const AppTopBar = (props: AppBarProps) => {
  const classes = useStyles()
  return (
    <AppBar {...props} userMenu={<AppUserMenu />} position="fixed">
      <Typography
        variant="h6"
        className={classes.title}
        id="react-admin-title"
      />
    </AppBar>
  )
}

const LightTheme = lazy(() => import('./app.theme.light.styles'))
const DarkTheme = lazy(() => import('./app.theme.dark.styles'))

const ThemeSelector = ({ children }: any) => {
  const Theme = useSelector((state: ThemeState) =>
    state.theme.darkMode === 'dark' ? DarkTheme : LightTheme,
  )

  return (
    <>
      <React.Suspense fallback={<></>}>
        <Theme />
      </React.Suspense>
      {children}
    </>
  )
}

export const AppLayout = (props: CoreLayoutProps) => {
  const theme = useSelector((state: ThemeState) =>
    state.theme.darkMode === 'dark' ? extendedDarkTheme : extendedLightTheme,
  )

  return (
    <AppLocationContext>
      <ThemeSelector>
        <Layout {...props} menu={AppMenu} appBar={AppTopBar} theme={theme} />
      </ThemeSelector>
    </AppLocationContext>
  )
}
