import { makeStyles } from '@material-ui/core'
import React from 'react'
import {
  Edit,
  EditProps,
  FormDataConsumer,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  ToolbarProps,
  useEditContext,
} from 'react-admin'
import { useFormState } from 'react-final-form'
import { DoctorDto } from '../../core/dto/user/doctor.dto'
import { UserBaseDto } from '../../core/dto/user/user-base.dto'
import { UserTypes } from '../../core/enum/UserTypes'
import Base64ImageInput from '../common/Base64ImageInput'
import ChangePasswordButton from './current-user-show/CurrentUserChangePasswordButton'

const useStyles = makeStyles({
  editForm: {
    width: '100%',
  },
})

export const validateUserEdit = (values: UserBaseDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.userName) {
    errors.userName = 'ra.validation.required'
  }
  return errors
}

const EditToolbar = (props: ToolbarProps) => {
  const RenderSaveButton = ({ ...formProps }) => {
    const { dirty } = useFormState(formProps)
    return <SaveButton {...formProps} disabled={!dirty} />
  }

  return (
    <Toolbar {...props}>
      <FormDataConsumer>
        {({ ...formProps }) => <RenderSaveButton {...formProps} />}
      </FormDataConsumer>
      <ChangePasswordButton />
    </Toolbar>
  )
}

const CurrentUserForm = ({ ...props }) => {
  const classes = useStyles()
  const { record: user } = useEditContext()

  return (
    <SimpleForm
      {...props}
      redirect={false}
      validate={validateUserEdit}
      toolbar={<EditToolbar />}
      className={classes.editForm}
    >
      <TextField source="id" label="ID" />
      <TextInput
        source="firstName"
        label="resources.users.fields.firstName"
        isRequired
      />
      <TextInput
        source="lastName"
        label="resources.users.fields.lastName"
        isRequired
      />
      {(user as DoctorDto)?.userType === UserTypes.DOCTOR && (
        <Base64ImageInput
          source="base64Photo"
          outputSource="blobPhoto"
          label="resources.users.fields.base64Photo"
        />
      )}
      <TextInput
        source="userName"
        isRequired
        label="resources.users.fields.userName"
      />
      {/* <FunctionField<UserBaseDto>
        source="requirePasswordUpdate"
        render={(record?: UserBaseDto) => (
          <div>
            {record?.requirePasswordUpdate
              ? translate('const.yes')
              : translate('const.no')}
          </div>
        )}
        label="resources.users.fields.requirePasswordUpdate"
      /> */}
      <TextInput source="email" label="resources.users.fields.email" />
    </SimpleForm>
  )
}

export const CurrentUserEdit = (props: EditProps) => {
  const classes = useStyles()

  return (
    <Edit {...props} title=" " actions={false} className={classes.editForm}>
      <CurrentUserForm />
    </Edit>
  )
}
