import {
  Dialog,
  DialogActions,
  makeStyles,
  createStyles,
  DialogContent,
  TextField,
  Typography,
  IconButton,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React, { useState } from 'react'
import {
  useTranslate,
  useShowContext,
  useMutation,
  useRefresh,
  sanitizeFetchType,
} from 'react-admin'
import { ResourceName } from '../../../core/ResourceName'
import Button from '../../common/customized-mui-components/Button'
import { UserBaseDto } from '../../../core/dto/user/user-base.dto'
import { ConfirmDialog } from '../../common/ConfirmDialog'
import DraggableComponent from '../../common/DraggableComponent'

const useStyles = makeStyles(() =>
  createStyles({
    bulkStateActions: {
      height: '16px',
      background: 'rgba(0,0,0,0.2)',
      minWidth: '300px',
      userSelect: 'none',
    },
    bulkProgressTitle: {
      marginLeft: '10px',
      marginRight: 'auto',
    },
    bulkProgressCloseButton: {
      margin: 0,
    },
    actionsButton: {
      margin: '2%',
      width: '96%',
    },
  }),
)

export const ChangePasswordDialog = ({
  open,
  close,
}: {
  open: boolean
  close: () => void
}) => {
  const classes = useStyles()
  const [value, setValue] = useState<string>('')
  const [error, setError] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [confirmOpen, setConfirmOpen] = useState(false)
  const { record: user } = useShowContext<UserBaseDto>()
  const [mutate] = useMutation()
  const refresh = useRefresh()
  const translate = useTranslate()

  const handleClose = () => {
    setValue('')
    setError(false)
    setErrorText('')
    close()
  }

  const handleValidate = () => {
    setError(false)
    setErrorText('')
    // if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/.test(value)) {
    //   setError(true)
    //   setErrorText(
    //     'resources.users.dialogs.changePassword.error-password-requirements',
    //   )
    // } else if (value.replace(/^[a-zA-Z0-9]*$/, '').length !== 0) {
    //   setError(true)
    //   setErrorText('resources.users.dialogs.changePassword.error-bad-string')
    // } else
    setConfirmOpen(true)
  }

  const handleChangeUserPassword = async () => {
    setConfirmOpen(false)
    await mutate(
      {
        type: sanitizeFetchType('changeUserPassword'),
        resource: ResourceName.USERS,
        payload: {
          userId: user?.id,
          newPassword: value,
        },
      },
      { returnPromise: true },
    )
    setValue('')
    refresh()
    close()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick
      disableEscapeKeyDown
      PaperComponent={DraggableComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <ConfirmDialog
        open={confirmOpen}
        cancel={() => setConfirmOpen(false)}
        confirm={handleChangeUserPassword}
        source="changePassword"
        action="set"
        resource={ResourceName.USERS}
      />
      <DialogActions
        className={classes.bulkStateActions}
        style={{ cursor: 'move' }}
        id="draggable-dialog-title"
      >
        <Typography className={classes.bulkProgressTitle}>
          {translate('resources.users.dialogs.changePassword.title')}
        </Typography>
        <IconButton
          size="small"
          className={classes.bulkProgressCloseButton}
          onClick={handleClose}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogActions>
      <DialogContent>
        <TextField
          variant="outlined"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          fullWidth
          error={error}
          helperText={translate(errorText)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleValidate}
          label={translate('ra.action.save')}
          useSmallVersionBreakpoint={false}
          className={classes.actionsButton}
        />
        <Button
          variant="contained"
          onClick={handleClose}
          label={translate('const.cancel')}
          useSmallVersionBreakpoint={false}
          className={classes.actionsButton}
        />
      </DialogActions>
    </Dialog>
  )
}

export const PatientMarkRadioteraphyAsDeactivatedDialog = ({
  open,
  close,
}: {
  open: boolean
  close: () => void
}) => {
  const { record: user } = useShowContext<UserBaseDto>()
  const [mutate] = useMutation()
  const refresh = useRefresh()

  const handleClose = () => {
    close()
  }

  const handleRadioteraphyDeactivation = async () => {
    handleClose()
    await mutate(
      {
        type: sanitizeFetchType('markPatientRadiotherapyAsDeactivated'),
        resource: ResourceName.USERS,
        payload: {
          patientId: user?.id,
        },
      },
      { returnPromise: true },
    )
    refresh()
    close()
  }

  return (
    <ConfirmDialog
      open={open}
      cancel={handleClose}
      confirm={handleRadioteraphyDeactivation}
      source="radioteraphyDeactivation"
      action="set"
      resource={ResourceName.USERS}
    />
  )
}

export const PatientMarkChemoteraphyAsDeactivatedDialog = ({
  open,
  close,
}: {
  open: boolean
  close: () => void
}) => {
  const { record: user } = useShowContext<UserBaseDto>()
  const [mutate] = useMutation()
  const refresh = useRefresh()

  const handleClose = () => {
    close()
  }

  const handleChemoteraphyDeactivation = async () => {
    handleClose()
    await mutate(
      {
        type: sanitizeFetchType('markPatientChemotherapyAsDeactivated'),
        resource: ResourceName.USERS,
        payload: {
          patientId: user?.id,
        },
      },
      { returnPromise: true },
    )
    refresh()
    close()
  }

  return (
    <ConfirmDialog
      open={open}
      cancel={handleClose}
      confirm={handleChemoteraphyDeactivation}
      source="chemoteraphyDeactivation"
      action="set"
      resource={ResourceName.USERS}
    />
  )
}

export const DeactivatePatientDialog = ({
  open,
  close,
}: {
  open: boolean
  close: () => void
}) => {
  const { record: user } = useShowContext<UserBaseDto>()
  const [mutate] = useMutation()
  const refresh = useRefresh()

  const handleClose = () => {
    close()
  }

  const handleChemoteraphyDeactivation = async () => {
    handleClose()
    await mutate(
      {
        type: sanitizeFetchType('deactivatePatient'),
        resource: ResourceName.USERS,
        payload: {
          patientId: user?.id,
        },
      },
      { returnPromise: true },
    )
    refresh()
    close()
  }

  return (
    <ConfirmDialog
      open={open}
      cancel={handleClose}
      confirm={handleChemoteraphyDeactivation}
      source="patientDeactivation"
      action="set"
      resource={ResourceName.USERS}
    />
  )
}
