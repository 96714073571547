const mapping: Record<string, string> = {
  id: 's=>s.Id',
  dateFrom: 's=>s.DateFrom',
  dateTo: 's=>s.DateTo',
  mainDoctorId: 's=>s.MainDoctorId',
  temporaryDoctorId: 's=>s.TemporaryDoctorId',
}

const sortMapper = (key: string) => mapping[key]

export default sortMapper
