import * as React from 'react';
import { useState, useRef } from 'react';
import { Typography, Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import { useTranslate, getFieldLabelTranslationArgs } from 'react-admin';
import { useResourceModel } from './useResourceModel';
var useStyles = makeStyles({
    popover: {
        pointerEvents: 'none',
    },
    popoverContent: {
        pointerEvents: 'auto',
        padding: '1em',
        maxWidth: '25em',
    },
});
export var TemplateInfoButton = function (_a) {
    var resource = _a.resource;
    var classes = useStyles();
    var _b = useState(false), openedPopover = _b[0], setOpenedPopover = _b[1];
    var popoverAnchor = useRef(null);
    var handleEnter = function () {
        setOpenedPopover(true);
    };
    var handleLeave = function () {
        setOpenedPopover(false);
    };
    var id = openedPopover ? 'simple-popover' : undefined;
    var translate = useTranslate();
    var types = useResourceModel(resource);
    var keys = Object.keys(types);
    return (React.createElement(React.Fragment, null,
        React.createElement("span", { ref: popoverAnchor, "aria-owns": id, onMouseEnter: handleEnter, onMouseLeave: handleLeave },
            React.createElement(InfoIcon, { color: "disabled" })),
        React.createElement(Popover, { id: id, open: openedPopover, anchorEl: popoverAnchor.current, onClose: handleLeave, anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
            }, transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
            }, className: classes.popover, classes: {
                paper: classes.popoverContent,
            }, PaperProps: {
                onMouseEnter: handleEnter,
                onMouseLeave: handleLeave,
            } },
            React.createElement(Typography, { color: "textSecondary" },
                "Display record data using the",
                ' ',
                React.createElement("a", { href: "https://mozilla.github.io/nunjucks/", target: "_blank", rel: "noreferrer" }, "nunjucks"),
                ' ',
                "syntax ",
                React.createElement("br", null),
                "e.g. ",
                '"{{',
                " first_name ",
                '}}',
                " ",
                '{{',
                " last_name ",
                '}}"',
                ".",
                React.createElement("br", null),
                React.createElement("br", null),
                "Available fields for the ",
                resource,
                " resource:"),
            React.createElement("ul", null, keys.map(function (key) { return (React.createElement(Typography, { key: key, component: "li", color: "textSecondary" },
                key,
                " (",
                translate.apply(void 0, getFieldLabelTranslationArgs({
                    resource: resource,
                    source: key,
                })),
                ")")); })))));
};
