import { useMediaQuery, useTheme } from '@material-ui/core'
import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  Create,
  CreateProps,
  DateTimeInput,
  GET_ONE,
  ListButton,
  SaveButton,
  SimpleForm,
  Toolbar,
  ToolbarProps,
  sanitizeFetchType,
  useQueryWithStore,
  useTranslate,
} from 'react-admin'
import { useLocation } from 'react-router'
import { CreateChemotherapyAilmentSurveyRequest } from '../../core/chemotherapy-ailment-surveys/chemotherapy-ailment-surveys.provider'
import { ResourceName } from '../../core/ResourceName'
import FilteredReferenceInput from '../common/FilteredReferenceInput'
import { UserBaseDto } from '../../core/dto/user/user-base.dto'
import { UserTypes } from '../../core/enum/UserTypes'
import { PatientDto } from '../../core/dto/user/patient.dto'

export const validateChemotherapyAilmentSurveyCreate = (
  values: CreateChemotherapyAilmentSurveyRequest,
) => {
  const errors: { [n: string]: string } = {}
  if (!values.patientId) {
    errors.patientId = 'ra.validation.required'
  }
  if (!values.date) {
    errors.date = 'ra.validation.required'
  }
  return errors
}

const CreateTitle = () => {
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.chemotherapy-ailment-surveys.titles.create')}
    </span>
  )
}

const CreateToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ListButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

export const ChemotherapyAilmentSurveyCreate = (props: CreateProps) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { state } = useLocation()

  const { data: user } = useQueryWithStore({
    type: sanitizeFetchType(GET_ONE),
    resource: ResourceName.CURRENT_USER,
    payload: {},
  })

  return (
    <Create {...props} title={<CreateTitle />}>
      <SimpleForm
        redirect="show"
        validate={validateChemotherapyAilmentSurveyCreate}
        toolbar={<CreateToolbar />}
        initialValues={state}
      >
        <FilteredReferenceInput
          source="patientId"
          defaultValue={null}
          reference={ResourceName.PATIENTS}
          sort={{ field: 'lastName', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          allowEmpty
          filterSource="fullNameSearch"
          filter={{
            patientsOnly: true,
            active: true,
            filterRelatedPatients:
              (user as UserBaseDto)?.userType === UserTypes.DOCTOR,
            relatedPatientDoctorId: (user as UserBaseDto)?.id,
          }}
          emptyText="-"
          selectWithPaginationInputProps={{
            optionText: (record: PatientDto) =>
              `${record.firstName} ${record.lastName} (${record.pesel})`,
            showFilter: true,
            disabled: (state as any)?.patientId,
          }}
        />
        <DateTimeInput
          source="date"
          parse={(value: string) => (value === '' ? null : value)}
          isRequired
        />
      </SimpleForm>
    </Create>
  )
}
