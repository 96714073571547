import { DataProvider } from 'react-admin'
import { ChemotherapyAilmentBaseDTO } from '../../dto/chemotherapy-ailment/chemotherapy-ailment-base.dto'

const fakeChemotherapyAilments: ChemotherapyAilmentBaseDTO[] = []

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({
      data: fakeChemotherapyAilments,
      total: fakeChemotherapyAilments.length,
    }),
} as DataProvider

export default provider
