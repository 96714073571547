import * as React from 'react'
import { Route } from 'react-router-dom'
import { Authenticated } from 'react-admin'
import { Configuration } from '../current-user/UserConfiguration'
import { ChatModal } from '../users-communication/ChatModal'
import PrintPatientDataModal from '../administration/users/UserCreate/PrintPatientDataModal'

export default [
  <Route exact path="/configuration" render={() => <Configuration />} />,
  <Route
    exact
    path="/chat"
    render={() => (
      <Authenticated>
        <ChatModal />
      </Authenticated>
    )}
  />,
  <Route
    exact
    path="/print-patient-data"
    render={() => <PrintPatientDataModal />}
  />,
]
