import { createStyles, makeStyles, Theme } from '@material-ui/core'
import React, { useState } from 'react'
import { useTranslate, useShowContext } from 'react-admin'
import { Authority } from '../../../../core/auth/Authority'
import Button from '../../../common/customized-mui-components/Button'
import { useHasAuthority } from '../../../hooks/useHasAuthority'
import { PatientMarkRadioteraphyAsDeactivatedDialog } from '../UserDialogs'
import { PatientDto } from '../../../../core/dto/user/patient.dto'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: theme.palette.common.white,
      margin: '2px',
    },
  }),
)

const PatientMarkRadioteraphyAsDeactivatedButton = () => {
  const { loading } = useShowContext()
  const [dialogOpen, setDialogOpen] = useState(false)
  const translate = useTranslate()
  const classes = useStyles()
  const hasAuthority = useHasAuthority()
  const { record: patient } = useShowContext<PatientDto>()

  return (
    <>
      <PatientMarkRadioteraphyAsDeactivatedDialog
        open={dialogOpen}
        close={() => setDialogOpen(false)}
      />
      <Button
        className={classes.button}
        variant="contained"
        label={translate('resources.users.actions.deactivateRadioteraphy')}
        disabled={
          (!hasAuthority(Authority.EDIT_USERS) &&
            !hasAuthority(Authority.EDIT_PATIENTS)) ||
          !patient?.isRadiotherapyModuleAvailable ||
          patient?.radiotherapyDeactivationDate !== null ||
          patient?.active !== true ||
          loading
        }
        onClick={() => setDialogOpen(true)}
      />
    </>
  )
}

export default PatientMarkRadioteraphyAsDeactivatedButton
