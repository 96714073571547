const mapping: Record<
  string,
  (lambdaParameter: string, value: any, otherValues: any) => string | null
> = {
  userName: (lambdaParameter, value) =>
    `${lambdaParameter}.UserName.Contains("${value}")`,
  companyId: (lambdaParameter, value) =>
    `${lambdaParameter}.Company.Id.Equals(${value})`,
  roleId: (lambdaParameter, value) =>
    `${lambdaParameter}.Roles.Any(r=>r.Id.Equals(${value}))`,
  webUsersOnly: (lambdaParameter, value) =>
    value
      ? `${lambdaParameter} is WebUser && !(${lambdaParameter} is Doctor) && !(${lambdaParameter}.UserName.Equals("admin"))`
      : null,
  fullNameSearch: (lambdaParameter, value) =>
    `${value
      .toString()
      .split(' ')
      .map(
        (q: string) =>
          `(${lambdaParameter}.UserName+${lambdaParameter}.FirstName+${lambdaParameter}.LastName+(${lambdaParameter} as Patient).Pesel).Contains("${q}")`,
      )
      .join('&&')}`,
  // DOCTOR
  filterPatientDoctorId: () => null,
  patientDoctorId: (lambdaParameter, value, otherValues) =>
    otherValues?.filterPatientDoctorId === false
      ? null
      : `(${lambdaParameter} as Patient).Doctors.Any(d=>d.Id.Equals(${value}))`,
  filterPatientMainDoctorId: () => null,
  patientMainDoctorId: (lambdaParameter, value, otherValues) =>
    otherValues?.filterPatientMainDoctorId === false
      ? null
      : `(${lambdaParameter} as Patient).MainDoctorId.Equals(${value})`,
  filterPatientTemporaryDoctorId: () => null,
  patientTemporaryDoctorId: (lambdaParameter, value, otherValues) =>
    otherValues?.filterPatientTemporaryDoctorId === false
      ? null
      : `((${lambdaParameter} as Patient).TemporaryDoctorId.Equals(${value}))`,
  doctorsOnly: (lambdaParameter, value) =>
    value ? `${lambdaParameter} is Doctor` : null,

  // PATIENT
  filterDoctorPatientId: () => null,
  doctorPatientId: (lambdaParameter, value, otherValues) =>
    otherValues?.filterDoctorPatientId === false
      ? null
      : `(${lambdaParameter} as Doctor).Patients.Any(p=>p.Id.Equals(${value}))`,
  patientsOnly: (lambdaParameter, value) =>
    value ? `${lambdaParameter} is Patient` : null,
  filterRelatedPatients: () => null,
  relatedPatientDoctorId: (lambdaParameter, value, otherValues) =>
    otherValues?.filterRelatedPatients === false
      ? null
      : `((${lambdaParameter} as Patient).Doctors.Any(d=>d.Id.Equals(${value})) || (${lambdaParameter} as Patient).MainDoctorId.Equals(${value}) || ((${lambdaParameter} as Patient).TemporaryDoctorId.Equals(${value})))`,
  mainDoctorId: (lambdaParameter, value) =>
    `(${lambdaParameter} as Patient).MainDoctorId.Equals(${value})`,
  temporaryDoctorId: (lambdaParameter, value) =>
    `(${lambdaParameter} as Patient).TemporaryDoctorId.Equals(${value})`,
  doctorIds: (lambdaParameter, value) =>
    `((${lambdaParameter} as Patient).Doctors.Any(d=>d.Id.Equals(${value})))`,
  active: (lambdaParameter, value) =>
    `((${lambdaParameter} as Patient).Active == ${
      value === true ? true : null
    })`,
}

const filterMapper = (key: string) =>
  mapping[key] as (
    lambdaParameter: string,
    value: any,
    otherValues: any,
  ) => string

export default filterMapper
