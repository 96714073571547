import * as React from 'react';
import { Typography, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'ra-core';
import { usePreferences } from '../../usePreferences';
import { ObjectInput } from './ObjectInput';
import { ResetSettingsButton } from '../ResetSettingsButton';
import { PreferencesEditor, EditorTitle } from '../Editor';
import { useGetResourceLabel } from 'react-admin';
/**
 * A component which provides a configuration UI to tweak the List
 * @param {ListEditorProps} props
 * @param {ColumnList} props.columns The List columns
 * @param {String} props.resource The resource
 * @param {String} props.preferencesKey The key of the columns preferences
 */
export var ListEditor = function (_a) {
    var defaultFilter = _a.defaultFilter, defaultTitle = _a.defaultTitle, resource = _a.resource, preferencesKey = _a.preferencesKey;
    var translate = useTranslate();
    var classes = useStyles();
    var getResourceLabel = useGetResourceLabel();
    // title preferences
    var _b = usePreferences(preferencesKey + ".title", defaultTitle), title = _b[0], setTitle = _b[1];
    var handleTitleChange = function (event) {
        setTitle(event.target.value);
    };
    // filter preferences
    var _c = usePreferences(preferencesKey + ".filter", defaultFilter), filter = _c[0], setFilter = _c[1];
    var handleFilterChange = function (value) {
        setFilter(value);
    };
    return (React.createElement(PreferencesEditor, null,
        React.createElement(React.Fragment, null,
            React.createElement(EditorTitle, { title: translate('ra-preferences.no-code.editor.list', {
                    resource: getResourceLabel(resource, 2),
                }) }),
            React.createElement(TextField, { label: translate('ra-preferences.no-code.list.title', {
                    _: 'Title',
                }), value: title, onChange: handleTitleChange, fullWidth: true, variant: "filled", size: "small", helperText: " " }),
            React.createElement(Typography, { variant: "body2", color: "textSecondary", gutterBottom: true }, translate('ra-preferences.no-code.list.filter', {
                _: 'Permanent filter',
            })),
            React.createElement(ObjectInput, { resource: resource, value: filter, onChange: handleFilterChange, className: classes.objectInput }),
            React.createElement(ResetSettingsButton, { preferencesKey: preferencesKey }))));
};
var useStyles = makeStyles(function (theme) { return ({
    objectInput: {
        borderLeft: "solid 1px " + theme.palette.divider,
        paddingTop: 0,
        paddingBottom: 0,
    },
}); });
