import * as React from 'react';
import { Button, Typography, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FieldTitle, useTranslate } from 'react-admin';
import { Link as RouterLink } from 'react-router-dom';
/**
 * A component providing UI to customize a single field.
 * Meant to be used inside the <DatagridEditor>
 *
 * @see DatagridEditor
 * @param {ColumnEditorProps} props
 * @param {Boolean} props.selected A boolean indicating whether this field is selected.
 * @param {Boolean} props.source The field source.
 * @param {Boolean} props.label The field label.
 * @param {Boolean} props.onToggle An event handler called when the selection state of the field changes.
 * @param {Boolean} props.onEdit An event handler called when users choose to edit this field.
 * @param {Boolean} props.resource The resource.
 * @param {FieldDefinition} props.customField The associated custom field if any.
 */
export var FieldEditor = function (props) {
    var selected = props.selected, customField = props.customField, label = props.label, onToggle = props.onToggle, resource = props.resource, source = props.source;
    var classes = useStyles(props);
    var translate = useTranslate();
    return (React.createElement("div", { className: classes.root, key: source },
        React.createElement("label", { htmlFor: "switch_" + source },
            React.createElement(Switch, { checked: selected, className: classes.switch, onChange: onToggle, name: source, id: "switch_" + source, size: "small" }),
            React.createElement(Typography, { component: "span", variant: "body2" },
                React.createElement(FieldTitle, { label: label, source: source, resource: resource }))),
        customField && (React.createElement(Button, { component: RouterLink, className: classes.editButton, to: getCustomFieldPath(customField) }, translate('ra.action.edit')))));
};
var useStyles = makeStyles(function (theme) { return ({
    root: {
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            '& $editButton': {
                opacity: 100,
            },
        },
    },
    switch: {
        marginRight: theme.spacing(0.5),
        marginLeft: -theme.spacing(0.5),
    },
    editButton: {
        opacity: 0,
        padding: 0,
        marginLeft: theme.spacing(1),
        transition: theme.transitions.create('opacity'),
    },
}); }, {
    name: 'RaColumnEditor',
});
export var getCustomFieldPath = function (field) {
    var result = field.template.replace(/({{)|(}})/g, '').replace(' ', '-');
    return "/" + result;
};
