import {
  Box,
  Button,
  ButtonGroup,
  makeStyles,
  MenuItem,
  Paper,
  Select,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import {
  Toolbar,
  ToolbarProps,
  useTranslate,
  useQuery,
  sanitizeFetchType,
  Labeled,
  useSetLocale,
  useMutation,
} from 'react-admin'
import { Form } from 'react-final-form'
import { useDispatch } from 'react-redux'
import lodash from 'lodash'
import { SET_THEME } from '../../core/constants/reducer.actions'
import { FrontDisplayModes } from '../../core/enum/FrontDisplayModes'
import { ResourceName } from '../../core/ResourceName'
import { SaveButton } from './UserSettingsSaveButton'

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
  },
  select: {
    width: '40vw',
  },
  box: {
    margin: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  timePicker: {
    minWidth: '10vw',
  },
}))

interface SettingsToolbarProps extends ToolbarProps {
  saveDisabled?: boolean
}

const EditToolbar = ({ saveDisabled, ...props }: SettingsToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton disabled={saveDisabled} />
  </Toolbar>
)

export const UserSettingsEdit = () => {
  const translate = useTranslate()
  const classes = useStyles()
  const setLocale = useSetLocale()
  const dispatch = useDispatch()

  const {
    data: settings,
    refetch: refetchSettings,
    loaded,
  } = useQuery({
    type: sanitizeFetchType('getSettings'),
    resource: ResourceName.CURRENT_USER,
    payload: {},
  })
  const [settingsEdit, setSettingsEdit] = useState(settings)

  const [mutate, { loading: mutationLoading }] = useMutation({
    type: sanitizeFetchType('patchSettings'),
    resource: ResourceName.CURRENT_USER,
    payload: settingsEdit,
  })

  useEffect(() => {
    if (loaded && !mutationLoading) setSettingsEdit(settings)
  }, [loaded, mutationLoading, settings])

  const handleChangeValue = (key: string, value: string | boolean) => {
    setSettingsEdit({
      ...settingsEdit,
      [key]: value,
    })
  }

  const handleSubmit = async () => {
    await mutate()
    setLocale(settingsEdit?.language)
    dispatch({ type: SET_THEME, payload: settingsEdit?.frontDisplayMode })
    if (refetchSettings) {
      refetchSettings()
    }
  }

  return (
    <Paper className={classes.form}>
      <Form onSubmit={handleSubmit}>
        {(props) => (
          <form onSubmit={props.handleSubmit} className={classes.form}>
            <Box className={classes.box}>
              <Labeled label="settings.darkMode.name">
                <ButtonGroup>
                  <Button
                    size="large"
                    variant="contained"
                    color={
                      settingsEdit?.frontDisplayMode === FrontDisplayModes.LIGHT
                        ? 'primary'
                        : 'default'
                    }
                    onClick={() =>
                      handleChangeValue(
                        'frontDisplayMode',
                        FrontDisplayModes.LIGHT,
                      )
                    }
                  >
                    {translate('settings.darkMode.light')}
                  </Button>
                  <Button
                    size="large"
                    variant="contained"
                    color={
                      settingsEdit?.frontDisplayMode === FrontDisplayModes.DARK
                        ? 'primary'
                        : 'default'
                    }
                    onClick={() =>
                      handleChangeValue(
                        'frontDisplayMode',
                        FrontDisplayModes.DARK,
                      )
                    }
                  >
                    {translate('settings.darkMode.dark')}
                  </Button>
                </ButtonGroup>
              </Labeled>
              {loaded && settingsEdit && (
                <Labeled label="settings.language.name">
                  <Select
                    value={settingsEdit?.language}
                    className={classes.select}
                    variant="outlined"
                    onChange={(e) =>
                      handleChangeValue('language', e.target.value as string)
                    }
                  >
                    <MenuItem value="pl">
                      {translate('settings.language.pl')}
                    </MenuItem>
                    {/* <MenuItem value="en">
                      {translate('settings.language.en')}
                    </MenuItem> */}
                  </Select>
                </Labeled>
              )}
            </Box>
            <EditToolbar
              onSubmit={props.handleSubmit}
              saveDisabled={lodash.isEqual(settings, settingsEdit)}
            />
          </form>
        )}
      </Form>
    </Paper>
  )
}
