const mapping: Record<string, string> = {
  id: 'a=>a.Id',
  ailmentType: 'a=>a.AilmentType',
  occurs: 'a=>a.Occurs',
  ailmentSurveyId: 'a=>a.AilmentSurveyId',
}

const sortMapper = (key: string) => mapping[key]

export default sortMapper
