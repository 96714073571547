import { Typography, useMediaQuery, useTheme } from '@material-ui/core'
import React, { cloneElement, useContext } from 'react'
import {
  BooleanInput,
  CreateButton,
  Datagrid,
  DateField,
  Filter,
  FilterButton,
  FilterContext,
  FunctionField,
  GET_ONE,
  ListProps,
  Pagination,
  ReferenceField,
  TextField,
  TopToolbar,
  sanitizeFetchType,
  sanitizeListRestProps,
  useListContext,
  useQueryWithStore,
} from 'react-admin'
import { Authority } from '../../core/auth/Authority'
import { ResourceName } from '../../core/ResourceName'
import List from '../common/customized-ra-components/List'
import { DateFilterInput } from '../common/DateFilterInput'
import FilteredReferenceInput from '../common/FilteredReferenceInput'
import FilterProps from '../common/FilterProps'
import { useHasAuthority } from '../hooks/useHasAuthority'
import { UserBaseDto } from '../../core/dto/user/user-base.dto'
import { UserTypes } from '../../core/enum/UserTypes'
import { PatientDto } from '../../core/dto/user/patient.dto'
import { ExportToCSVButton } from './ChemotherapyAilmentSurveyList/ExportToCSVButton'
import QuickFilter from '../common/QuickFilter'

const ChemotherapyAilmentSurveysActions = ({ ...props }) => {
  const { filters: filtersProp } = props
  const resource = ResourceName.CHEMOTHERAPY_AILMENT_SURVEYS
  const hasAuthority = useHasAuthority()

  const { displayedFilters, filterValues, showFilter } = useListContext(props)
  const filters = useContext(FilterContext) || filtersProp

  return (
    <TopToolbar {...sanitizeListRestProps(props)}>
      {filtersProp
        ? cloneElement(filtersProp, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })
        : filters && <FilterButton />}
      {localStorage.getItem('user_type') === UserTypes.DOCTOR && (
        <ExportToCSVButton />
      )}
      {hasAuthority(Authority.CREATE_CHEMOTHERAPY_AILMENT_SURVEYS) && (
        <CreateButton resource={ResourceName.CHEMOTHERAPY_AILMENT_SURVEYS} />
      )}
    </TopToolbar>
  )
}

const ChemotherapyAilmentSurveysFilters = (props: FilterProps) => {
  const hasAuthority = useHasAuthority()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { data: user } = useQueryWithStore({
    type: sanitizeFetchType(GET_ONE),
    resource: ResourceName.CURRENT_USER,
    payload: {},
  })

  return (
    <Filter {...props}>
      <DateFilterInput source="date" key="date" />
      {localStorage.getItem('user_type') === UserTypes.DOCTOR &&
        hasAuthority(Authority.WEB_USER) && (
          <BooleanInput source="filterRelatedPatientsAilmentSurveys" alwaysOn />
        )}
      {hasAuthority(Authority.VIEW_PATIENTS) && (
        <FilteredReferenceInput
          label="resources.chemotherapy-ailment-surveys.fields.patientId"
          source="patientId"
          reference={ResourceName.PATIENTS}
          sort={{ field: 'lastName', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="fullNameSearch"
          filter={{
            patientsOnly: true,
            active: true,
            filterRelatedPatients:
              (user as UserBaseDto)?.userType === UserTypes.DOCTOR,
            relatedPatientDoctorId: (user as UserBaseDto)?.id,
          }}
          selectWithPaginationInputProps={{
            optionText: (record: PatientDto) =>
              `${record.firstName} ${record.lastName} (${record.pesel})`,
            showFilter: true,
          }}
        />
      )}
      <QuickFilter source="hideEmptyAilmentSurveys" defaultValue />
    </Filter>
  )
}

const ChemotherapyAilmentSurveysGrid = ({ ...props }) => {
  const { loading } = useListContext()

  return (
    <Datagrid
      {...props}
      size="small"
      loaded={!loading}
      rowClick="show"
      hasBulkActions={false}
    >
      <TextField source="id" label="ID" />
      <ReferenceField
        source="patientId"
        reference={ResourceName.PATIENTS}
        link={false}
        emptyText="-"
        sortable={false}
      >
        <FunctionField<PatientDto>
          source="patientId"
          render={(record?: PatientDto) => (
            <Typography variant="body2">
              {record?.firstName} {record?.lastName}
            </Typography>
          )}
        />
      </ReferenceField>
      <DateField
        source="date"
        showTime
        options={{
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        }}
        emptyText="-"
      />
    </Datagrid>
  )
}

export const ChemotherapyAilmentSurveyList = ({ ...props }: ListProps) => {
  const { data: user } = useQueryWithStore({
    type: sanitizeFetchType(GET_ONE),
    resource: ResourceName.CURRENT_USER,
    payload: {},
  })

  return (
    <List
      filters={<ChemotherapyAilmentSurveysFilters />}
      filterDefaultValues={{
        hidePatientsWithoutChemotherapyModule: true,
        filterRelatedPatientsAilmentSurveys:
          (user as UserBaseDto)?.userType === UserTypes.DOCTOR,
        relatedPatientDoctorId: (user as UserBaseDto)?.id,
      }}
      perPage={20}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
      exporter={false}
      sort={{ field: 'date', order: 'DESC' }}
      actions={<ChemotherapyAilmentSurveysActions />}
      {...props}
    >
      <ChemotherapyAilmentSurveysGrid />
    </List>
  )
}
