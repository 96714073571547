import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  Edit,
  EditProps,
  SaveButton,
  ShowButton,
  SimpleForm,
  TextField,
  TextInput,
  TitleProps,
  Toolbar,
  ToolbarProps,
  useTranslate,
} from 'react-admin'
import { DoctorDto } from '../../core/dto/user/doctor.dto'
import Base64ImageInput from '../common/Base64ImageInput'

interface DoctorTitleProps extends TitleProps {
  record?: DoctorDto
}

export const validateDoctorEdit = (values: DoctorDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.firstName) {
    errors.firstName = 'ra.validation.required'
  }
  if (!values.lastName) {
    errors.lastName = 'ra.validation.required'
  }
  if (!values.userName) {
    errors.userName = 'ra.validation.required'
  }
  return errors
}

const EditTitle = (props: DoctorTitleProps) => {
  const { record } = props
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.doctors.titles.edit')}: {record?.firstName}{' '}
      {record?.lastName}
    </span>
  )
}

const EditToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ShowButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

const DoctorEditForm = ({ ...props }) => (
  <SimpleForm
    {...props}
    redirect="show"
    validate={validateDoctorEdit}
    toolbar={<EditToolbar />}
  >
    <TextField source="id" label="ID" />
    <TextInput source="firstName" isRequired />
    <TextInput source="lastName" isRequired />
    <TextInput source="userName" isRequired />
    <TextInput source="email" />
    <Base64ImageInput
      source="base64Photo"
      outputSource="blobPhoto"
      label="resources.users.fields.base64Photo"
    />
    {/* <BooleanInput source="requirePasswordUpdate" /> */}
  </SimpleForm>
)

export const DoctorEdit = (props: EditProps) => (
  <Edit {...props} title={<EditTitle />} actions={false}>
    <DoctorEditForm />
  </Edit>
)
