import { GetApp as Download } from '@material-ui/icons'
import React, { useState } from 'react'
import { useShowContext, useTranslate } from 'react-admin'
import Button from '../../common/customized-mui-components/Button'
import { ExportToCSVDialog } from './ExportToCSVDialog'

export const ExportToCSVButton = () => {
  const { loading } = useShowContext()
  const [dialogOpen, setDialogOpen] = useState(false)
  const translate = useTranslate()

  return (
    <>
      <ExportToCSVDialog open={dialogOpen} close={() => setDialogOpen(false)} />
      <Button
        variant="text"
        size="small"
        label={translate(
          'resources.chemotherapy-ailment-surveys.actions.exportToCSV',
        )}
        disabled={loading}
        onClick={() => setDialogOpen(true)}
      >
        <Download />
      </Button>
    </>
  )
}
