var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { Stepper, StepButton, StepLabel, Step, } from '@material-ui/core';
/**
 * Progress component rendering a stepper on top of the wizard
 *
 * @prop {number} currentStep Current selected step index
 * @prop {Function} onStepClick Action called when a step is clicked
 * @prop {React.ReactElement[]} steps Array of step elements
 */
export var WizardProgress = function (_a) {
    var currentStep = _a.currentStep, onStepClick = _a.onStepClick, steps = _a.steps, rest = __rest(_a, ["currentStep", "onStepClick", "steps"]);
    var handleStepClick = function (index) { return function () { return onStepClick(index); }; };
    return (React.createElement(Stepper, __assign({ activeStep: currentStep }, rest), steps.map(function (step, index) {
        var label = React.cloneElement(step, { intent: 'label' });
        return (React.createElement(Step, { key: "step_" + index }, !onStepClick ? (React.createElement(StepLabel, null, label)) : (React.createElement(StepButton, { onClick: handleStepClick(index) }, label))));
    })));
};
WizardProgress.defaultProps = {
    currentStep: 0,
    steps: [],
};
